import React, { FC } from 'react';
import { GroupedSlot } from '../../../api-types';
import CallapsedRow from './CallapsedRow';

type Props = {
  slotItem: GroupedSlot;
};

const ProfitabilitySlotGroupRows: FC<Props> = ({ slotItem }) => {
  return (
    <CallapsedRow
      rowTitle={slotItem.slotName}
      sumRevenueRub={slotItem.sumRevenueRub}
      sumSoldHours={slotItem.sumSoldHours}
      sumTrackingHours={slotItem.sumTrackingHours}
      sumPriceRub={slotItem.sumPriceRub}
      developmentExpenses={slotItem.developmentExpenses}
      level="dark"
    >
      {slotItem.users.map((userItem) => (
        <CallapsedRow
          key={userItem.user}
          rowTitle={userItem.user}
          sumRevenueRub={userItem.sumRevenueRub}
          sumSoldHours={userItem.sumSoldHours}
          sumTrackingHours={userItem.sumTrackingHours}
          sumPriceRub={userItem.sumPriceRub}
          developmentExpenses={(slotItem.developmentExpenses / slotItem.sumTrackingHours) * userItem.sumTrackingHours}
          level="average"
        >
          {userItem.projects.map((projectItem) => (
            <CallapsedRow
              key={projectItem.project}
              rowTitle={projectItem.project}
              sumRevenueRub={projectItem.revenueRub}
              sumSoldHours={projectItem.soldHours}
              sumTrackingHours={projectItem.trackingHours}
              sumPriceRub={projectItem.priceRub}
              developmentExpenses={(slotItem.developmentExpenses / slotItem.sumTrackingHours) * projectItem.trackingHours}
              level="light"
            >
              {projectItem.orders.map((order) => (
                <CallapsedRow
                  key={order.orderId}
                  rowTitle={order.orderNumber}
                  sumRevenueRub={order.revenueRub}
                  sumSoldHours={order.soldHours}
                  sumTrackingHours={order.trackingHours}
                  sumPriceRub={order.factCostPriceRub + order.calculatedCostPriceRub}
                  developmentExpenses={(slotItem.developmentExpenses / slotItem.sumTrackingHours) * order.trackingHours}
                  level="none"
                />
              ))}
            </CallapsedRow>
          ))}
        </CallapsedRow>
      ))}
    </CallapsedRow>
  );
};

export default ProfitabilitySlotGroupRows;
