import { Theme } from "@material-ui/core";

export default (theme: Theme) => ({
  text: {
    position: "relative" as "relative",
    zIndex: 1,
    textAlign: 'center' as 'center',
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120,
  },
  menuItem: {
    fontSize: "14px"
  },
});
