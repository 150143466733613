import {
  Hours,
  MoneyAmount,
  SalesOrderAnalyze,
  SalesOrderAnalyzeOrder,
  SalesOrderAnalyzePayment,
  SalesOrderAnalyzePaymentFact
} from "~/config/pages/moneyFlow/api-types";

export interface SalesOrderAnalyzeExtended extends SalesOrderAnalyze {
  headOrder: SalesOrderAnalyzeOrder;

  fullAmount: MoneyAmount;
  plannedAmount: MoneyAmount;
  factAmount: MoneyAmount;
  plannedNotPaidAmount: MoneyAmount;
  toPlanAmount: MoneyAmount;
  toPayAmount: MoneyAmount;
  estimatedAmount: MoneyAmount;

  fullHours: Hours;

  rate: MoneyAmount;
}

export function extendSalesOrder(row: SalesOrderAnalyze): SalesOrderAnalyzeExtended {
  for (let j in row.payments) {
    let payment = row.payments[j];
    for (let k in payment.fact) {
      let fact = payment.fact[k];
      fact["order"] = row.orders.find((order) => order.id === fact.orderId);
    }
  }

  let amount: MoneyAmount = getFullAmount(row);
  let hours: Hours = fullHours(row);

  let rate: MoneyAmount = {
    currency: amount.currency,
    amountInCurrency: amount.amountInCurrency / hours.sold,
    amountInRub: amount.amountInRub / hours.sold
  }

  return {
    ...row,
    headOrder: row.orders.find((order) => order.id === row.headOrderId)!,

    fullAmount: amount,
    plannedAmount: getPlannedAmount(row),
    factAmount: getFactAmount(row),
    plannedNotPaidAmount: getPlannedNotPaidAmount(row),
    toPlanAmount: getNotPlannedAmount(row),
    toPayAmount: getToPayAmount(row),
    estimatedAmount: {
      currency: rate.currency,
      amountInCurrency: hours.spent * rate.amountInCurrency,
      amountInRub: hours.spent * rate.amountInRub,
    },

    fullHours: hours,

    rate: rate
  }
}

const emptyMoneyAmount: MoneyAmount = {
  currency: "",
  amountInCurrency: 0,
  amountInRub: 0
};

const emptyHours: Hours = {
  sold: 0,
  prod: 0,
  spent: 0
}

function fullHours(row: SalesOrderAnalyze): Hours {
  return hoursSum(row.orders.map(item => item.hours));
}

function getPlannedAmount(row: SalesOrderAnalyze): MoneyAmount {
  return row.payments
    .flatMap(item => item.plan)
    .map(item => item?.current?.amount)
    .filter(item => item != undefined)
    .map(item => item!!)
    .reduce(moneyAmountPlus, emptyMoneyAmount);
}

function getFactAmount(row: SalesOrderAnalyze): MoneyAmount {
  return row.payments
    .flatMap(item => item.fact)
    .map(item => item.amount)
    .reduce(moneyAmountPlus, emptyMoneyAmount);
}

function getPlannedNotPaidAmount(row: SalesOrderAnalyze): MoneyAmount {
  return row.payments
    .filter(item => item.plan != undefined)
    .map((item: SalesOrderAnalyzePayment): MoneyAmount => {
      let paid: MoneyAmount = item.fact
        .map(item => item.amount)
        .reduce(moneyAmountPlus, emptyMoneyAmount);

      let amount: MoneyAmount = item.plan!!.current.amount;
      return {
        currency: amount.currency,
        amountInCurrency: amount.amountInCurrency - paid.amountInCurrency,
        amountInRub: amount.amountInRub - paid.amountInRub,
      }
    })
    .reduce(moneyAmountPlus, emptyMoneyAmount);
}

function getNotPlannedAmount(row: SalesOrderAnalyze): MoneyAmount {
  let planned: MoneyAmount = row.payments
    .map((item: SalesOrderAnalyzePayment): MoneyAmount => {
      let paid: MoneyAmount = item.fact.reduce((prev: MoneyAmount, curr: SalesOrderAnalyzePaymentFact): MoneyAmount => {
        return {
          currency: curr.amount.currency,
          amountInCurrency: prev.amountInCurrency + curr.amount.amountInCurrency,
          amountInRub: prev.amountInRub + curr.amount.amountInRub
        }
      }, emptyMoneyAmount);

      let amount: MoneyAmount | undefined = item.plan?.current?.amount;
      return {
        currency: paid.currency,
        amountInCurrency: Math.max(paid.amountInCurrency, amount?.amountInCurrency ?? 0),
        amountInRub: Math.max(paid.amountInRub, amount?.amountInRub ?? 0),
      }
    })
    .reduce(moneyAmountPlus, emptyMoneyAmount);

  let full: MoneyAmount = getFullAmount(row);
  return moneyAmountMinus(full, planned);
}

function getToPayAmount(row: SalesOrderAnalyze): MoneyAmount {
  let full: MoneyAmount = getFullAmount(row);
  let fact: MoneyAmount = getFactAmount(row);
  return moneyAmountMinus(full, fact);
}

function getFullAmount(row: SalesOrderAnalyze): MoneyAmount {
  return row.orders.map((item: SalesOrderAnalyzeOrder) => item.amount)
    .reduce(moneyAmountPlus, emptyMoneyAmount);
}

function moneyAmountPlus(a: MoneyAmount, b: MoneyAmount): MoneyAmount {
  return {
    currency: b.currency,
    amountInCurrency: a.amountInCurrency + b.amountInCurrency,
    amountInRub: a.amountInRub + b.amountInRub
  }
}

function moneyAmountMinus(a: MoneyAmount, b: MoneyAmount): MoneyAmount {
  return {
    currency: a.currency,
    amountInCurrency: a.amountInCurrency - b.amountInCurrency,
    amountInRub: a.amountInRub - b.amountInRub
  }
}

export function moneyAmountSum(items: MoneyAmount[]): MoneyAmount {
  return items.reduce(moneyAmountPlus, emptyMoneyAmount);
}

export function hoursSum(items: Hours[]): Hours {
  return items.reduce((prev: Hours, current: Hours): Hours => {
    return {
      sold: prev.sold + current.sold,
      prod: prev.prod + current.prod,
      spent: prev.spent + current.spent
    };
  }, emptyHours);
}
