import React from "react";
import { Entity } from "icerockdev-admin-toolkit";
import { computed } from "mobx";
import { observer } from "mobx-react";
import { OrderNumberList } from "../OrderNumberList";

export class OrderNumberEntity extends Entity {
  @computed
  get ListBody() {
    return observer(() => <OrderNumberList orders={this.data} />);
  }

  @computed
  get ListHead() {
    return () => <></>;
  }

  @computed
  get ListFooter() {
    return () => <></>;
  }
}
