import React, { FC, useState, useCallback, useEffect } from "react";
import styles from "./styles";
import {
    WithStyles,
    withStyles,
    Dialog,
    Button,
    CircularProgress,
    TextField, Checkbox,
} from "@material-ui/core";
import { OrderAutocomplete } from "../WorklogEntity";
import { flow } from "mobx";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useCloseOnEscape } from "~/utils/hooks";

type IProps = WithStyles<typeof styles> & {
  onClose: () => void;
  onSubmit: (val: OrderAutocomplete | null, isBench: boolean | null) => void;
  fetchOrders: () => Promise<OrderAutocomplete[]>;
};

type OrderAutocompleteWithTitle = OrderAutocomplete & { title: string };

const WorklogAssignModalUnconnected: FC<IProps> = ({
  onClose,
  onSubmit,
  classes,
  fetchOrders,
}) => {
  const [orders, setOrders] = useState<OrderAutocompleteWithTitle[]>([]);
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState<OrderAutocompleteWithTitle>();
  const [isBench, setBench] = useState<boolean | null>(null);

  useCloseOnEscape(onClose)

  const onMount = useCallback(
    flow(function* onMount() {
      setLoading(true);

      const {
        data: newOrders,
      }: { data: OrderAutocomplete[] } = yield fetchOrders();

      setOrders(
        newOrders
          .map((order) => ({
            ...order,
            title: `${order.number}${
              order.jiraCategory && ` - ${order.jiraCategory}`
            }${order.description && ` - ${order.description}`}`,
          }))
          .sort((a, b) => a.title.localeCompare(b.title))
      );
      setLoading(false);
    }),
    []
  );

  useEffect(() => {
    const action = onMount();
    return () => action.cancel();
  }, []);

  const onSubmitForm = useCallback(
    async (event) => {
      event.preventDefault();

      let value: OrderAutocomplete | null;
      if (selected) {
        const { title, ...val } = selected;
        value = val;
      } else {
        value = null;
      }

      onSubmit(value, isBench);
    },
    [onSubmit, selected, isBench, onClose]
  );

  if (loading)
    return (
      <Dialog open>
        <CircularProgress />
      </Dialog>
    );

  return (
    <Dialog open onClose={onClose}>
      <form className={classes.wrap} onSubmit={onSubmitForm}>
        <Autocomplete
          options={orders}
          getOptionLabel={(option) => option.title}
          style={{ width: 400 }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Новый номер заказа"
              variant="outlined"
            />
          )}
          onChange={(e, item) => setSelected(item)}
        />

        <div style={{display: "flex"}}>
          <Checkbox
            checked={isBench ?? false}
            onChange={(event, checked) => setBench(checked)}
          />
          <p>Недогруз</p>
        </div>


        <div className={classes.buttons}>
          <Button onClick={onClose} color="primary" type="button">
            Отмена
          </Button>

          <Button
            color="primary"
            type="submit"
            variant={selected ? "contained" : "outlined"}
          >
            OK
          </Button>
        </div>
      </form>
    </Dialog>
  );
};

const WorklogAssignModal = withStyles(styles)(WorklogAssignModalUnconnected);

export { WorklogAssignModal };
