import React, { FC, useMemo } from "react";
import { IEntityFieldProps } from "icerockdev-admin-toolkit";

interface IProps extends IEntityFieldProps {}

const WorklogTime: FC<IProps> = ({ value }) => {
  const hours = useMemo(() => parseInt(value) / 60 / 60, [value]);
  const mins = useMemo(() => hours % 1 && (hours % 1) * 60, [hours]);

  return (
    <div>
      <b>
        {(Math.floor(hours) && `${Math.floor(hours)}h`) || ""}{" "}
        {mins > 0 && `${Math.round(mins)}m`}
      </b>
    </div>
  );
};

export { WorklogTime };
