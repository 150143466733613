import {makeStyles} from "@material-ui/core/styles";
import styles from "~/config/pages/orders/OrderEditorTab/styles";
import {IReactComponent} from "mobx-react/dist/types/IReactComponent";
import {observer} from "mobx-react";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {getList} from "~/config/pages/moneyFlow/api";
import {SalesOrderAnalyze} from "~/config/pages/moneyFlow/api-types";
import {CircularProgress, FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import {Config} from "icerockdev-admin-toolkit";
import SalesDashboardView from "~/config/pages/moneyFlow/SalesDashboardView";
import {extendSalesOrder, SalesOrderAnalyzeExtended} from "~/config/pages/moneyFlow/SalesOrderAnalyzeExt";
import {SelectInputProps} from "@material-ui/core/Select/SelectInput";

const useStyles = makeStyles(styles)

interface MoneyFlowListProps {
  apiBaseUrl: string,
  config: Config
}

type DataState = Error | SalesOrderAnalyze[] | null

export const MoneyFlowListView: IReactComponent<MoneyFlowListProps> = observer(
  (
    props: MoneyFlowListProps
  ): JSX.Element => {

    const [
      dataState,
      setDataState
    ] = useState<DataState>(() => null);

    useEffect(() => {
      setDataState(null);
      getList(props.apiBaseUrl, props.config)
        .then(setDataState)
        .catch(setDataState);
    }, [setDataState]);

    if (dataState instanceof Error) {
      return errorState(dataState);
    } else if (dataState == null) {
      return loadingState();
    } else {
      return (
        <SuccessfulState
          data={dataState as SalesOrderAnalyze[]}
          apiBaseUrl={props.apiBaseUrl}
          config={props.config}
        />
      );
    }
  }
);

function errorState(error: Error): JSX.Element {
  let errorString = JSON.stringify(error["response"]?.data, null, 2);
  return (
    <div style={{padding: 16}}>
      <p>{error.message}</p>
      <p style={{whiteSpace: "pre-wrap"}}>{errorString}</p>
    </div>
  );
}

function loadingState(): JSX.Element {
  return (<div style={
    {
      width: "100vw",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }
  }>
    <CircularProgress/>
  </div>);
}

const SuccessfulState: IReactComponent<
  {
    data: SalesOrderAnalyze[],
    apiBaseUrl: string,
    config: Config
  }
> = observer((props): JSX.Element => {
  let extended: SalesOrderAnalyzeExtended[] = useMemo(() => props.data.map(extendSalesOrder), [props.data]);
  let sales: (string | null)[] = useMemo(() => {
    let usernames = extended.map(item => item.headOrder.salesUsername);
    return Array.from(new Set(usernames));
  }, [extended]);
  let projects: (string | null)[] = useMemo(() => {
    let projects = extended.map(item => item.headOrder.jiraCategory)
    .sort((a, b) => {
      if (a === null) return -1;
      if (b === null) return 1;
      return a.toLowerCase().localeCompare(b.toLowerCase());
    });
    return Array.from(new Set(projects));
  }, [extended]);

  // sales
  const [
    selectedSales,
    setSelectedSales
  ] = useState<string | null>(() => null);

  let changeSales: SelectInputProps['onChange'] = useCallback((event) => {
    setSelectedSales(event.target.value as string)
  }, [setSelectedSales]);

  // project
  const [
    selectedProject,
    setSelectedProject
  ] = useState<string | null>(() => null);

  let changeProject: SelectInputProps['onChange'] = useCallback((event) => {
    setSelectedProject(event.target.value as string)
  }, [setSelectedProject]);

  let filtered: SalesOrderAnalyzeExtended[] = useMemo(() => {
    return extended.filter(item => {
      return selectedSales == null || selectedSales == item.headOrder.salesUsername
    }).filter(item => {
      return selectedProject == null || selectedProject == item.headOrder.jiraCategory
    });
  }, [extended, selectedSales, selectedProject]);

  return (
    <div style={{padding: 16}}>
      <div style={{width: "100%", display: "flex"}}>
        <FormControl variant="filled" style={{minWidth: 200, marginLeft: "auto", marginRight: 4}}>
          <InputLabel id="sales-selection-label">Sales</InputLabel>
          <Select
            labelId="sales-selection-label"
            id="sales-selection"
            value={selectedSales}
            onChange={changeSales}
          >
            <MenuItem value={undefined}>-</MenuItem>
            {sales.map(sales => (<MenuItem value={sales ?? undefined}>{sales}</MenuItem>))}
          </Select>
        </FormControl>

        <FormControl variant="filled" style={{minWidth: 200}}>
          <InputLabel id="project-selection-label">Project</InputLabel>
          <Select
            labelId="project-selection-label"
            id="project-selection"
            value={selectedProject}
            onChange={changeProject}
          >
            <MenuItem value={undefined}>-</MenuItem>
            {projects.map(item => (<MenuItem value={item ?? undefined}>{item}</MenuItem>))}
          </Select>
        </FormControl>
      </div>

      <SalesDashboardView rows={filtered} apiBaseUrl={props.apiBaseUrl} config={props.config}/>
    </div>
  );
});
