import { Theme } from "@material-ui/core";

export default (theme: Theme) => ({
  link: {
    display: 'flex',
    borderRadius: 4,
    height: 30,
    alignItems: 'center' as 'center',
    justifyContent: 'flex-start' as 'flex-start',
    color: '#333333',
    fontWeight: 'bold' as 'bold',
    textDecoration: 'none',
    whiteSpace: 'nowrap' as 'nowrap',
    fontSize: '1.2em',
    backgroundColor: '#eeeeee',
    padding: '0 8px',

    '& img': {
      marginRight: 10,
    }
  }
});
