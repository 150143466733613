import {Config, UNAUTHORIZED} from "icerockdev-admin-toolkit";
import {MoneyFlowDetails, SalesOrderAnalyze} from "~/config/pages/moneyFlow/api-types";
import Axios from "axios";
import HttpStatusCode from "http-status-typed";

async function getDetails(
  apiBaseUrl: string,
  config: Config,
  refKey: string
): Promise<MoneyFlowDetails> {
  let url: string = `${apiBaseUrl}/moneyflow/${refKey}`
  return config.auth?.withToken(async ({token}) => {
    const response = await Axios.get(url, {
      headers: {authorization: token},
    });

    if (response?.status === HttpStatusCode.UNAUTHORIZED) return {data: {}, error: UNAUTHORIZED};
    if (!response?.data) return;

    return response.data;
  }, {});
}

async function setDetails(
  apiBaseUrl: string,
  config: Config,
  refKey: string,
  salesComment: string
): Promise<void> {
  let url: string = `${apiBaseUrl}/moneyflow/${refKey}`
  return config.auth?.withToken(async ({token}) => {
    const response = await Axios.post(
      url,
      {
        salesComment: salesComment
      },
      {
        headers: {authorization: token},
      }
    );

    if (response?.status === HttpStatusCode.UNAUTHORIZED) return {data: {}, error: UNAUTHORIZED};
    if (!response?.data) return;

    return response.data;
  }, {});
}

async function getList(
  apiBaseUrl: string,
  config: Config
): Promise<SalesOrderAnalyze[]> {
  let url: string = `${apiBaseUrl}/moneyflow`
  return config.auth?.withToken(async ({token}) => {
    const response = await Axios.get(url, {
      headers: {authorization: token},
    });

    if (response?.status === HttpStatusCode.UNAUTHORIZED) return {data: {}, error: UNAUTHORIZED};
    if (!response?.data) return;

    return response.data;
  }, {});
}

export {getDetails, setDetails, getList};
