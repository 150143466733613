export default {
  wrap: {
    padding: 10,
  },
  table: {
    width: "100%",
    borderCollapse: "collapse" as "collapse",

    "& td, & th": {
      padding: 5,
      border: "1px solid #dddddd",
      verticalAlign: "top",
    },
  },
};
