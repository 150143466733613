import React, { useMemo, useCallback, useState, useEffect } from "react";
import {
  WithStyles,
  withStyles,
  Button,
  CircularProgress,
} from "@material-ui/core";
import styles from "./styles";
import { observer } from "mobx-react";
import { OrderViewComments } from "../OrderViewComments";
import { OrderEntity } from "../OrderEntity";
import { EntityField, ENTITY_ERRORS } from "icerockdev-admin-toolkit";
import classNames from "classnames";
import { ORDER_FIELDS } from "../fields";
import omit from "ramda/es/omit";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { useCloseOnEscape } from "~/utils/hooks";

type IProps = WithStyles<typeof styles> & {
  data: Record<string, any>[];
  id: any;
  fields: OrderEntity["fields"];
  setData: (data: OrderEntity["data"]) => void;
  postComment: OrderEntity["postComment"];
  getComments: (id: number) => Promise<any[]>;
  referenceData: OrderEntity["referenceData"];
  updateItem: (data: Record<string, any>) => Record<string, any>;
  getItem: (id: number) => Record<string, any>;
  onClose: () => void;
  fetchComments: ({}: {
    id: number;
    skip: number;
  }) => Promise<Record<string, string>[]>;
};

const EDITABLE_FIELDS = [
  "productionStatus",
  "saleStatus",
  "teamLeadUser",
  "responsibleUser",
  "salesUser",
  "plannedApproveDate",
  "estimatedApproveDate",
  "factApproveDate",
  "supplementaryAgreementDate",
  "healthStatus",
];

const OrderExtra = observer(
  withStyles(styles)(
    ({
      classes,
      data,
      id,
      setData,
      postComment,
      getComments,
      fields,
      updateItem,
      getItem,
      onClose,
      fetchComments,
    }: IProps) => {
      const [fieldErrors, setFieldErrors] = useState({});
      const [comments, setComments] = useState<any[]>([]);
      const [commentsLoading, setCommentsLoading] = useState(true);
      const [dataLoading, setDataLoading] = useState(false);
      const [editorData, setEditorData] = useState<Record<string, any>>({});
      const [order, setOrder] = useState<Record<string, any>>({});
      const [isSavingData, setIsSavingData] = useState(false);
      const [isSavedData, setIsSavedData] = useState(false);
      const [saveError, setSaveError] = useState("");

      useCloseOnEscape(onClose, false);

      const validateFields = useCallback(() => {
        const errors = ORDER_FIELDS.filter((field) =>
          EDITABLE_FIELDS.includes(field.name)
        ).reduce(
          (obj, field) =>
            (!field.required ||
              field.type === "boolean" ||
              !!editorData[field.name]) &&
            (!field.validator || !field.validator(editorData[field.name]))
              ? obj
              : {
                  ...obj,
                  [field.name]:
                    (field.validator &&
                      field.validator(editorData[field.name])) ||
                    ENTITY_ERRORS.FIELD_IS_REQUIRED,
                },
          {}
        );

        setFieldErrors(errors);

        return Object.keys(errors).length === 0;
      }, [setFieldErrors, editorData]);

      const changed = useMemo(
        () =>
          order &&
          EDITABLE_FIELDS.some(
            (field) =>
              Object.prototype.hasOwnProperty.call(editorData, field) &&
              editorData[field] !== order[field]
          ),
        [editorData, order]
      );

      const loadData = useCallback(async () => {
        if (!id) return;
        setDataLoading(true);

        try {
          const result = await getItem(id);

          if (!result?.data) return;

          setOrder(result.data);
          setEditorData(result.data);
        } catch (e) {
          setEditorData(data.find((item) => item.id === id) || {});
          setSaveError("Не удалось загрузить заказ");
        } finally {
          setDataLoading(false);
        }
      }, [id, setEditorData, setOrder]);

      const onSubmit = useCallback(
        async (event) => {
          event.preventDefault();

          if (!id || !changed) return;

          setFieldErrors({});
          setSaveError("");
          setIsSavedData(false);
          setIsSavingData(true);

          const changedData = {
            ...order,
            ...editorData,
          };

          try {
            if (!validateFields()) {
              throw new Error(ENTITY_ERRORS.INCORRECT_INPUT);
            }

            const result = await updateItem(
              Object.keys(changedData).reduce(
                (obj, field) => ({
                  ...obj,
                  [field]: changedData[field] || "",
                }),
                {}
              )
            );

            if (!result.data || !result.data.id) {
              throw new Error(result.error || "Не удалось сохранить");
            }

            setOrder(result.data);
            setEditorData(result.data);
            setData(
              data.map((entry) => (entry.id === id ? result.data : entry))
            );
            setSaveError("");
            setIsSavedData(true);
          } catch (e) {
            setSaveError(e.message);
          } finally {
            setIsSavingData(false);
          }
        },
        [
          id,
          editorData,
          setData,
          updateItem,
          changed,
          setSaveError,
          setIsSavedData,
          setIsSavingData,
          validateFields,
        ]
      );

      useEffect(() => {
        loadData();
      }, [id]);

      const onPostComment = useCallback(
        async (_, text: string) => {
          const comment = await postComment(id, text);

          if (comment && comment.id) {
            setComments([...comments, comment]);
          }

          return comment;
        },
        [id, postComment, comments, setComments]
      );

      const onChangeData = useCallback(
        (field: string) => (value: any) => {
          if (fieldErrors[field]) {
            setFieldErrors(omit([field])(fieldErrors));
          }

          setEditorData({
            ...editorData,
            [field]: value,
          });
        },
        [setEditorData, editorData, setFieldErrors, fieldErrors]
      );

      useEffect(() => {
        (async () => {
          const comments = await getComments(id);
          setComments(comments);
          setCommentsLoading(false);
        })();
      }, []);

      useEffect(() => {
        setIsSavedData(false);
        setSaveError("");
      }, [editorData]);

      if (!id) {
        return <div />;
      }

      useEffect(() => {
        disableBodyScroll(document.body, { reserveScrollBarGap: true });

        return () => enableBodyScroll(document.body);
      }, []);

      return (
        <div className={classes.modal}>
          <div className={classes.content}>
            <div className={classes.wrap}>
              <div className={classes.close} onClick={onClose}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 0 24 24"
                  width="24"
                >
                  <path
                    d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                    fill="#3F52B5"
                  />
                </svg>
              </div>

              <div className={classes.editor}>
                {<h2>{dataLoading ? "LOADING..." : editorData.number}</h2>}

                <form
                  className={classNames(classes.fields, {
                    [classes.locked]: isSavingData || dataLoading,
                  })}
                  onSubmit={onSubmit}
                >
                  {EDITABLE_FIELDS.map((field) => (
                    <EntityField
                      name={field}
                      fields={fields}
                      data={editorData}
                      isEditing
                      handler={onChangeData(field)}
                      key={field}
                      error={fieldErrors[field] || ""}
                    />
                  ))}

                  <div className={classes.save_button}>
                    <Button
                      color={changed ? "primary" : "default"}
                      variant="contained"
                      type="submit"
                    >
                      Сохранить
                    </Button>
                  </div>
                </form>

                {isSavingData && (
                  <div className={classes.status}>Сохраняем...</div>
                )}

                {isSavedData && (
                  <div className={classNames(classes.status, classes.success)}>
                    Успешно сохранено
                  </div>
                )}

                {saveError && (
                  <div className={classNames(classes.status, classes.error)}>
                    {saveError}
                  </div>
                )}
              </div>

              <div className={classes.comments}>
                {commentsLoading ? (
                  <div className={classes.loader}>
                    <CircularProgress />
                  </div>
                ) : (
                  <OrderViewComments
                    data={{ ...order, id, comments }}
                    postComment={onPostComment}
                    fetchComments={fetchComments}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }
  )
);

export { OrderExtra };
