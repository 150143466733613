import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import React from "react";
import {Tab} from "@material-ui/core";

export function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export function TabItem(
  props: {
    children: JSX.Element[],
    index: any,
  }
): JSX.Element {
  return (
    <Tab
      label={(<div>{props.children}</div>)}
      {...props}
      {...a11yProps(props.index)} />
  )
}
