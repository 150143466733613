import {IPageProps, Page} from "icerockdev-admin-toolkit";
import {computed, observable} from "mobx";
import {observer} from "mobx-react";
import {Route, RouteComponentProps, Switch} from "react-router-dom";
import {MoneyFlowDetailsView} from "~/config/pages/moneyFlow/MoneyFlowDetailsView";
import React from "react";
import {MoneyFlowListView} from "~/config/pages/moneyFlow/MoneyFlowListView";

export interface IMoneyFlowPageProps extends IPageProps {
  apiBaseUrl: string;
}

type IdRoute = RouteComponentProps<{ id: string }>;

export class MoneyFlowPage extends Page {
  @observable apiBaseUrl: IMoneyFlowPageProps['apiBaseUrl'] = '';

  constructor(fields?: Partial<IMoneyFlowPageProps>) {
    super(fields);

    if (fields) {
      Object.assign(this, fields);
    }
  }

  @computed
  get output(): () => JSX.Element {
    return observer(() => (
      <Switch>
        <Route
          path={this.menu.url}
          component={this.listPage}
        />
        <Route
          path={`${this.menu.url}/:id/`}
          component={this.detailsPage}
        />
      </Switch>
    ));
  }

  @computed
  get detailsPage(): (route: IdRoute) => JSX.Element {
    return observer(
      (route: IdRoute): JSX.Element => {
        return (
          <MoneyFlowDetailsView
            apiBaseUrl={this.apiBaseUrl}
            refKey={route.match.params.id}
            config={this.parent!}/>
        );
      }
    );
  }

  @computed
  get listPage(): () => JSX.Element {
    return () => <MoneyFlowListView
      apiBaseUrl={this.apiBaseUrl}
      config={this.parent!}/>
  }
}
