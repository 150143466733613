export default {
  changes: {
    display: "grid",
    gridAutoFlow: "row",
    gridRowGap: 10,
  },
  change: {
    display: "grid" as "grid",
    gridAutoFlow: "column",
    gridTemplateColumns: "repeat(6, auto) 1fr",
    gridColumnGap: 10,
  },
};
