import {ReportAnalyze} from "~/config/pages/reports/api-types";
import React from "react";
import {Card, CardContent, Grid, Typography} from "@material-ui/core";

export let rubFormatter = new Intl.NumberFormat('ru-RU', {
  style: 'currency',
  currency: 'RUB',
  maximumFractionDigits: 0
});

function defaultFormat(value: number): string {
  return value.toFixed(0);
}

function formatHours(value: number): string {
  return value.toFixed(2) + " h";
}

function formatRub(value: number): string {
  return rubFormatter.format(value);
}

function formatPct(value: number): string {
  return value.toFixed(0) + "%";
}

function formatDefault(value: number): string {
  return value.toFixed(0);
}

function defaultColor(value: number, maxValue: number | null): number {
  return 0xFFFFFFFF;
}

function requiredFill(value: number, maxValue: number | null): number {
  if (maxValue == null) return 0xFFFFFFFF;

  if (value < maxValue) return 0xDD3333FF;
  else return 0xFFFFFFFF
}

function requiredZero(value: number, maxValue: number | null): number {
  if (value > 0) return 0xDD3333FF;
  else return 0xFFFFFFFF
}

function sum<T>(array: T[], getter: (T) => number): number {
  return array
    .map(item => getter(item))
    .reduce((previous, current) => current + previous, 0.0)
}

type HighlightInfo = {
  title: string,
  value: number,
  maxValue: number | null,
  format: (value: number) => string,
  color: (value: number, maxValue: number | null) => number
}

export function HighlightsTab(props: { data: ReportAnalyze }) {
  let report = props.data;
  let capacity: HighlightInfo[] = [
    {
      title: "Сотрудников",
      value: report.employees.length,
      maxValue: null,
      format: formatDefault,
      color: defaultColor
    },
    {
      title: "Полная ёмкость",
      value: sum(report.employees, item => item.fullHours),
      maxValue: null,
      format: formatHours,
      color: defaultColor
    },
    {
      title: "Выпадающая ёмкость",
      value: sum(report.employees, item => item.droppedHours),
      maxValue: sum(report.employees, item => item.fullHours),
      format: formatHours,
      color: defaultColor
    },
    {
      title: "Доступная ёмкость",
      value: sum(report.employees, item => item.workHours),
      maxValue: sum(report.employees, item => item.fullHours),
      format: formatHours,
      color: defaultColor
    },
    {
      title: "Коммерческая ёмкость",
      value: sum(report.employees, item => item.orderTrackingHours),
      maxValue: sum(report.employees, item => item.fullHours),
      format: formatHours,
      color: defaultColor
    },
  ];
  let tracking: HighlightInfo[] = [
    {
      title: "Затреканные часы",
      value: sum(report.employees, item => item.trackingHours),
      maxValue: sum(report.employees, item => item.workHours),
      format: formatHours,
      color: requiredFill
    },
    {
      title: "Коммерческие часы",
      value: sum(report.employees, item => item.orderTrackingHours),
      maxValue: sum(report.employees, item => item.trackingHours),
      format: formatHours,
      color: defaultColor
    },
    {
      title: "Внутрянка",
      value: sum(report.employees, item => item.internalTrackingHours),
      maxValue: sum(report.employees, item => item.trackingHours),
      format: formatHours,
      color: defaultColor
    },
    {
      title: "Не привязано",
      value: sum(report.employees, item => item.noOrderTrackingHours),
      maxValue: null,
      format: formatHours,
      color: requiredZero
    },
    {
      title: "Привязан не верный заказ",
      value: sum(report.employees, item => item.illegalOrderTrackingHours),
      maxValue: null,
      format: formatHours,
      color: requiredZero
    },
    {
      title: "Недогруз",
      value: sum(report.employees, item => item.benchHours),
      maxValue: null,
      format: formatHours,
      color: requiredZero
    }
  ];
  let orders: HighlightInfo[] = [
    {
      title: "Заказов завершено",
      value: report.orders.filter(item => item.factDate != null).length,
      maxValue: report.orders.length,
      format: defaultFormat,
      color: defaultColor
    },
    {
      title: "Закрыто часов",
      value: sum(report.orders.filter(item => item.factDate != null), item => item.soldHours),
      maxValue: sum(report.orders, item => item.soldHours),
      format: formatHours,
      color: defaultColor
    },
    {
      title: "Выручка",
      value: sum(report.orders.filter(item => item.factDate != null), item => item.revenueRub),
      maxValue: sum(report.orders, item => item.revenueRub),
      format: formatRub,
      color: defaultColor
    },
    {
      title: "Себестоимость закрытых заказов",
      value: sum(report.orders.filter(item => item.factDate != null), item => item.factCostPriceRub + item.calculatedCostPriceRub),
      maxValue: sum(report.orders.filter(item => item.factDate != null), item => item.revenueRub),
      format: formatRub,
      color: defaultColor
    },
    {
      title: "Себестоимость активных заказов",
      value: sum(report.orders.filter(item => item.factDate == null), item => item.factCostPriceRub + item.calculatedCostPriceRub),
      maxValue: sum(report.orders.filter(item => item.factDate == null), item => item.revenueRub),
      format: formatRub,
      color: defaultColor
    }
  ];
  let infoItems: HighlightInfo[] = [
    {
      title: "Больничных",
      value: sum(report.employees, item => item.sickLeaveHours),
      maxValue: null,
      format: formatHours,
      color: defaultColor
    },
    {
      title: "Sick days",
      value: sum(report.employees, item => item.sickDaysHours),
      maxValue: null,
      format: formatHours,
      color: defaultColor
    },
    {
      title: "Отпусков",
      value: sum(report.employees, item => item.vacationHours),
      maxValue: null,
      format: formatHours,
      color: defaultColor
    },
    {
      title: "За свой счет",
      value: sum(report.employees, item => item.notWorkHours),
      maxValue: null,
      format: formatHours,
      color: defaultColor
    }
  ]

  return (
    <div>
      <p>Capacity analyze:</p>
      <HighlightsGroup items={capacity}/>
      <p>Tracking analyze:</p>
      <HighlightsGroup items={tracking}/>
      <p>Orders analyze:</p>
      <HighlightsGroup items={orders}/>
      <p>Additional info:</p>
      <HighlightsGroup items={infoItems}/>
    </div>
  )
}

function HighlightsGroup(props: { items: HighlightInfo[] }) {
  let highlights = props.items;
  let items = highlights.map(item => (
    <Grid item xs={2} sm={2} md={2} key={item.title}>
      <HighlightItem
        title={item.title}
        value={item.value}
        maxValue={item.maxValue}
        format={item.format}
        color={item.color}/>
    </Grid>
  ));
  return (
    <Grid container spacing={2}>
      {items}
    </Grid>
  );
}

function HighlightItem(props: HighlightInfo) {
  let body: string
  let formatter = props.format
  if (props.maxValue != null) {
    let pct: number = (props.value / props.maxValue) * 100.0;
    body = formatter(props.value) + " / " + formatter(props.maxValue) + " (" + pct.toFixed(0) + "%)";
  } else {
    body = formatter(props.value);
  }
  let color: number = props.color(props.value, props.maxValue);
  return (
    <Card>
      <CardContent style={{backgroundColor: '#' + color.toString(16)}}>
        <Typography color="textPrimary">{props.title}</Typography>
        <Typography variant="body2">{body}</Typography>
      </CardContent>
    </Card>
  )
}
