import React, {useCallback, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import {
  Hours,
  MoneyAmount,
  SalesOrderAnalyzeOrder,
  SalesOrderAnalyzePayment,
  SalesOrderAnalyzePaymentFact,
  SalesOrderAnalyzePaymentPlan
} from "~/config/pages/moneyFlow/api-types";
import {formatAmount, formatHours} from "~/config/pages/moneyFlow/formatters";
import {hoursSum, moneyAmountSum, SalesOrderAnalyzeExtended} from "~/config/pages/moneyFlow/SalesOrderAnalyzeExt";
import {Tabs} from "@material-ui/core";
import {TabItem, TabPanel} from "~/components/Tabs/TabPanel";
import {SubmitableTextField} from "~/components/SubmitableTextField";
import {setDetails} from "~/config/pages/moneyFlow/api";
import {Config} from "icerockdev-admin-toolkit";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset"
    }
  }
});

function HoursInfo(props: { hours: Hours }): JSX.Element {
  const {hours} = props;

  return (
    <div>
      <Tooltip title="Продано часов">
        <span>{formatHours(hours.sold)}</span>
      </Tooltip>
      &nbsp;/&nbsp;
      <Tooltip title="Оценка производства">
        <span>{formatHours(hours.prod)}</span>
      </Tooltip>
      &nbsp;/&nbsp;
      <Tooltip title="Потрачено часов">
        <span>{formatHours(hours.spent)}</span>
      </Tooltip>
    </div>
  );
}

function AmountInfo(props: { amount: MoneyAmount, style?: any }): JSX.Element {
  const amount: MoneyAmount = props.amount;
  let rubAmount: JSX.Element | null;
  if (amount.currency == "₽") {
    rubAmount = null;
  } else {
    rubAmount = (<span style={{paddingLeft: 4}}>({formatAmount(amount.amountInRub)} ₽)</span>);
  }
  return (
    <div style={props.style}>
      <span>{formatAmount(amount.amountInCurrency)} {amount.currency}</span>
      {rubAmount}
    </div>
  );
}

function OrderLink(props: { order: SalesOrderAnalyzeOrder }): JSX.Element {
  let head: SalesOrderAnalyzeOrder = props.order;
  return (
    <a href={`orders/${head.id}`}>{head.number}</a>
  )
}

function OrderDetailsRow(props: { order: SalesOrderAnalyzeOrder }): JSX.Element {
  const {order} = props;

  return (
    <TableRow key={order.id}>
      <TableCell component="th" scope="row">
        <OrderLink order={order}/>
      </TableCell>
      <TableCell>{order.description}</TableCell>
      <TableCell>
        <Tooltip title="Изначально запланированная дата приёмки">
          <div>{order.plannedApproveDate}</div>
        </Tooltip>
        <Tooltip title="Ожидаемая дата приёмки">
          <div>{order.estimatedApproveDate}</div>
        </Tooltip>
        <Tooltip title="Фактическая дата приёмки">
          <div>{order.factApproveDate}</div>
        </Tooltip>
        <Tooltip title="Крайний срок по ДС">
          <div>{order.supplementaryAgreementDate}</div>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip title="Статус производства">
          <div>{order.productionStatus}</div>
        </Tooltip>
        <Tooltip title="Статус продаж">
          <div>{order.salesStatus}</div>
        </Tooltip>
      </TableCell>
      <TableCell align="right">
        <AmountInfo amount={order.amount}/>
      </TableCell>
      <TableCell align="right">
        <HoursInfo hours={order.hours}/>
      </TableCell>
    </TableRow>
  );
}

function OrderPlanPayment(
  props: {
    payment: SalesOrderAnalyzePaymentPlan | null,
    apiBaseUrl: string,
    config: Config
  }
): JSX.Element {
  const {payment} = props;

  if (payment == null) return <p>-</p>;

  const onSubmit = useCallback((text: string): void => {
    setDetails(props.apiBaseUrl, props.config, payment.refKey, text)
      .then(result => console.debug(`comment saved for ${payment.refKey}`))
      .catch(error => console.error(`comment save failed for ${payment.refKey}`, error))
  }, [payment]);

  return (
    <table style={{width: "100%"}}>
      <thead>
      <tr>
        <th style={{width: 100}}></th>
        <th></th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>Дата</td>
        <td>
          <Tooltip title="Текущее плановое значение">
            <span>{payment.current.date}</span>
          </Tooltip>
        </td>
        <td
          style={{textDecoration: 'line-through'}}
          hidden={payment.original.date == payment.current.date}
        >
          <Tooltip title="Изначальное плановое значение">
            <span>{payment.original.date}</span>
          </Tooltip>
        </td>
      </tr>
      <tr>
        <td>Оплата</td>
        <td>
          <AmountInfo amount={payment.current.amount}/>
        </td>
        <td
          style={{textDecoration: 'line-through'}}
          hidden={payment.original.amount.amountInCurrency == payment.current.amount.amountInCurrency}
        >
          <AmountInfo amount={payment.original.amount}/>
        </td>
      </tr>
      <tr>
        <td>Финотдел</td>
        <td colSpan={2}>{payment.financeComment}</td>
      </tr>
      <tr>
        <td>Продажи</td>
        <td colSpan={2}>
          <SubmitableTextField
            initialValue={payment.salesComment}
            onSubmit={onSubmit}
            props={{style: {width: "100%"}}}
          />
        </td>
      </tr>
      </tbody>
    </table>
  );
}

function OrderFactPayment(props: { payment: SalesOrderAnalyzePaymentFact }): JSX.Element {
  const {payment} = props;

  return (
    <TableRow key={payment.refKey}>
      <TableCell component="th" scope="row">
        {payment.date}
      </TableCell>
      <TableCell>
        <OrderLink order={payment["order"]}/>
      </TableCell>
      <TableCell>
        <AmountInfo amount={payment.amount}/>
      </TableCell>
    </TableRow>
  );
}

function OrderPaymentRow(
  props: {
    payment: SalesOrderAnalyzePayment,
    apiBaseUrl: string,
    config: Config
  }
): JSX.Element {
  const {payment} = props;

  let factRows: JSX.Element[] = payment.fact.map((fact) => (
    <OrderFactPayment payment={fact}/>
  ));

  return (
    <TableRow>
      <TableCell component="th" scope="row" style={{width: "50%"}}>
        <OrderPlanPayment payment={payment.plan} apiBaseUrl={props.apiBaseUrl} config={props.config}/>
      </TableCell>
      <TableCell style={{width: "50%"}}>
        {factRows.length === 0 ? <p>-</p> : factRows}
      </TableCell>
    </TableRow>
  );
}

function PaymentsFooter(props: { row: SalesOrderAnalyzeExtended }): JSX.Element {
  const {row} = props;

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <AmountInfo amount={row.plannedAmount}/>
      </TableCell>
      <TableCell>
        <AmountInfo amount={row.factAmount}/>
      </TableCell>
    </TableRow>
  );
}

function CollapseBody(
  props: {
    row: SalesOrderAnalyzeExtended,
    apiBaseUrl: string,
    config: Config
  }
): JSX.Element {
  const {row} = props;

  return (
    <Box margin={1}>
      <Typography variant="h6" gutterBottom component="div">
        Заказы
      </Typography>
      <Table size="small" aria-label="orders">
        <TableHead>
          <TableRow>
            <TableCell>Номер</TableCell>
            <TableCell>Описание</TableCell>
            <TableCell>Даты</TableCell>
            <TableCell>Статусы</TableCell>
            <TableCell align="right">Стоимость</TableCell>
            <TableCell align="right">Часы</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {row.orders.map((order) => (
            <OrderDetailsRow order={order}/>
          ))}
        </TableBody>
      </Table>
      <br/>
      <Typography variant="h6" gutterBottom component="div">
        Оплаты
      </Typography>
      <Table size="small" aria-label="payments">
        <TableHead>
          <TableRow>
            <TableCell>План</TableCell>
            <TableCell>Факт</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {row.payments.map((paymentRow) => (
            <OrderPaymentRow payment={paymentRow} apiBaseUrl={props.apiBaseUrl} config={props.config}/>
          ))}
        </TableBody>
        <TableFooter>
          <PaymentsFooter row={row}/>
        </TableFooter>
      </Table>
    </Box>
  );
}

function Row(
  props: {
    row: SalesOrderAnalyzeExtended,
    apiBaseUrl: string,
    config: Config
  }
): JSX.Element {
  const {row} = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  const head: SalesOrderAnalyzeOrder = row["headOrder"];

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
          </IconButton>
        </TableCell>
        <TableCell>{head.salesUsername}</TableCell>
        <TableCell>
          <OrderLink order={head}/>
        </TableCell>
        <TableCell>{head.jiraCategory}</TableCell>
        <TableCell align="right">
          <HoursInfo hours={row.fullHours}/>
        </TableCell>
        <TableCell align="right">
          <Tooltip title="Стоимость по 1С">
            <Typography variant="body2" color="textPrimary">
              <AmountInfo amount={row.fullAmount}/>
            </Typography>
          </Tooltip>
          <Tooltip title="Расчетная стоимость, на основании рейта и потраченных часов">
            <Typography variant="caption" color="textSecondary">
              <AmountInfo amount={row.estimatedAmount}/>
            </Typography>
          </Tooltip>
        </TableCell>
        <TableCell align="right">
          <AmountInfo amount={row.toPayAmount}/>
        </TableCell>
        <TableCell align="right">
          <AmountInfo amount={row.plannedNotPaidAmount}/>
        </TableCell>
        <TableCell align="right">
          <AmountInfo amount={row.toPlanAmount}/>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <CollapseBody row={row} apiBaseUrl={props.apiBaseUrl} config={props.config}/>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function AmountSum(
  props: {
    orders: SalesOrderAnalyzeExtended[],
    mapper: (SalesOrderAnalyzeExtended) => MoneyAmount
  }
): JSX.Element {
  let sum: string = formatAmount(moneyAmountSum(props.orders.map(props.mapper)).amountInRub);
  return (<span>{sum} ₽</span>)
}

function OrdersTable(
  props: {
    orders: SalesOrderAnalyzeExtended[],
    apiBaseUrl: string,
    config: Config
  }
): JSX.Element {
  return (
    <TableContainer component={Paper} style={{marginTop: 8, marginBottom: 8}}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell/>
            <TableCell>Продавец</TableCell>
            <TableCell>Заказ</TableCell>
            <TableCell>Проект</TableCell>
            <TableCell align="right">Объем</TableCell>
            <TableCell align="right">Стоимость</TableCell>
            <TableCell align="right">К оплате</TableCell>
            <TableCell align="right">Запланировано</TableCell>
            <TableCell align="right">Не запланировано</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.orders.map((row) => (
            <Row key={row.headOrderId} row={row} apiBaseUrl={props.apiBaseUrl} config={props.config}/>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell/>
            <TableCell/>
            <TableCell/>
            <TableCell/>
            <TableCell align="right">
              <HoursInfo hours={hoursSum(props.orders.map(item => item.fullHours))}/>
            </TableCell>
            <TableCell align="right">
              <AmountSum
                orders={props.orders}
                mapper={(item) => item.fullAmount}
              />
            </TableCell>
            <TableCell align="right">
              <AmountSum
                orders={props.orders}
                mapper={(item) => item.toPayAmount}
              />
            </TableCell>
            <TableCell align="right">
              <AmountSum
                orders={props.orders}
                mapper={(item) => item.plannedNotPaidAmount}
              />
            </TableCell>
            <TableCell align="right">
              <AmountSum
                orders={props.orders}
                mapper={(item) => item.toPlanAmount}
              />
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

enum DashboardTab {
  Debit,
  BadDebit,
  Incomplete,
  Paid
}

export default function SalesDashboardView(
  props: {
    rows: SalesOrderAnalyzeExtended[],
    apiBaseUrl: string,
    config: Config
  }
): JSX.Element {
  let toPay: SalesOrderAnalyzeExtended[] = [...props.rows].filter(item => {
    return item.toPayAmount.amountInCurrency != 0;
  }).sort((a, b) => {
    return b.toPayAmount.amountInRub - a.toPayAmount.amountInRub;
  });
  let debit: SalesOrderAnalyzeExtended[] = toPay.filter(item => {
    let headOrder: SalesOrderAnalyzeOrder = item.headOrder;
    return headOrder.productionStatus == "DONE";
  });
  let normalDebit: SalesOrderAnalyzeExtended[] = debit.filter(item => {
    let headOrder: SalesOrderAnalyzeOrder = item.headOrder;
    let now: Date = new Date();
    let approve: Date = new Date(headOrder.factApproveDate!!);
    return (now.valueOf() - approve.valueOf()) < 1000 * 60 * 60 * 24 * 90;
  });
  let badDebit: SalesOrderAnalyzeExtended[] = debit.filter(item => normalDebit.findIndex(toPay => toPay.headOrderId == item.headOrderId) == -1);
  let inProgress: SalesOrderAnalyzeExtended[] = toPay.filter(item => debit.findIndex(toPay => toPay.headOrderId == item.headOrderId) == -1);
  let paid: SalesOrderAnalyzeExtended[] = props.rows.filter(item => toPay.findIndex(toPay => toPay.headOrderId == item.headOrderId) == -1)

  const [
    selectedTab,
    setSelectedTab
  ] = useState<DashboardTab>(() => DashboardTab.Debit);

  const handleChange = useCallback((event, newValue) => {
    setSelectedTab(newValue)
  }, [setSelectedTab]);

  return (
    <div>
      <Tabs value={selectedTab} onChange={handleChange}>
        <TabItem index={DashboardTab.Debit}>
          <div>Дебиторка</div>
          <AmountSum
            orders={normalDebit}
            mapper={(item) => item.toPayAmount}
          />
        </TabItem>
        <TabItem index={DashboardTab.BadDebit}>
          <div>Плохая дебиторка</div>
          <AmountSum
            orders={badDebit}
            mapper={(item) => item.toPayAmount}
          />
        </TabItem>
        <TabItem index={DashboardTab.Incomplete}>
          <div>Незавершенка</div>
          <AmountSum
            orders={inProgress}
            mapper={(item) => item.toPayAmount}
          />
        </TabItem>
        <TabItem index={DashboardTab.Paid}>
          <div>Закрыто</div>
          <AmountSum
            orders={paid}
            mapper={(item) => item.fullAmount}
          />
        </TabItem>
      </Tabs>

      <TabPanel value={selectedTab} index={DashboardTab.Debit}>
        <OrdersTable orders={normalDebit} apiBaseUrl={props.apiBaseUrl} config={props.config}/>
      </TabPanel>
      <TabPanel value={selectedTab} index={DashboardTab.BadDebit}>
        <OrdersTable orders={badDebit} apiBaseUrl={props.apiBaseUrl} config={props.config}/>
      </TabPanel>
      <TabPanel value={selectedTab} index={DashboardTab.Incomplete}>
        <OrdersTable orders={inProgress} apiBaseUrl={props.apiBaseUrl} config={props.config}/>
      </TabPanel>
      <TabPanel value={selectedTab} index={DashboardTab.Paid}>
        <OrdersTable orders={paid} apiBaseUrl={props.apiBaseUrl} config={props.config}/>
      </TabPanel>
    </div>
  )
}
