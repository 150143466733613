import React, { FC } from 'react';
import { GroupedHealthStatus } from '../../../api-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { IconButton } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { makeStyles } from '@material-ui/core/styles';
import { rubFormatter } from '../../highlightsTab';

const useStyles = makeStyles({
  tableRow: {
    '& .MuiTableCell-root': {
      padding: '16px 8px',
    },
  },
});

type Props = {
  groupedData: GroupedHealthStatus[];
  isCanBeCompleted?: boolean;
};

const GroupRows: FC<Props> = ({ groupedData, isCanBeCompleted }) => {
  const classes = useStyles();
  const typeText = isCanBeCompleted ? 'Закроем' : 'Не закроем';

  const [isRowsCallpsed, setIsRowsCallpsed] = React.useState(false);

  const totalSoldHours = groupedData.reduce((acc, curr) => acc + curr.sumSoldHours, 0);
  const totalTrackedHours = groupedData.reduce((acc, curr) => acc + curr.sumTrackingHours, 0);
  const totalAvrerageRate =
    groupedData.reduce((acc, curr) => acc + curr.sumSoldHours * curr.averageRateRub, 0) /
    totalSoldHours;
  const totalFactCostPriceRub = groupedData.reduce(
    (acc, curr) => acc + curr.sumFactCostPriceRub,
    0
  );
  const totalCalculatedCostPriceRub = groupedData.reduce(
    (acc, curr) => acc + curr.sumCalculatedCostPriceRub,
    0
  );
  const totalRevenueRub = groupedData.reduce((acc, curr) => acc + curr.sumRevenueRub, 0);
  const averagePercentagePriceRub = groupedData.reduce(
    (acc, curr) =>
      acc +
      ((curr.sumFactCostPriceRub + curr.sumCalculatedCostPriceRub) / curr.sumRevenueRub) * 100,
    0
  );

  return (
    <>
      {isRowsCallpsed &&
        groupedData.map((row, indx) => (
          <TableRow className={classes.tableRow} key={`${row.healthStatus}-${indx}`}>
            {indx === 0 ? (
              <TableCell>
                <IconButton onClick={() => setIsRowsCallpsed(!isRowsCallpsed)}>
                  <KeyboardArrowUpIcon />
                </IconButton>
                {typeText}
              </TableCell>
            ) : (
              <TableCell />
            )}
            <TableCell component="th" scope="row">
              {row.healthStatus}
            </TableCell>
            <TableCell align="right">{row.sumSoldHours.toFixed(2)}</TableCell>
            <TableCell align="right">{row.sumTrackingHours.toFixed(2)}</TableCell>
            <TableCell align="right">{rubFormatter.format(row.averageRateRub)}</TableCell>
            <TableCell align="right">{rubFormatter.format(row.sumFactCostPriceRub)}</TableCell>
            <TableCell align="right">
              {rubFormatter.format(row.sumCalculatedCostPriceRub)}
            </TableCell>
            <TableCell align="right">{rubFormatter.format(row.sumRevenueRub)}</TableCell>
            <TableCell align="right">
              {(
                ((row.sumFactCostPriceRub + row.sumCalculatedCostPriceRub) / row.sumRevenueRub) *
                100
              ).toFixed(0)}
              %
            </TableCell>
          </TableRow>
        ))}
      <TableRow className={classes.tableRow}>
        <TableCell component="th" scope="row">
          {!isRowsCallpsed && (
            <IconButton onClick={() => setIsRowsCallpsed(!isRowsCallpsed)}>
              <KeyboardArrowDownIcon />
            </IconButton>
          )}
          {typeText} Total
        </TableCell>
        <TableCell align="right"></TableCell>
        <TableCell align="right">{totalSoldHours.toFixed(2)}</TableCell>
        <TableCell align="right">{totalTrackedHours.toFixed(2)}</TableCell>
        <TableCell align="right">{rubFormatter.format(totalAvrerageRate)}</TableCell>
        <TableCell align="right">{rubFormatter.format(totalFactCostPriceRub)}</TableCell>
        <TableCell align="right">{rubFormatter.format(totalCalculatedCostPriceRub)}</TableCell>
        <TableCell align="right">{rubFormatter.format(totalRevenueRub)}</TableCell>
        <TableCell align="right">
          {(averagePercentagePriceRub / groupedData.length).toFixed(0)}%
        </TableCell>
      </TableRow>
    </>
  );
};

export default GroupRows;
