import React, { FC, useMemo } from "react";
import { IEntityFieldProps, EntityFieldString } from "icerockdev-admin-toolkit";
import styles from "./styles";
import { WithStyles, withStyles } from "@material-ui/core";
import { Link } from "react-router-dom";

type IProps = IEntityFieldProps & WithStyles<typeof styles> & {};

const WorklogOrderUnconnected: FC<IProps> = ({
  value,
  classes,
  data,
  isEditing,
  isFiltering,
  fields,
  name,
  handler,
}) => {
  const field = useMemo(() => fields.find((field) => field.name === name), [
    fields,
    name,
  ]);

  if (!field || !data) return null;

  if (isFiltering)
    return (
      <EntityFieldString
        value={
          Object.prototype.hasOwnProperty.call(data, field.name)
            ? data[field.name]
            : null
        }
        label={field.label}
        name={name}
        isEditing={isEditing}
        handler={handler}
        fields={fields}
      />
    );

  if (!value) return null;

  return (
    <div className={classes.wrap}>
      <Link
        to={`/orders/#number=${value}`}
        target="_blank"
        className={classes.link}
      >
        {value}
      </Link>

      <div className="hint">
        {data?.projectCategory && <b>{data?.projectCategory}</b>}
        {data?.orderDescription && <div>{data?.orderDescription}</div>}
      </div>
    </div>
  );
};

const WorklogOrder = withStyles(styles)(WorklogOrderUnconnected) as FC<IProps>;

export { WorklogOrder };
