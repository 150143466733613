import React, { FC, useMemo } from "react";
import { getOrdersByTypes } from "./utils";
import { List, ListItem, Paper } from "@material-ui/core";

interface IProps {
  orders: Record<string, any>;
}

const OrderNumberList: FC<IProps> = ({ orders }) => {
  const orderNumbers = useMemo(() => getOrdersByTypes(Object.values(orders)), [
    orders,
  ]);

  return (
    <Paper>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        {Object.keys(orderNumbers).map((type) => (
          <List key={type}>
            {orderNumbers[type].map((el) => (
              <ListItem key={el.id}>{el.number}</ListItem>
            ))}
          </List>
        ))}
      </div>
    </Paper>
  );
};

export { OrderNumberList };
