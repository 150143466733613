import { IEntityField } from "icerockdev-admin-toolkit";
import { WorklogTime } from "./WorklogTime";
import { WorklogIssue } from "./WorklogIssue";
import { WorklogOrder } from "./WorklogOrder";
import { WorklogComment } from "./WorklogComment";
import { EntityFieldDateRange } from "~/config/pages/worklog/FieldDateRange";
import {OrderSlotSelect} from "~/config/pages/orders/OrderSlotSelect";

export const WORKLOG_FIELDS: IEntityField[] = [
  {
    name: "issueKey",
    label: "Задача",
    type: "custom",
    filterable: true,
    sortable: true,
    hideInCreate: false,
    hideInList: false,
    hideInEdit: false,
    hideInExport: false,
    component: WorklogIssue,
  },
  {
    name: "issueSummary",
    label: "Описание",
    type: "custom",
    filterable: false,
    sortable: true,
    hideInCreate: false,
    hideInList: false,
    hideInEdit: false,
    hideInExport: false,
    component: WorklogComment,
  },
  {
    name: "order",
    label: "Заказ",
    type: "string",
    filterable: true,
    sortable: true,
    hideInCreate: false,
    hideInList: false,
    hideInEdit: false,
    hideInExport: false,
    component: WorklogOrder,
  },
  {
    name: "isBench",
    label: "Недогруз",
    type: "boolean",
    filterable: true,
    sortable: false,
    hideInCreate: false,
    hideInList: false,
    hideInEdit: false,
    hideInExport: false,
  },
  {
    name: "dateTime",
    type: "date",
    label: "Дата",
    filterable: false,
    sortable: true,
    hideInCreate: false,
    hideInList: false,
    hideInEdit: false,
    hideInExport: false,
  },
  {
    name: "dateTimeFilter",
    type: "custom",
    label: "Дата",
    filterable: true,
    sortable: true,
    hideInCreate: false,
    hideInList: true,
    hideInEdit: false,
    hideInExport: false,
    component: EntityFieldDateRange
  },
  {
    name: "author",
    label: "Автор",
    type: "string",
    filterable: false,
    sortable: true,
    hideInCreate: false,
    hideInList: false,
    hideInEdit: false,
    hideInExport: false,
  },
  {
    name: "worklogComment",
    label: "Комментарий",
    type: "string",
    filterable: false,
    sortable: false,
    hideInCreate: false,
    hideInList: false,
    hideInEdit: false,
    hideInExport: false,
  },
  {
    name: "workedSeconds",
    label: "Время",
    type: "custom",
    filterable: false,
    sortable: true,
    hideInCreate: false,
    hideInList: false,
    hideInEdit: false,
    hideInExport: false,
    component: WorklogTime,
  },

  // filters only:
  {
    name: "projectCategory",
    label: "Категория Jira",
    type: "referenceSelect",
    filterable: true,
    sortable: true,
    hideInCreate: false,
    hideInList: true,
    hideInEdit: false,
    hideInExport: false,
  },
  {
    name: "projectLead",
    label: "Ответственный",
    type: "referenceSelect",
    filterable: true,
    sortable: true,
    hideInCreate: false,
    hideInList: true,
    hideInEdit: false,
    hideInExport: false,
  },
  {
    name: "version",
    label: "Версия",
    type: "string",
    filterable: true,
    sortable: false,
    hideInCreate: true,
    hideInList: true,
    hideInEdit: true,
    hideInExport: true,
  },
  {
    name: "authorName",
    label: "Автор",
    type: "string",
    filterable: true,
    sortable: false,
    hideInCreate: true,
    hideInList: true,
    hideInEdit: true,
    hideInExport: true,
  },
  {
    name: "authorSlot",
    label: "Слот автора",
    type: "referenceSelect",
    component: OrderSlotSelect,
    filterable: true,
    sortable: false,
    hideInCreate: true,
    hideInList: true,
    hideInEdit: true,
    hideInExport: true,
  },
  {
    name: "projectKey",
    label: "Код проекта",
    type: "string",
    filterable: true,
    sortable: false,
    hideInCreate: true,
    hideInList: true,
    hideInEdit: true,
    hideInExport: true,
  },
  {
    name: "worklogComment",
    label: "Комментарий",
    type: "string",
    filterable: true,
    sortable: false,
    hideInCreate: true,
    hideInList: true,
    hideInEdit: true,
    hideInExport: true,
  }
];
