import React, { FC } from 'react';
import { GroupedProject } from '../../../api-types';
import CallapsedRow from './CallapsedRow';

type Props = {
  projectOrders: GroupedProject;
};

const ProfitabilityProjectGroupRows: FC<Props> = ({ projectOrders }) => {
  return (
    <CallapsedRow
      rowTitle={projectOrders.project}
      sumRevenueRub={projectOrders.sumRevenueRub}
      sumSoldHours={projectOrders.sumSoldHours}
      sumTrackingHours={projectOrders.sumTrackingHours}
      sumPriceRub={projectOrders.sumPriceRub}
      developmentExpenses={projectOrders.developmentExpenses}
      level="dark"
    >
      {projectOrders.slots.map((slotItem) => (
        <CallapsedRow
          key={slotItem.slotName}
          rowTitle={slotItem.slotName}
          sumRevenueRub={slotItem.sumRevenueRub}
          sumSoldHours={slotItem.sumSoldHours}
          sumTrackingHours={slotItem.sumTrackingHours}
          sumPriceRub={slotItem.sumPriceRub}
          developmentExpenses={
            slotItem.orders
              .map((order) => order.devExpensesRub)
              .reduce((prev, curr) => curr + prev, 0)
          }
          level="average"
        >
          {slotItem.orders.map((order) => (
            <CallapsedRow
              key={order.orderNumber}
              rowTitle={order.orderNumber}
              sumRevenueRub={order.revenueRub}
              sumSoldHours={order.soldHours}
              sumTrackingHours={order.trackingHours}
              sumPriceRub={order.factCostPriceRub + order.calculatedCostPriceRub}
              developmentExpenses={order.devExpensesRub}
              level="none"
            />
          ))}
        </CallapsedRow>
      ))}
    </CallapsedRow>
  );
};

export default ProfitabilityProjectGroupRows;
