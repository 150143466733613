import { Theme } from "@material-ui/core";

export default (theme: Theme) => ({
  wrap: {
    position: 'relative' as 'relative',

    '& .hint': {
      position: 'absolute' as 'absolute',
      bottom: '100%',
      transform: 'translate(0, -4px)',
      left: 0,
      display: 'none',
      padding: '10px 10px',
      zIndex: 2,
      background: '#333333',
      color: 'white',
      boxShadow: 'rgba(0,0,0,0.3) 3px 5px 5px',
      width: 'max-content',
      maxWidth: 250,
      borderRadius: 4,
      margin: '0 auto',
    },

    '& .hint div': {
      marginTop: 5,
    },
    
    '&:hover .hint': {
      display: 'block',
    }
  },
  link: {
    background: '#999999',
    display: 'flex',
    borderRadius: 4,
    height: 25,
    alignItems: 'center' as 'center',
    justifyContent: 'center' as 'center',
    color: 'white',
    fontWeight: 'bold' as 'bold',
    textDecoration: 'none'
  }
});
