import {IReactComponent} from "mobx-react/dist/types/IReactComponent";
import {observer} from "mobx-react";
import React, {useState} from "react";
import {IconButton, Input} from "@material-ui/core";
import {Save} from "@material-ui/icons";

interface SubmitableTextFieldProps {
  initialValue: string;
  onSubmit: (string) => void;
  title?: string;
  className?: string;
  props?: any
}

export const SubmitableTextField: IReactComponent<SubmitableTextFieldProps> = observer(
  (
    props: SubmitableTextFieldProps
  ): JSX.Element => {
    const [
      value,
      setValue
    ] = useState<string>(() => props.initialValue);

    return (
      <Input
        className={props.className}
        style={{padding: "0px 16px"}}
        placeholder={props.title}
        value={value}
        onChange={(event) => setValue(event.target.value)}
        endAdornment={
          <IconButton onClick={() => {
            props.onSubmit(value)
          }}>
            <Save/>
          </IconButton>
        }
        {...props.props}
      />
    );
  }
);
