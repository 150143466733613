import React, { Fragment } from "react";
import { Entity, EntityList, IEntityProps } from "icerockdev-admin-toolkit";
import { computed, observable, action, toJS, reaction } from "mobx";
import { observer } from "mobx-react";
import { Button } from "@material-ui/core";
import { WorklogAssignModal } from "../WorklogAssignModal";
import { getWorklogOrdersAutocomplete, setWorklogOrders } from "../api";

export type OrderAutocomplete = {
  id: number;
  number: string;
  jiraCategory: string;
  description: string;
};

export class WorklogEntity extends Entity {
  @observable isModalShown = false;
  @action
  showModal = () => (this.isModalShown = true);
  @action
  hideModal = () => {
    this.selected = [];
    this.isModalShown = false;
  };
  itemsPerPage = [50, 100, 200, 500];
  items = 50;

  @action
  getOrdersAutocomplete = async (): Promise<OrderAutocomplete[]> =>
    this.parent?.auth?.withToken(
      getWorklogOrdersAutocomplete(this.api?.autocomplete?.url || ""),
      {}
    );

  getFilters = (): IEntityProps["filters"] =>
    (this.filters.length > 0 &&
      toJS(this.filters).filter(
        (el) => (el.name && el.value !== "") || el.name === "order"
      )) ||
    [];

  @action
  onRowClick = (id, event) => {
    if (event.target.tagName === "A") return;

    if (this.selected.length) {
      if (this.selected.some((el) => el === id)) {
        this.selected = this.selected.filter((el) => el !== id);
      } else {
        this.selected = [...this.selected, id];
      }
    } else {
      this.selected = [id];
      this.showModal();
    }
  };

  @action saveWorklogOrders = async ({
    worklogs,
    orderId,
    isBench
  }: {
    worklogs: number[];
    orderId: number | null;
    isBench: boolean | null;
  }) => {
    if (!this.parent?.auth?.withToken) return;

    try {
      const result = await this.parent.auth.withToken(
        setWorklogOrders(this.api?.update.url || ""),
        {
          worklogs,
          orderId: orderId,
          isBench: isBench
        }
      );

      if (result.error) {
        throw new Error(result.error);
      }

      return true;
    } catch (e) {
      this.parent?.notifications.showError(e.message);
      return false;
    }
  };

  @computed
  get ListBody() {
    return observer(() => (
      <EntityList
        fields={this.fields}
        data={this.data}
        extra={this.ListExtra}
        isLoading={this.isLoading}
        url={this.menu.url}
        selected={this.selected}
        sortBy={this.sortBy}
        sortDir={this.sortDir}
        canView={this.viewable}
        canEdit={this.editable && this.canEdit}
        canSelect={this.selectable}
        setSelected={this.setSelected}
        onSortChange={this.setSort}
        withToken={this.parent?.auth?.withToken}
        onRowClick={this.onRowClick}
        entity={this}
      />
    ));
  }

  @action
  onChangeOrderSubmit = async (order: OrderAutocomplete | null, isBench: boolean | null) => {
    const success = await this.saveWorklogOrders({
      worklogs: this.selected,
      orderId: order?.id ?? null,
      isBench: isBench ?? null
    });

    if (success) {
      this.data = this.data.map((item) => {
          if (!this.selected.includes(item.id)) return item;

          let newItem: Record<string, any> = item
          if (order != null) {
            newItem = {
              ...newItem,
              order: order.number,
              orderId: order.id,
              orderDescription: order.description,
            };
          }
          if (isBench != null) {
            newItem = {
              ...newItem,
              isBench: isBench
            };
          }

          return newItem;
        }
      );

      this.hideModal();
    }
  };

  @computed
  get ListHeadButtons() {
    if (!this.selected.length) return () => <Fragment />;

    return observer(() => (
      <>
        {this.isModalShown && (
          <WorklogAssignModal
            onClose={this.hideModal}
            onSubmit={this.onChangeOrderSubmit}
            fetchOrders={this.getOrdersAutocomplete}
          />
        )}

        <Button variant="outlined" color="secondary" onClick={this.showModal}>
          Сменить заказ ({this.selected.length})
        </Button>
      </>
    ));
  }

  rememberItems = () => {
    localStorage.setItem("worklogsPerPage", this.items.toString());
  };

  applyFilters = () => {
    this.page = 0;
    this.fetchItems();
  };

  @action
  onMount = () => {

    try {
      this.items = parseInt(
        localStorage.getItem("worklogsPerPage") || "50",
        10
      );
    } catch (e) {}

    this.getFiltersFromHash();
    this.setFiltersWindowHash();

    reaction(() => this.filters, this.setFiltersWindowHash);
    reaction(() => this.items, this.rememberItems);
    reaction(() => [this.items, this.sortBy, this.sortDir], this.applyFilters);
    reaction(() => this.page, this.fetchItems)
    reaction(
      () => [this.items, this.filters, this.page, this.sortBy, this.sortDir],
      this.setFiltersWindowHash
    );

    this.fetchItems();
  };
}
