import React, { useMemo, useCallback, useState, useEffect, useRef } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { IEntityFieldProps } from "icerockdev-admin-toolkit";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: 0,
      marginLeft: 10,
      width: 320,

      "& > * + *": {
        marginTop: 0,
      },

      "& .MuiInput-formControl": {
        marginTop: 0,
      },

      "& .MuiInputLabel-shrink": {
        transform: "translate(2px, -10px) scale(0.75)",
      },

      "& .MuiAutocomplete-tag": {
        height: 26,
        borderRadius: 4,
      },

      '& .MuiInput-underline:before': {
        display: 'none'
      }
    },
  })
);

export const JiraAutocomplete = ({
  value,
  label,
  name,
  error,
  entity,
  isFiltering,
  onClick,
  isEditing,
  handler,
  fields,
}: IEntityFieldProps) => {
  const classes = useStyles();

  const exclude = useMemo<String[]>(() => {
    if (!isFiltering) return [];

    const oppositeName =
      name === "jiraCategory" ? "excludeJiraCategory" : "jiraCategory";

    const oppositeFilter = entity?.filters.find(
      (filter) => filter.name === oppositeName && filter.value
    );

    return (
      (oppositeFilter &&
        oppositeFilter.value &&
        oppositeFilter.value.split(",")) ||
      []
    );
  }, [name, entity, entity?.filters, isFiltering]);

  const variants = useMemo<{ id: string; title: string }[]>(() => {
    const jiraCategory = fields.find((field) => field.name === "jiraCategory");

    const entries: Record<string, string> =
      (jiraCategory && jiraCategory.options?.referenceData) || {};

    return (
      Object.entries(entries)
        .filter(([id]) => !exclude.includes(id))
        .map(([id, title]) => ({ id, title })) || []
    );
  }, [fields, exclude]);

  const options = useMemo<Record<string, string>>(() => {
    const jiraCategory = fields.find(
      (field) => field.name === "jiraCategory"
    );

    return (jiraCategory && jiraCategory.options?.referenceData) || {};
  }, [fields]);

  const selected = useMemo<Record<string, string>[]>(() => {
    const current = (value && value.split(",").filter((item) => item)) || [];
    return variants.filter((item) => current.includes(item.id));
  }, [value, variants]);

  const onMultipleChange = useCallback(
    (event, values) => {
      if (!handler) return;
      handler(values.map((item) => item.id).join(","));
    },
    [handler]
  );

  const onChange = useCallback(
    (event) => {
      if (!handler) return;

      handler(event.target.value);
    },
    [value, handler]
  );

  const ref = useRef<HTMLLabelElement>(null);
  const [labelWidth, setLabelWidth] = useState(0);

  useEffect(() => {
    setLabelWidth((ref.current && ref.current.clientWidth) || 0);
  }, [ref.current]);

  if (isFiltering) {
    return (
      <div className={classes.root}>
        <Autocomplete
          multiple
          disableClearable
          value={selected}
          getOptionLabel={(option) => option.title}
          options={variants}
          onChange={onMultipleChange}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label={label || name}
              placeholder="Поиск"
            />
          )}
        />
      </div>
    );
  }

  return isEditing ? (
    <FormControl variant="outlined">
      <InputLabel htmlFor={label} ref={ref}>
        {label}
      </InputLabel>

      <Select
        variant="outlined"
        id={label}
        name={label}
        label={label}
        value={value || ""}
        onChange={onChange}
        error={!!error}
        inputProps={{ className: "select" }}
        labelWidth={labelWidth}
        style={{ minWidth: labelWidth + 40 }}
      >
        <MenuItem value="">...</MenuItem>

        {!!options &&
        !!Object.keys(options).length &&
        Object.keys(options).filter(item => item && options[item]).map((item) => (
          <MenuItem key={item} value={item}>
            {options[item]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  ) : (
    <div onClick={onClick}>
      {(options && value && options[value]) || <div>&nbsp;</div>}
    </div>
  );

};
