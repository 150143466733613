import React, { FC, ReactNode } from 'react';
import { IconButton, TableCell, TableRow, makeStyles } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { rubFormatter } from '../../highlightsTab';
import { safeDivide } from '~/utils/helpers';

type LevelType = 'dark' | 'average' | 'light' | 'none';

const useStyles = makeStyles({
  tableRow: ({ level, color }: { color: string; level: LevelType }) => ({
    background: color,
    '& .MuiTableCell-root': {
      padding: '16px 8px',
    },
    '& .titleCell': {
      paddingLeft:
        level === 'average'
          ? '10px'
          : level === 'light'
          ? '20px'
          : level === 'none'
          ? '50px !important'
          : 'unset',
    },
  }),
});

type Props = {
  rowTitle: string;
  sumRevenueRub: number;
  sumSoldHours: number;
  sumTrackingHours: number;
  developmentExpenses: number;
  children?: ReactNode;
  sumPriceRub: number;
  level: LevelType;
};

const CallapsedRow: FC<Props> = ({
  rowTitle,
  sumRevenueRub,
  sumSoldHours,
  sumTrackingHours,
  developmentExpenses,
  children,
  sumPriceRub,
  level,
}) => {
  const [isRowsCallpsed, setIsRowsCallpsed] = React.useState(false);
  const color =
    level === 'dark' ? '#ccc' : level === 'average' ? '#ddd' : level === 'light' ? '#eee' : '#fff';
  const classes = useStyles({ color, level });

  const projectProfit: number = sumRevenueRub - sumPriceRub;
  const projectProfitability: number = safeDivide(projectProfit, sumRevenueRub);
  const grossProductionMargin: number = projectProfit - developmentExpenses;
  const grossProductionMarginProfitability: number = safeDivide(grossProductionMargin, sumRevenueRub);

  return (
    <>
      <TableRow className={classes.tableRow}>
        <TableCell className="titleCell" component="th" scope="row">
          {children && (
            <IconButton onClick={() => setIsRowsCallpsed(!isRowsCallpsed)}>
              {isRowsCallpsed ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
          {rowTitle}
        </TableCell>
        <TableCell align="right">{rubFormatter.format(sumRevenueRub)}</TableCell>
        <TableCell align="right">{rubFormatter.format(sumPriceRub)}</TableCell>
        <TableCell align="right">{rubFormatter.format(projectProfit)}</TableCell>
        <TableCell align="right">{(projectProfitability * 100).toFixed(2)}%</TableCell>
        <TableCell align="right">{sumSoldHours.toFixed(2)}</TableCell>
        <TableCell align="right">{sumTrackingHours.toFixed(2)}</TableCell>
        <TableCell align="right">{(sumTrackingHours - sumSoldHours).toFixed(2)}</TableCell>
        <TableCell align="right">{rubFormatter.format(developmentExpenses)}</TableCell>
        <TableCell align="right">{rubFormatter.format(grossProductionMargin)}</TableCell>
        <TableCell align="right">{(grossProductionMarginProfitability * 100).toFixed(2)}%</TableCell>
      </TableRow>
      {isRowsCallpsed && children}
    </>
  );
};

export default CallapsedRow;
