import React, { FC } from "react";
import { IEntityFieldProps } from "icerockdev-admin-toolkit";
import styles from "./styles";
import { WithStyles, withStyles } from "@material-ui/core";

type IProps = IEntityFieldProps & WithStyles<typeof styles> & {};

const WorklogCommentUnconnected: FC<IProps> = ({ data, value, classes }) => {
  return (
    <div className={classes.trim}>
      {data?.versions
        .split(",")
        .filter((ver) => ver)
        .map((ver) => (
          <span key={ver} className={classes.version}>
            {ver}
          </span>
        ))}
      {value}
    </div>
  );
};

const WorklogComment = withStyles(styles)(WorklogCommentUnconnected) as FC<
  IProps
>;

export { WorklogComment };
