export default {
  buttons: {
    padding: '0 16px 16px 16px',
  },
  tabWithError: {
    '&.MuiTab-textColorPrimary': {
      color: 'red',
    },
  },
  tab: {},
};
