import { Theme } from "@material-ui/core";

const height = "calc(100vh - 185px)";

export default (theme: Theme) => ({
  wrap: {
    padding: 20,
  },
  buttons: {
    marginTop: 20,
    width: "100%",
    display: "flex" as "flex",
    alignItems: "center",
    justifyContent: "flex-end",

    '& > *': {
      marginLeft: 20,
    }
  },
});
