import React, { FC, useCallback, useMemo } from "react";
import { IOrderActivity } from "~/config/pages/orders/types";
import styles from "./styles";
import { makeStyles } from "@material-ui/core/styles";
import { formatDateTime } from "~/utils/date";
import { EntityField, IEntityField } from "icerockdev-admin-toolkit";
import { observer } from "mobx-react";
import { OrderEntity } from "~/config/pages/orders/OrderEntity";

interface IProps {
  activity: IOrderActivity;
  data: Record<string, string>;
  entity: OrderEntity;
}

type IOrderChange = {
  property: string;
  from: any;
  to: any;
  fromValue: any;
  toValue: any;
};

const useStyles = makeStyles(styles);

const OrderViewHistoryRow: FC<IProps> = observer(
  ({ activity, data, entity }) => {
    const classes = useStyles();

    const fields = useMemo(
      () =>
        Object.values(entity.fields).reduce(
          (obj, val) => ({ ...obj, [val.name]: val }),
          {}
        ),
      [entity.fields]
    );

    const renderValue = useCallback(
      (field: IEntityField, value: any) => {
        const replacedData = { ...data, [field.name]: value };

        return (
          <EntityField
            name={field.name}
            fields={entity.fields}
            value={value}
            data={replacedData}
            entity={entity}
          />
        );
      },
      [entity.fields, data, entity]
    );

    const changes = useMemo<IOrderChange[]>(() => {
      const matches = activity.changes.matchAll(
        /([A-Za-z]+)=\[\(([^,]+),\s([^)]+)\)]/gim
      );

      if (!matches) return [];

      return [...matches].map((match) => {
        const field = fields[match[1]];
        const from = field ? renderValue(field, match[2]) : match[2];
        const to = field ? renderValue(field, match[3]) : match[3];

        return {
          property: field ? field.label : match[1],
          from,
          to,
          fromValue: match[2],
          toValue: match[3],
        };
      });
    }, [activity, fields, renderValue]);

    return (
      <tr>
        <td>{activity.user}</td>

        {changes.length ? (
          <td>
            <div className={classes.changes}>
              {changes.map((change) => (
                <div className={classes.change} id={change.property}>
                  <b>{change.property}:</b>
                  <div>{change.from}</div>
                  <div>({change.fromValue})</div>
                  <div>{`-->`}</div>
                  <div>{change.to}</div>
                  <div>({change.toValue})</div>
                </div>
              ))}
            </div>
          </td>
        ) : (
          <td>{activity.changes}</td>
        )}

        <td>{formatDateTime(activity.dateTime)}</td>
      </tr>
    );
  }
);

export { OrderViewHistoryRow };
