import React, { useState, useCallback, useRef, useEffect } from 'react';
import format from 'date-fns/format';
import { withStyles, WithStyles, TextField, Button, Input } from '@material-ui/core';
import styles from './styles';
import { observer } from 'mobx-react';
import { formatDateTime } from '~/utils/date';

interface IProps extends WithStyles<typeof styles> {
  data: Record<string, any>;
  postComment: (orderId: number, text: string) => Promise<Record<string, string>>;
  fetchComments: ({}: { id: number; skip: number }) => Promise<Record<string, string>[]>;
}

const OrderViewComments = withStyles(styles)(
  observer(({ data, classes, postComment, fetchComments }: IProps) => {
    const list = useRef<HTMLDivElement>(null);
    const [text, setText] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [comments, setComments] = useState<Record<string, string>[]>([]);

    const onTextChange = useCallback((event) => setText(event.target.value), [setText]);

    const onSubmit = useCallback(
      async (event) => {
        event.preventDefault();

        if (!text.trim()) return;

        setIsLoading(true);
        const result = await postComment(data.id, text);
        setText('');

        if (result && result.id) {
          setComments([...comments, result]);
        }

        setIsLoading(false);

        if (list.current) {
          list.current?.scrollTo(0, list.current?.scrollHeight || 0);
        }
      },
      [data, text, setText, postComment, setIsLoading, comments, list.current]
    );

    const loadMoreComments = useCallback(async () => {
      if (!data.id || !list.current) return;

      const result = await fetchComments({
        id: data.id,
        skip: comments.length,
      });

      setComments([...result.reverse(), ...comments]);

      if (!comments.length) {
        list.current.scrollTo(0, list.current.scrollHeight);
      }
    }, [fetchComments, data.id, list.current, comments]);

    useEffect(() => {
      loadMoreComments();
    }, [data.id]);

    const onScroll = useCallback(
      (event) => {
        if (!event.target) return;

        const { scrollTop } = event.target;

        if (scrollTop < 100) {
          loadMoreComments();
        }
      },
      [loadMoreComments]
    );

    return (
      <div className={classes.wrap}>
        <div className={classes.list_wrap}>
          <div className={classes.list} ref={list} onScroll={onScroll}>
            {comments.map((comment: Record<string, any>) => (
              <div className={classes.comment} key={comment.id}>
                <div className={classes.comment_meta}>
                  <b>{comment.author}</b>
                  <span className={classes.comment_date}>{formatDateTime(comment.dateTime)}</span>
                </div>

                {comment.text}
              </div>
            ))}
          </div>
        </div>

        <div className={classes.form}>
          <form onSubmit={onSubmit}>
            <Input
              disabled={isLoading}
              fullWidth
              value={text}
              onChange={onTextChange}
              placeholder="Добавить комментарий"
              autoFocus
              endAdornment={
                <Button type="submit" variant="contained" color="primary">
                  Добавить
                </Button>
              }
            />
          </form>
        </div>
      </div>
    );
  })
);

export { OrderViewComments };
