let format = Intl.NumberFormat(undefined, {
  maximumFractionDigits: 2
});

export function formatHours(hours: number): string {
  return hours.toFixed(2);
}

export function formatAmount(sum: number): string {
  return format.format(sum);
}
