import { Theme } from "@material-ui/core";

export default (theme: Theme) => ({
  status: {
    borderRadius: 3,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 32,
    position: "relative" as "relative",
  },
  color: {
    position: "absolute" as "absolute",
    borderRadius: 3,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 'normal' as 'normal',
    fontSize: '11px',
    lineHeight: '14px',
  },
  empty: {
    boxShadow: '#9BA0A7 0 0 0 1px'
  }
});
