export default {
  grid: {

  },

  group: {
    padding: 16,
  },

  groupTitle: {
    textTransform: 'uppercase' as 'uppercase',
    backgroundColor: '#e1e8ee',
    fontWeight: 'bold' as 'bold',
    borderRadius: '3px 3px 0 0',
    padding: '5px 10px',
  },

  groupFields: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(500px, 1fr))',
    boxShadow: 'inset #e1e8ee 0 0 0 1px',
    borderRadius: '0 0 3px 3px',
  },

  field: {
    color: '#282f36',
    fontSize: '17px',
    fontWeight: 400,
    padding: 16,
    boxShadow: '#e1e8ee 1px -1px',

    '& > .label': {
      display: 'block',
      color: '#a5b0bf',
      fontSize: '14px',
    },

    '& .MuiFormControl-root': {
      width: '100%',
    },

    '& > .field': {
      marginTop: 1,
      display: 'block',
      padding: 10,
    },

  },
}