import React, { FC } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { IEntityField } from 'icerockdev-admin-toolkit';
import styles from './styles';
import { withStyles, WithStyles } from '@material-ui/core';
import { OrderExcessField } from '../OrderExscessField';

type IProps = {
  data: Record<string, any>;
  fields: IEntityField[];
} & Record<any, any>;

const ROWS = {
  '': 'всего',
  Ios: 'iOS',
  Android: 'Android',
  MPP: 'MPP',
  Web: 'Web',
  Design: 'Design',
  Analytics: 'Analytics',
  QA: 'QA',
  PM: 'PM',
  Support: 'Support',
  DevOps: 'DevOps',
  Preprod: 'Preprod'
};

function getHoursNumber(input?: any): number {
    if (input && (typeof input == "string")) {
      input = parseFloat(input)
    }
    return input && parseFloat(input.toFixed(2))
}

function getHoursComplexNumber(data: Record<string, any>, rowName: string, InnerName: string): number {
    return data[rowName] && getHoursNumber(data[rowName][InnerName])
}


const OrderViewStatistics = withStyles(styles)(({ classes, data }: IProps) => {
  return (
    <div className={classes.wrap}>
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Разрез</TableCell>
              <TableCell>Комм. оценка</TableCell>
              <TableCell>Прод. Оценка</TableCell>
              <TableCell>Потрачено нами</TableCell>
              <TableCell>Потрачено удалёнщиками</TableCell>
              <TableCell>Потрачено всего</TableCell>
              <TableCell>Вылет из комм. оценки</TableCell>
              <TableCell>Вылет из прод. оценки</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(ROWS)
              .map((row) => {

                const sold = getHoursNumber(data[`soldHours${row}`])
                const prod = getHoursNumber(data[`prodHours${row}`])
                const excessSold = getHoursNumber(data[`excessSoldHours${row}`])
                const excessProd = getHoursNumber(data[`excessProdHours${row}`])
                const internal = getHoursComplexNumber(data, `spentHours${row}`, `internalSpentHours`)
                const external = getHoursComplexNumber(data, `spentHours${row}`, `externalSpentHours`)
                const total = getHoursComplexNumber(data, `spentHours${row}`, `spentHours`)

                return (
                  <TableRow key={row}>
                    <TableCell component="td" scope="row">
                      <b>Часов {ROWS[row as ''] || ''}</b>
                    </TableCell>
                    <TableCell>{sold}</TableCell>
                    <TableCell>{prod}</TableCell>
                    <TableCell>{internal}</TableCell>
                    <TableCell>{external}</TableCell>
                    <TableCell>{total}</TableCell>
                    <TableCell>
                      <OrderExcessField
                        overtime={excessSold}
                        data={{
                          estimateHours: sold,
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <OrderExcessField
                        overtime={excessProd}
                        data={{
                          estimateHours: prod,
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
});

export { OrderViewStatistics };
