export const uniqueFilter = (value, index, self) => (self.indexOf(value) === index)
export const notNullFilter = (value, index, self) => (value)

export const ordersSort = (a, b) => {
  const aNum = parseInt(a.number.split("-")[1]);
  const bNum = parseInt(b.number.split("-")[1]);

  return bNum - aNum
}

//получаем все типы(TM, FP итд)
export const getOrderTypes = orders => {
  return orders.map(el => el.number.split("-")[0]).filter(uniqueFilter)
}

//группируем по типам
export const getOrdersByTypes = (orders) => {
  const orderTypes = getOrderTypes(orders)
  const orderNumbers = {}

  orderTypes.forEach(orderType => {
      return orderNumbers[orderType] = orders
          .map(el => (el.number.includes(orderType) ? el : null))
          .filter(notNullFilter).sort(ordersSort)
  })

  return orderNumbers
}

