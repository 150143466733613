import React from "react";
import {MoneyFlowPage} from "~/config/pages/moneyFlow/MoneyFlowPage";

export default (host: string) =>
  new MoneyFlowPage({
    title: "ДДС",
    apiBaseUrl: host,
    menu: {
      label: "ДДС",
      url: "/moneyflow",
      enabled: true,
    },
  });
