import { Theme } from "@material-ui/core";

export default (theme: Theme) => ({
  wrap: {
    "& > div": {
      marginTop: "0 !important",
      maxWidth: 250,
    },
  },
});
