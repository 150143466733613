import React, { FC, useCallback, useMemo } from 'react';
import { EntityField, IEntityField } from "icerockdev-admin-toolkit";
import { makeStyles } from "@material-ui/core/styles";
import styles from './styles';
import { IEditorTabGroup } from "~/config/pages/orders/OrderEditor/constants";
import { observer } from "mobx-react";

interface IProps {
  fields: IEntityField[],
  data: Record<string, any>,
  groups: IEditorTabGroup[],
  isEditing?: boolean,
  isCreating?: boolean,
  errors?: Record<string, string>;
  onChange?: (field: string, value: string) => void;
}

const useStyles = makeStyles(styles)

const OrderEditorTab: FC<IProps> = observer(
  ({
    isEditing,
    isCreating,
    fields,
    groups,
    data,
    errors= {},
    onChange,
  }) => {
    const classes = useStyles();

    const handler = useCallback((field: string) => (value: any) => {
      if (!onChange) return;
      onChange(field, value)
    }, [onChange]);

    const fieldsParsed: Record<string, IEntityField> = useMemo(
      () =>
        fields.reduce((obj, field) => {
          if (
            (isCreating && field.hideInCreate) ||
            (!isCreating && isEditing && field.hideInEdit)
          ) return obj

          return { ...obj, [field.name]: field }
        }, {}),
      [fields, isEditing, isCreating]
    );

    return (
      <div className={classes.grid}>
        {
          groups.map(group => <div className={classes.group} key={group.title}>
            <div className={classes.groupTitle}>{group.title}</div>

            <div className={classes.groupFields}>
              {group.fields.map(
                (name: string) =>
                  fieldsParsed[name] && (
                    <div className={classes.field} key={name}>
                      {!isEditing && <div className="label">{fieldsParsed[name].label}</div>}

                      <EntityField
                        name={name}
                        data={data}
                        fields={fields}
                        key={name}
                        isEditing={isEditing}
                        handler={handler(name)}
                        value={data[name]}
                        error={errors[name]}
                      />
                    </div>
                  )
              )}
            </div>
          </div>)
        }
      </div>
    );
  }
)

export { OrderEditorTab };
