import React from "react";
import {
  Entity,
  ENTITY_ERRORS,
  ENTITY_REFERENCE_FIELDS,
  IFilterValue,
  EntityList,
  ENTITY_SORT_DIRS,
} from "icerockdev-admin-toolkit";
import { computed, reaction, action, flow } from "mobx";
import {
  postOrderComment,
  fetchOrderComments,
  fetchOrderHistoryFn,
  updatePriority, archiveOrderItemFn,
} from "../api";
import { observable } from "mobx";
import Axios from "axios";
import {
  Button,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
} from "@material-ui/core";
import { observer } from "mobx-react";
import { OrderView } from "../OrderView";
import { format } from "date-fns/esm";
import { debounce } from "throttle-debounce";
import { parseISO } from "date-fns";
import { OrderExtra } from "../OrderExtra";
import { parseQuery } from "../utils";
import qs from "qs";
import { OrderListHead } from "../OrderListHead";
import { ORDER_SEARCH_DEBOUNCE } from "../constants";
import { createPortal } from "react-dom";
import { OrderEditor } from "~/config/pages/orders/OrderEditor";
import { formatFilterDate } from "~/utils/date";
import { IOrderActivity } from "~/config/pages/orders/types";

type Unwrap<T> = T extends (...args: any[]) => Promise<infer U> ? U : T;

export class OrderEntity extends Entity {
  @observable orderNumber: string = "";
  @observable openedItem: number = 0;

  @action
  setOrderNumber = (val: string) => {
    this.orderNumber = val;
  };

  @observable
  totalHours: {
    soldHours: number;
    prodHours: number;
    spentHours: number;
    internalSpentHours: number;
    externalSpentHours: number;
    excessSoldHours: number;
    excessProdHours: number;
  } = {
    soldHours: 0,
    prodHours: 0,
    spentHours: 0,
    internalSpentHours: 0,
    externalSpentHours: 0,
    excessSoldHours: 0,
    excessProdHours: 0,
  };

  @observable
  exportOrderData = async () => {
    if (!this.fetchItemsFn) return;

    const collectedFilters = this.collectFilters();

    const response = await this.parent?.auth?.withToken(this.fetchItemsFn, {
      url: this.api?.list?.url,
      filter: Object.keys(collectedFilters).map((name) => ({
        name,
        value: collectedFilters[name],
      })),
      page: 0,
      count: 1000,
      sortDir: "DESC",
      sortBy: "id",
    });

    if (!response.data?.list) return;

    response.data.list = response.data.list.map((item) => ({
      ...item,
      spentHours: item.spentHours.spentHours.toFixed(2),
      spentHoursInternal: item.spentHours.internalSpentHours.toFixed(2),
      spentHoursExternal: item.spentHours.externalSpentHours.toFixed(2),
      responsibleUser:
        (item.responsibleUser &&
          this.referenceData.responsibleUser[item.responsibleUser]) ||
        "",
      teamLeadUser:
        (item.teamLeadUser &&
          this.referenceData.teamLeadUser[item.teamLeadUser]) ||
        "",
      salesUser:
        (item.salesUser && this.referenceData.salesUser[item.salesUser]) || "",
      factApproveDate:
        (item.factApproveDate &&
          format(parseISO(item.factApproveDate), "yyyy-MM-dd")) ||
        "",
      estimatedApproveDate:
        (item.estimatedApproveDate &&
          format(parseISO(item.estimatedApproveDate), "yyyy-MM-dd")) ||
        "",
      plannedApproveDate:
        (item.plannedApproveDate &&
          format(parseISO(item.plannedApproveDate), "yyyy-MM-dd")) ||
        "",
      supplementaryAgreementDate:
        (item.supplementaryAgreementDate &&
          format(parseISO(item.supplementaryAgreementDate), "yyyy-MM-dd")) ||
        "",
      excessSoldHours: parseFloat(
        Math.max(
          (item?.spentHours?.spentHours || 0) - (item?.soldHours || 0),
          0
        ).toFixed(2)
      ),
      excessProdHours: parseFloat(
        Math.max(
          (item?.spentHours?.spentHours || 0) - (item?.prodHours || 0),
          0
        ).toFixed(2)
      ),
    }));

    const fields = this.fields
      .filter((field) => !field.hideInExport)
      .map((field) => field.name);

    const rows = [
      fields,
      ...response.data.list.map((item: Record<string, any>) =>
        fields.reduce(
          (obj, field) => [...obj, '"' + String(item[field]) + '"'],
          [] as string[]
        )
      ),
    ];

    let csv =
      "data:text/csv;charset=utf-8," + rows.map((e) => e.join(",")).join("\n");
    var uri = encodeURI(csv);

    saveAs(uri, `${this.title}.csv`);
  };

  @computed
  get ListHeadButtons() {
    return observer(() => (
      <Button
        variant="outlined"
        color="primary"
        onClick={this.exportOrderData}
        style={{ marginRight: 10 }}
      >
        Экспорт
      </Button>
    ));
  }

  @computed
  get ListHeadTitle() {
    return observer(() => (
      <OrderListHead value={this.orderNumber} handler={this.setOrderNumber} />
    ));
  }

  @action
  fetchComments = async ({ id, skip }: { id: any; skip: number }) => {
    if (!this.parent?.auth || !this.api?.get?.url) return;

    const comments = await this.parent?.auth?.withToken(
      ({ token }) =>
        fetchOrderComments({
          url: this.api?.get?.url || "",
          token: token || "",
          orderId: id,
          skip,
        }),
      {}
    );

    return comments;
  };

  fetchHistory = async (orderId: string): Promise<IOrderActivity[]> => {
    const url = this.api?.activity?.url;

    // noinspection TypeScriptValidateTypes
    return this.parent?.auth
      ?.withToken(fetchOrderHistoryFn, {
        url,
        id: orderId,
      })
      .catch(() => []);
  };

  @computed
  get ViewerBody() {
    return observer(({ id }: { id: string }) => (
      <OrderView
        id={id}
        fields={this.fields}
        getItem={this.getItem}
        cancelGetItem={this.getItemsCancel}
        postComment={this.postComment}
        fetchComments={this.fetchComments}
        fetchHistory={this.fetchHistory}
        data={this.editorData}
        entity={this}
      />
    ));
  }

  @computed
  get EditorBody() {
    return observer(({ id }: { id: string }) => (
      <OrderEditor id={id} entity={this} user={this.parent?.auth?.user} isEditing/>
    ));
  }

  @computed
  get CreatorBody() {
    return observer(() => (
      <OrderEditor id="" entity={this} user={this.parent?.auth?.user} isEditing />
    ));
  }

  @action
  updatePriority = async (orderId: number, priority: number | null) => {
    // noinspection TypeScriptValidateTypes
    await this.parent?.auth?.withToken(updatePriority, {
      priority,
      orderId,
      url: this.api?.get.url.replace("/orders", "/order-priority"),
    })

    await this.fetchItems();
  }

  @action
  postComment = async (orderId: number, text: string) => {
    // noinspection TypeScriptValidateTypes
    const result = await this.parent?.auth?.withToken(postOrderComment, {
      text,
      orderId,
      url: this.api?.get.url.replace("/orders", "/order-comments"),
    });

    if (!result?.id) {
      return;
    }

    this.editorData.comments = [...(this.editorData?.comments || []), result];

    return result;
  };

  @action
  fetchTotalHours = () => {
    this.parent?.auth?.withToken(async ({ token }) => {
      try {
        const response = await Axios.get(`${this.api?.get?.url}/sales-hours`, {
          params: this.collectFilters(),
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: "repeat" });
          },
          headers: { authorization: token },
        });

        if (!response?.data) return;

        this.totalHours = response.data;
      } catch (e) {}
    }, {});
  };

  @action
  setData = (data: Entity["data"]) => {
    this.data = data;
  };

  @computed
  get ListModal() {
    const getComments = (orderId: number) => {
      return (
        this.parent?.auth?.withToken(fetchOrderComments, {
          orderId,
          url: this.api?.list.url,
        }) || Promise.resolve([])
      );
    };

    const updateItem = async (data: Record<string, any>) => {
      const result = await this.parent?.auth?.withToken(this.updateItemsFn, {
        url: this.api?.update?.url || "",
        data,
      });

      return result;
    };

    const getItem = async (id: number) => {
      const result = await this.parent?.auth?.withToken(this.getItemsFn, {
        url: this.api?.get?.url || "",
        id,
      });

      return result;
    };

    return observer(
      ({ id, onClose }: { id: any; onClose: (id: any) => void }) => {
        const onExtraClose = () => onClose(id);

        return createPortal(
          <OrderExtra
            data={this.data}
            id={id}
            setData={this.setData}
            postComment={this.postComment}
            getComments={getComments}
            referenceData={this.referenceData}
            fields={this.fields}
            updateItem={updateItem}
            getItem={getItem}
            fetchComments={this.fetchComments}
            onClose={onExtraClose}
          />,
          document.body
        );
      }
    );
  }

  collectFilters = (): Record<string, any> => {
    const filters: Record<string, any> = this.filters
      .filter((filter) => filter.value)
      .reduce((obj, item) => ({ ...obj, [item.name]: item.value }), {});

    const plannedApproveDate =
      filters.plannedApproveDateRange &&
      filters.plannedApproveDateRange.split(",");

    const estimatedApproveDate =
      filters.estimatedApproveDateRange &&
      filters.estimatedApproveDateRange.split(",");

    const factApproveDate =
      filters.factApproveDateRange && filters.factApproveDateRange.split(",");

    return {
      ...filters,
      ...(this.orderNumber ? { number: this.orderNumber } : {}),
      ...(filters.productionStatus
        ? { productionStatus: filters.productionStatus.split(",") }
        : {}),
      ...(filters.saleStatus
        ? { saleStatus: filters.saleStatus.split(",") }
        : {}),
      ...(filters.jiraCategory
        ? { jiraCategory: filters.jiraCategory.split(",") }
        : {}),
      ...(filters.excludeJiraCategory
        ? { excludeJiraCategory: filters.excludeJiraCategory.split(",") }
        : {}),
      ...(plannedApproveDate
        ? {
          plannedApproveDateRange: undefined,
          plannedApproveDateStart: formatFilterDate(plannedApproveDate[0]) || undefined,
          plannedApproveDateEnd: formatFilterDate(plannedApproveDate[1]) || undefined,
        }
        : {}),
      ...(estimatedApproveDate
        ? {
          estimatedApproveDateRange: undefined,
          estimatedApproveDateStart: formatFilterDate(estimatedApproveDate[0]) || undefined,
          estimatedApproveDateEnd: formatFilterDate(estimatedApproveDate[1]) || undefined,
        }
        : {}),
      ...(factApproveDate
        ? {
          factApproveDateRange: undefined,
          factApproveDateStart: formatFilterDate(factApproveDate[0]) || undefined,
          factApproveDateEnd: formatFilterDate(factApproveDate[1]) || undefined,
        }
        : {}),
    };
  };

  @action fetchReferences = async () => {
    // Loading references (if any)
    const references = this.fields
      .filter(
        (field) =>
          field.type &&
          Object.prototype.hasOwnProperty.call(
            ENTITY_REFERENCE_FIELDS,
            field.type
          ) &&
          this.references[field.name]?.getMany
      )
      .map(async (field) => ({
        [field.name]: await this.references[field.name].getMany(this),
      }));

    const refResults = await Promise.all(references);

    this.referenceData = refResults.reduce(
      (obj: Record<string, any>, res: Record<string, any>) => ({
        ...obj,
        ...res,
      }),
      {}
    );

    // updating field reference data
    this.fields = this.fields.map((field) =>
      this.referenceData[field.name]
        ? {
            ...field,
            options: { referenceData: this.referenceData[field.name] },
          }
        : field
    );
  };

  // overriding standart function to add fast-filter for order number
  @action
  fetchItems = () => {
    this.fetchItemsCancel();

    this.fetchItemsInstance = flow(function* (this: OrderEntity) {
      this.isLoading = true;
      this.error = "";
      this.selected = [];

      try {
        // loading entity
        if (!this.api?.list?.url || !this.fetchItemsFn) {
          throw new Error(ENTITY_ERRORS.CANT_LOAD_ITEMS);
        }

        const collectedFilters = this.collectFilters();
        const filter: IFilterValue[] = Object.keys(
          collectedFilters
        ).map((name) => ({ name, value: collectedFilters[name] }));

        // noinspection TypeScriptValidateGenericTypes
        const result: Unwrap<typeof this.fetchItemsFn> = yield this.parent?.auth?.withToken(
          this.fetchItemsFn,
          {
            url: this.api?.list?.url || "",
            filter,
            page: this.page,
            count: this.items,
            sortBy: this.sortBy,
            sortDir: this.sortDir,
          }
        );

        if (!result || result.error)
          throw new Error(result?.error || ENTITY_ERRORS.CANT_LOAD_ITEMS);

        this.data = result?.data?.list || [];
        this.filterData = result?.filterData || {};
        this.totalCount = result?.data?.totalCount || 0;

        yield this.fetchReferences();

        // finished
        this.isLoading = false;
      } catch (e) {
        this.parent?.notifications.showError(e.message);
        this.isLoading = false;
      }
    }).bind(this)();
  };

  @action
  clearNumberInput = () => {
    const exist = this.filters.find((filter) => filter.name === "number");

    if (!exist && this.orderNumber) {
      this.orderNumber = "";
      return;
    }

    if (exist && exist.value !== this.orderNumber) {
      this.orderNumber = exist.value;
    }
  };

  rememberItems = () => {
    localStorage.setItem("ordersPerPage", this.items.toString());
  };

  applyFilters = () => {
    this.page = 0;
    this.fetchItems();
  };

  @action
  onMount = () => {
    this.filters = [];

    if (Object.keys(this.filters).length || this.orderNumber) {
      this.setFiltersWindowHash();
    }

    try {
      this.items = parseInt(localStorage.getItem("ordersPerPage") || "10", 10);
    } catch (e) {}

    this.getFiltersFromHash();

    reaction(() => this.data, this.fetchTotalHours);
    reaction(
      () => [
        this.items,
        this.orderNumber,
        this.filters,
        this.page,
        this.sortBy,
        this.sortDir,
      ],
      this.setFiltersWindowHash
    );

    reaction(() => [this.items, this.sortBy, this.sortDir], this.applyFilter);
    reaction(() => this.page, this.fetchItems);
    reaction(() => this.items, this.rememberItems);
    reaction(() => [this.items, this.sortBy, this.sortDir], this.applyFilters);
    reaction(
      () => this.orderNumber,
      debounce(ORDER_SEARCH_DEBOUNCE, this.applyFilter)
    );
  };

  @action
  setFiltersWindowHash = () => {
    const filters: Record<string, string> = this.filters
      .filter((filter) => !!filter.value)
      .reduce((obj, filter) => ({ ...obj, [filter.name]: filter.value }), {});

    if (this.orderNumber) {
      filters.number = this.orderNumber;
    }

    const params = new URLSearchParams({
      ...filters,
      _page: this.page.toString(),
      _sortBy: this.sortBy.toString(),
      _sortDir: this.sortDir.toString(),
      _items: this.items.toString(),
    });

    window.location.hash = params.toString();
  };

  @action
  getFiltersFromHash = () => {
    const hash = window.location.hash.slice(1, window.location.hash.length);
    const query = parseQuery(hash);
    const searchParams = new URLSearchParams(hash)

    const filters: IFilterValue[] = this.fields
      .filter(
        (field) => field.filterable && Object.keys(query).includes(field.name)
      )
      .map((field) => ({ value: searchParams.get(field.name), name: field.name }));

    if (query.number) {
      this.orderNumber = query.number;
    }

    if (Object.keys(filters).length) {
      this.setFilters(filters);
    }

    if (query._page && parseInt(query._page)) {
      this.page = parseInt(query._page);
    }

    if (query._sortDir && ["asc", "desc"].includes(query._sortDir)) {
      this.sortDir = query._sortDir === "asc" ? "asc" : "desc";
    }

    if (
      query._sortBy &&
      this.fields.some((field) => field.name === query._sortBy)
    ) {
      this.sortBy = query._sortBy;
    }

    if (query._items && parseInt(query._items)) {
      this.items = parseInt(query._items);
    }

    this.fetchItems();
  };

  @computed
  get ListTotalHours() {
    return observer(() => (
      <TableRow style={{ backgroundColor: "#fafafa" }}>
        <TableCell colSpan={8} />
        <TableCell style={{ textAlign: "right", padding: 4 }}>
          <b>Total:</b>
        </TableCell>

        <TableCell style={{ padding: 4 }}>
          <b>{this.totalHours.soldHours.toFixed(1)}</b>
        </TableCell>

        <TableCell style={{ padding: 4 }}>
          <b>{this.totalHours.prodHours.toFixed(1)}</b>
        </TableCell>


        <TableCell style={{ padding: 4 }}>
          <b>{this.totalHours.spentHours.toFixed(1)}</b>
        </TableCell>

        <TableCell style={{ padding: 4 }}>
          <b>{this.totalHours.internalSpentHours.toFixed(1)}</b>
        </TableCell>

        <TableCell style={{ padding: 4 }}>
          <b>{this.totalHours.externalSpentHours.toFixed(1)}</b>
        </TableCell>
        <TableCell colSpan={4} />
        <TableCell style={{ padding: 4 }}>
          <b>{this.totalHours.excessSoldHours.toFixed(1)}</b>
        </TableCell>
        <TableCell style={{ padding: 4 }}>
          <b>{this.totalHours.excessProdHours.toFixed(1)}</b>
        </TableCell>
        <TableCell colSpan={2} />
      </TableRow>
    ));
  }

  @computed
  get ListTableHeader() {
    const visibleFields = this.fields.filter((field) => !field.hideInList);
    const onClick = (field: string) => () => this.setSort(field);
    const spanFields = ["spentHoursInternal", "spentHoursExternal"];

    return observer(() => (
      <TableHead>
        <TableRow style={{ height: "auto" }}>
          {visibleFields.map((field) => {
            if (spanFields.includes(field.name)) {
              return null;
            }

            if (field.name === "spentHours") {
              return (
                <TableCell
                  colSpan={3}
                  rowSpan={1}
                  style={{
                    padding: 4,
                    borderBottom: "none",
                  }}
                  key={field.name}
                >
                  <b>{field.label}</b>
                </TableCell>
              );
            }

            return field.sortable ? (
              <TableCell key={field.name} rowSpan={2}>
                <TableSortLabel
                  active={this.sortBy === field.name}
                  direction={
                    this.sortBy === field.name
                      ? this.sortDir
                      : ENTITY_SORT_DIRS.DESC
                  }
                  onClick={onClick(field.name)}
                >
                  <b>{field.label || field.name}</b>
                </TableSortLabel>
              </TableCell>
            ) : (
              <TableCell key={field.name} rowSpan={2}>
                <b>{field.label || field.name}</b>
              </TableCell>
            );
          })}

          <TableCell rowSpan={2} />
          <TableCell rowSpan={2} />
        </TableRow>
        <TableRow style={{ height: "auto" }}>
          <TableCell>All</TableCell>
          <TableCell>Int</TableCell>
          <TableCell>Ext</TableCell>
        </TableRow>
      </TableHead>
    ));
  }

  @computed
  get ListBody() {
    const onClose = () => this.setOpenedItem(0);

    return observer(() => (
      <>
        {this.openedItem > 0 && (
          <this.ListModal id={this.openedItem} onClose={onClose} />
        )}

        <EntityList
          fields={this.fields}
          data={this.data}
          extra={this.ListExtra}
          isLoading={this.isLoading}
          url={this.menu.url}
          selected={this.selected}
          sortBy={this.sortBy}
          sortDir={this.sortDir}
          canView={this.viewable}
          canEdit={this.editable && this.canEdit}
          canSelect={this.selectable}
          setSelected={this.setSelected}
          onSortChange={this.setSort}
          withToken={this.parent?.auth?.withToken}
          lastRow={<this.ListTotalHours />}
          onRowClick={this.setOpenedItem}
          tableHead={<this.ListTableHeader />}
          entity={this}
        />
      </>
    ));
  }

  @action
  setOpenedItem = (id: number) => {
    this.openedItem = id;
  };

  @action
  archiveItem = (id: number) => {
    this.parent?.auth?.withToken(archiveOrderItemFn, {
      id: id,
      url: this.api?.delete.url,
    }).then(result => {
      if (result.error != null) {
        this.parent?.notifications.showError(result.error);
      } else {
        this.parent?.history.push(this.menu.url);
      }
    });
  };
}
