import axios from "axios";
import HttpStatusCode from 'http-status-typed';

export const authRequestFn = (host: string) => async (
  login: string,
  password: string
): Promise<{
  user: {
    id?: number | undefined;
    email?: string | undefined;
    username?: string | undefined;
    role?: string | undefined;
    token?: string | undefined;
  };
  tokens: {
    access: string;
    refresh: string;
  };
  error: string;
}> => {
  try {
    const response = await axios
      .post(`${host}/auth/signIn`, { login, password })
      .catch((e) => e.response);

    if (response.status === HttpStatusCode.UNAUTHORIZED) {
      throw new Error("Авторизация не удалась");
    }

    if (!response?.data?.data?.accessToken) {
      throw new Error("Авторизация не удалась");
    }

    return {
      user: {
        username: login,
        token: response.data.data.accessToken
      },
      tokens: {
        access: response.data.data.accessToken,
        refresh: response.data.data.refreshToken,
      },
      error: "",
    };
  } catch (e) {
    return { user: {}, tokens: { access: "", refresh: "" }, error: e.message };
  }
};

import { UNAUTHORIZED } from "icerockdev-admin-toolkit";
export const tokenRefreshFn = (host) => async (refreshToken) => {
  try {
    const result = await axios
      .post(`${host}/auth/refresh`, { refreshToken })
      .catch((e) => e.response);
    if (!result?.data?.data?.accessToken || !result?.data?.data?.refreshToken) {
      throw new Error(UNAUTHORIZED);
    }
    return {
      access: result.data.data.accessToken,
      refresh: result.data.data.refreshToken,
    };
  } catch (e) {
    return {
      access: "",
      refresh: "",
    };
  }
};
