import React, {FC, useMemo} from "react";
import {FormControl, withStyles, WithStyles} from "@material-ui/core";
import styles from "./styles";
import {observer} from "mobx-react";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {OrderEntity} from "~/config/pages/orders/OrderEntity";
import { DEFAULT_PRIORITY_COLOR, DEFAULT_PRIORITY_VALUE } from "../constants";

interface PriorityInfo {
    value: string;
    color: string;
    text: string
}

type IProps = {
    data: Record<any, any>;
    entity: OrderEntity,
    value: string | null,
    options: Array<PriorityInfo>
};

export const OrderPriority: FC<IProps> = withStyles(styles)(
    observer(({ data, classes, entity, value, options }: IProps & WithStyles<typeof styles>) => {

        const handleChange = (event) => {
            const priority = event.target.value == DEFAULT_PRIORITY_VALUE ? null : event.target.value
            entity.updatePriority(data.id, priority)
        };

        const color = useMemo(
            () =>
                getColorPriority(value, options),
            [data]
        );

        return (
                <div className={classes.text}>
                    <FormControl variant="filled" className={classes.formControl}>
                        <Select className={classes.menuItem}
                            id="priority-select"
                            value={value ? value : DEFAULT_PRIORITY_VALUE}
                            onChange={handleChange}
                            onClick={(event) => {
                                event.stopPropagation()
                            }}
                            style={{backgroundColor: color}}
                        >
                            {options && options.map(
                                    (item) => (
                                        <MenuItem
                                            className={classes.menuItem}
                                            value={item.value ? item.value : DEFAULT_PRIORITY_VALUE}
                                            style={{backgroundColor: item.color}}
                                        >{item.text}
                                        </MenuItem>
                                    )
                                )
                            }
                        </Select>
                    </FormControl>
                </div>
            )
    })

) as FC<IProps>;

function getColorPriority(priority, options) {
    let color
    options.forEach(item => {
        if (item.value == priority) color = item.color
    })
    if (!color) {
        color = DEFAULT_PRIORITY_COLOR
    }

    return color
}

