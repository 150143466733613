import React, { FC, useCallback } from "react";
import { TextField, InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";

interface IProps {
  value: string;
  handler: (val: string) => void;
}

const OrderNumberInput: FC<IProps> = ({ value, handler }) => {
  const onChange = useCallback((event) => handler(event.target.value), [
    handler,
  ]);

  const onClear = useCallback(() => handler(""), [handler]);

  return (
    <TextField
      value={value}
      onChange={onChange}
      inputProps={{ style: { padding: "7px 14px 7px", width: 100 } }}
      placeholder="Номер"
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {value ? (
              <CloseIcon onClick={onClear} style={{ cursor: "pointer", opacity: 1 }} />
            ) : (
              <SearchIcon />
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};

export { OrderNumberInput };
