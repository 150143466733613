import React, { FC } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TableCell, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  headcell: {
    padding: '0px 5px',
    minWidth: '130px',
    width: '130px',
    '& .MuiAutocomplete-popper': {
      width: 'fit-content !important',
    },
  },
  autocomplete: {
    border: '1px solid rgb(80, 70, 70, 0.5)',
    '& .MuiInputLabel-shrink': {
      transform: 'scale(1)',
    },
    '& .MuiAutocomplete-endAdornment': {
      transform: 'scale(0.75)',
    },
    '& input': {
      width: '100%',
    },
    '& .MuiInputBase-root': {
      padding: '0px 20px 0px 2px',
    },
    '& .MuiInput-underline:before': {
      border: 'none',
    },
  },
  txtField: {
    padding: '0px',
    '& input': {
      fontSize: '12px',
    },
  },
});

type Props = {
  headTitle: string;
  listData: string[];
  handleChange: (val: string | null) => void;
};

const HeadCell: FC<Props> = ({ listData, headTitle, handleChange }) => {
  const classes = useStyles();
  const uniqueList = [...new Set(listData)].sort((a, b) =>
    a?.toLowerCase().localeCompare(b?.toLowerCase())
  );

  return (
    <TableCell className={classes.headcell}>
      {headTitle}
      <Autocomplete
        className={classes.autocomplete}
        freeSolo
        options={uniqueList}
        getOptionLabel={(option) => option}
        renderInput={(params) => (
          <TextField {...params} label="" variant="standard" className={classes.txtField} />
        )}
        onChange={(val, item) => handleChange(item)}
        disablePortal
      />
    </TableCell>
  );
};

export default HeadCell;
