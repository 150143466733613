import React, { FC, MouseEventHandler } from "react";
import {getHoursNumber} from "~/utils/helpers";

type IProps = {
  label: string;
  value: any;
  error?: string;
  isEditing?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  handler?: (val: any) => void;
} & Record<string, any>;

const OrderSoldHours: FC<IProps> = ({
  value,
  onClick,
}) => {
  return (
    <div onClick={onClick}>
      {!!value && getHoursNumber(value) || '0.00'}
    </div>
  );
};

export { OrderSoldHours };
