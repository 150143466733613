import React, { FC, useMemo } from 'react';
import { IEntityField } from 'icerockdev-admin-toolkit';
import format from 'date-fns/format';
import {
  withStyles,
  WithStyles,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import styles from './styles';
import { parseISO } from 'date-fns/esm/fp';

type IProps = WithStyles<typeof styles> & {
  data: Record<string, any>;
  fields: IEntityField[];
};

const formatDate = (d: string): string =>
  (d && parseISO(d) && format(parseISO(d), 'MM.dd.yyyy')) || '';

const UrlField = (url: string) =>
  useMemo(() => {
    const link =
      url
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(
          /(\b(https?|ftp|file):\/\/([-A-Z0-9+&@#%?=~_|!:,.;]*)([-A-Z0-9+&@#%?\/=~_|!:,.;]*)[-A-Z0-9+&@#\/%=~_|])/gi,
          '<a href="$1" target="blank" rel="nofollow">$1</a>'
        ) || '';

    return <div dangerouslySetInnerHTML={{ __html: link }} />;
  }, []);

const OrderViewReleases = withStyles(styles)(({ data, classes }: IProps) => {
  if (!data.releases || !data.releases.length) {
    return null;
  }

  return (
    <div className={classes.wrap}>
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Project</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Url</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Planned date</TableCell>
              <TableCell>Estimated date</TableCell>
              <TableCell>Release date</TableCell>
              <TableCell>Approve date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Трудозатраты</TableCell>
              <TableCell>Осталось часов</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.releases.map((release: Record<string, any>) => (
              <TableRow key={release.id}>
                <TableCell>{release.projectName}</TableCell>
                <TableCell>{release.name}</TableCell>
                <TableCell>{UrlField(release.url)}</TableCell>
                <TableCell>{release.description}</TableCell>
                <TableCell>{formatDate(release.plannedDate)}</TableCell>
                <TableCell>{formatDate(release.estimateDate)}</TableCell>
                <TableCell>{formatDate(release.releaseDate)}</TableCell>
                <TableCell>{formatDate(release.approveDate)}</TableCell>
                <TableCell>{release.status}</TableCell>
                <TableCell>
                  {parseFloat(release.timeSpentHours.toFixed(2))}/
                  {parseFloat(release.originalEstimateHours.toFixed(2))}
                </TableCell>
                <TableCell>{parseFloat(release.remainingEstimateHours.toFixed(2))}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
});

export { OrderViewReleases };
