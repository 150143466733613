import {ReportsPage} from "./ReportsPage";

export default (host: string) =>
  new ReportsPage({
    title: "Отчеты",
    apiBaseUrl: host,
    menu: {
      label: "Отчеты",
      url: "/reports",
      enabled: true,
    },
  });
