import { WORKLOG_FIELDS } from "./fields";
import { WorklogEntity } from './WorklogEntity'
import { fetchExternalJiraCategories, fetchWorklogItemsFn, fetchWorklogUserReference } from "./api";
import {fetchOrderSlots} from "~/config/pages/orders/api";

const INTERNAL_CATEGORIES = ["IceRock"]

export default (host: string) =>
  new WorklogEntity({
    title: "Трекинг",
    editable: false,
    viewable: false,
    creatable: false,
    exportable: false,
    selectable: true,
    api: {
      list: { url: `${host}/worklog`, method: "get" },
      update: { url: `${host}/worklog`, method: "post" },
      autocomplete: { url: `${host}/orders/autocomplete`, method: "get" }
    },
    menu: {
      label: "Трекинг",
      url: "/worklogs",
      enabled: true,
    },
    references: {
      projectLead: {
        getMany: fetchWorklogUserReference(host, "project-managers"),
      },
      projectCategory: {
        getMany: fetchExternalJiraCategories(host, "jira-categories", INTERNAL_CATEGORIES),
      },
      authorSlot: {
        getMany: fetchOrderSlots(host),
      }
    },
    fields: WORKLOG_FIELDS,
    fetchItemsFn: fetchWorklogItemsFn,
  });
