import React from 'react';
import { action, computed, observable, reaction } from 'mobx';
import { IPageProps, Page, UNAUTHORIZED } from 'icerockdev-admin-toolkit';
import { observer } from 'mobx-react';
import Axios from 'axios';
import { DateRangePicker } from '@material-ui/pickers';
import { Box, CircularProgress, TextField } from '@material-ui/core';
import { DateRange } from '@material-ui/pickers/src/DateRangePicker/RangeTypes';
import { ReportAnalyze } from '~/config/pages/reports/api-types';
import { OrderSlotSelect } from '~/config/pages/orders/OrderSlotSelect';
import { ReportTabs } from '~/config/pages/reports/ReportsPage/reportTabs';
import HttpStatusCode from 'http-status-typed';
import './styles.css'

export interface IReportsPageProps extends IPageProps {
  apiBaseUrl: string;
}

export class ReportsPage extends Page {
  @observable apiBaseUrl: IReportsPageProps['apiBaseUrl'] = '';

  @observable dataState: ReportAnalyze | Error | null | undefined = undefined;
  @observable dateRange: DateRange = [null, null];
  @observable selectedSlotId: number | null = null;
  @observable slots: { id: number; name: string }[] = [];

  constructor(fields?: Partial<IReportsPageProps>) {
    super(fields);

    if (fields) {
      Object.assign(this, fields);
    }
  }

  @action
  onMount = () => {
    reaction(
      () => [this.dateRange, this.selectedSlotId],
      () => {
        let slotId: number | null = this.selectedSlotId;
        let from: Date = this.dateRange[0];
        let to: Date = this.dateRange[1];
        if (from == null) return;
        if (to == null) return;

        this.dataState = null;
        this.getReportData(dateForApi(from), dateForApi(to), slotId)
          .then((data) => {
            // check is this response is actual
            if (
              slotId !== this.selectedSlotId ||
              from !== this.dateRange[0] ||
              to !== this.dateRange[1]
            )
              return;

            this.dataState = data;
          })
          .catch((error) => {
            // check is this response is actual
            if (
              slotId !== this.selectedSlotId ||
              from !== this.dateRange[0] ||
              to !== this.dateRange[1]
            )
              return;

            this.dataState = error;
          });
      }
    );
    this.getSlotsList().then((data) => (this.slots = data));
  };

  @computed
  get output() {
    return observer(() => {
      let body;
      if (this.dataState instanceof Error) {
        let error: Error = this.dataState;
        let data = error['response']?.data;
        let message: string | undefined = data?.message;
        let text: string = message ?? JSON.stringify(data, null, 2);
        body = (
          <div>
            <p>{error.message}</p>
            <p style={{ whiteSpace: 'pre-wrap' }}>{text}</p>
          </div>
        );
      } else if (this.dataState === undefined) {
        body = <div>No data</div>;
      } else if (this.dataState === null) {
        body = (
          <div className='loaderWrapper'>
            <CircularProgress />
          </div>
        );
      } else {
        body = <ReportTabs data={this.dataState} />;
      }

      return (
        <div style={{ padding: 16 }}>
          <div style={{ flexDirection: 'row', display: 'flex' }}>
            <DateRangePicker
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField {...startProps} />
                  <Box style={{ paddingLeft: 8, paddingRight: 8 }}> to </Box>
                  <TextField {...endProps} />
                </React.Fragment>
              )}
              value={this.dateRange}
              onChange={(newValue) => (this.dateRange = newValue)}
            />
            <div style={{ width: 16 }} />
            <OrderSlotSelect
              fields={[]}
              name="slot"
              label="Select slot"
              isEditing={true}
              value={this.selectedSlotId}
              handler={(id) => (this.selectedSlotId = id)}
              options={{
                referenceData: this.slots.reduce((prev, current) => {
                  prev[current.id] = current.name;
                  return prev;
                }, {}),
              }}
            />
          </div>

          {body}
        </div>
      );
    });
  }

  private async getReportData(
    from: string,
    to: string,
    slotId: number | null
  ): Promise<ReportAnalyze> {
    let url = `${this.apiBaseUrl}/report/slot?from=${from}&to=${to}&slotId=${slotId}`;
    return this.parent?.auth?.withToken(async ({ token }) => {
      const response = await Axios.post(url, null, {
        headers: { authorization: token },
      });

      if (response?.status === HttpStatusCode.UNAUTHORIZED)
        return { data: {}, error: UNAUTHORIZED };
      if (!response?.data) return;

      return response.data;
    }, {});
  }

  private async getSlotsList(): Promise<{ id: number; name: string }[]> {
    let url = `${this.apiBaseUrl}/slots`;
    return this.parent?.auth?.withToken(async ({ token }) => {
      const response = await Axios.get(url, {
        headers: { authorization: token },
      });

      if (response?.status === HttpStatusCode.UNAUTHORIZED)
        return { data: {}, error: UNAUTHORIZED };
      if (!response?.data) return;

      return response.data;
    }, {});
  }
}

function dateForApi(date: Date): string {
  return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
}
