import { Theme } from "@material-ui/core";

export default (theme: Theme) => ({
  trim: {
    maxWidth: '25vw',
    fontSize: '1.2em',
    lineHeight: '1.4em',
    fontWeight: 'bold' as 'bold',
    maxHeight: '2.8em',
    overflow: 'hidden',
  },
  version: {
    marginRight: 5,
    fontSize: '0.8em',
    background: theme.palette.primary.main,
    borderRadius: 2,
    padding: 2,
    color: 'white'
  }
});
