import React, { FC, useMemo } from "react";
import { IEntityFieldProps } from "icerockdev-admin-toolkit";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./styles";

type IProps = IEntityFieldProps;

const useStyles = makeStyles(styles)

const OrderProdHours: FC<IProps> = ({
  data,
  value,
  onClick,
}) => {
  const classes = useStyles();

  const [color, backgroundColor] = useMemo<[string, string]>(() => {
    const spentHours = data?.spentHours?.spentHours || 0.0;
    const prodHours = data?.prodHours || 0.0;
    const factApproveDate = data?.factApproveDate || null;

    switch (true) {
      case (spentHours <= 0 && prodHours <= 0):
        return ['inherit', ''];
      case (spentHours > prodHours):
        return ['rgb(111, 29, 29)', 'rgb(248, 213, 213)'];
      case (spentHours >= prodHours * 0.8 && !factApproveDate):
        return ['rgb(148, 77, 30)', 'rgb(248, 230, 211)'];
      case (spentHours <= prodHours && !!factApproveDate):
        return ['rgb(71, 104, 60)', 'rgb(215, 239, 219)'];
      default:
        return ['inherit', '']
    }
  }, [data])

  return (
    <div onClick={onClick}>
      <div className={classes.label} style={{ backgroundColor, color }}>
        {(!!value && !!parseFloat(value) && parseFloat(value).toFixed(2)) || '0.00'}
      </div>
    </div>
  );
};

export { OrderProdHours };
