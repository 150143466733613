import React, { FC } from "react";
import { OrderNumberInput } from "../OrderNumberInput";
import styles from "./styles";
import { withStyles, WithStyles } from "@material-ui/core";

interface IProps extends WithStyles<typeof styles> {
  value: string;
  handler: (val: string) => void;
}

const OrderListHeadUnconnected: FC<IProps> = ({ value, handler, classes }) => {
  return (
    <div className={classes.wrap}>
      <div className={classes.title}>Заказы</div>
      <OrderNumberInput value={value} handler={handler} />
    </div>
  );
};

const OrderListHead = withStyles(styles)(OrderListHeadUnconnected);

export { OrderListHead };
