import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {AuthProvider, IAuthProviderProps, IEntityField, IPageProps} from 'icerockdev-admin-toolkit';
import { OrderEntity } from '~/config/pages/orders/OrderEntity';
import {observer, useObserver} from 'mobx-react';
import { OrderDateReadonly } from '~/config/pages/orders/OrderDateReadonly';
import { ORDER_EDITOR_TABS, SOLD_HOURS_TO_PROD } from '~/config/pages/orders/OrderEditor/constants';
import { Button, Grid, Paper, Tab, Tabs } from '@material-ui/core';
import { OrderEditorTab } from '~/config/pages/orders/OrderEditorTab';
import styles from './styles';
import { makeStyles } from '@material-ui/core/styles';
import omit from 'ramda/es/omit';
import {isOrdersAdmin} from "~/utils/jwtUtils";

interface IProps {
  id: string;
  entity: OrderEntity;
  isEditing: boolean;
  user: IAuthProviderProps['user'] | undefined;
}

const useStyles = makeStyles(styles);

const OrderEditor: FC<IProps> = observer(({ id, entity, isEditing, user }) => {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState(0);
  const [data, setData] = useState<Record<string, any>>({});
  const [errors, setErrors] = useState<Record<string, string>>({});
  const isCreating = useMemo(() => isEditing && !id, [id, isEditing]);
  const onTabChange = useCallback((_, index: number) => setCurrentTab(index), [setCurrentTab]);

  const isAdmin: boolean = user != null && isOrdersAdmin(user);

  const onSave = useCallback(
    (event) => {
      event.preventDefault();
      entity.setEditorData(data);

      if (isCreating) {
        entity.createItem();
      } else {
        entity.updateItem();
      }
    },
    [entity, data, entity.setEditorData]
  );

  const onCancel = useCallback(
    (event) => {
      event.preventDefault();
      entity.onEditCancel();
    },
    [entity]
  );

  // Take only tabs and fields, that can be specified at create or edit
  const availableTabs = useMemo(() => {
    const availableFields: Record<string, IEntityField> = entity.fields.reduce((obj, field) => {
      if ((isCreating && field.hideInCreate) || (!isCreating && isEditing && field.hideInEdit))
        return obj;

      return { ...obj, [field.name]: field };
    }, {});

    return ORDER_EDITOR_TABS.map((tab) => ({
      ...tab,
      groups: tab.groups
        .map((group) => ({
          ...group,
          fields: group.fields.filter((field) => availableFields[field]),
        }))
        .filter((group) => group.fields.length),
    })).filter((tab) => tab.groups.length);
  }, [isCreating, isEditing, entity.fields]);

  // Locking approve date in some cases
  const fields = useMemo<IEntityField[]>(() => {
    const fieldsByKey: Record<string, IEntityField> = entity.fields.reduce(
      (obj, field) => ({
        ...obj,
        [field.name]: { ...field },
      }),
      {}
    );

    if (!entity.editorData || entity.isLoading) return entity.fields;

    if (entity.editorData.plannedApproveDate && fieldsByKey.plannedApproveDate && !isAdmin) {
      fieldsByKey.plannedApproveDate.type = 'custom';
      fieldsByKey.plannedApproveDate.component = OrderDateReadonly;
    }

    if (entity.editorData.factApproveDate && fieldsByKey.factApproveDate && !isAdmin) {
      fieldsByKey.factApproveDate.type = 'custom';
      fieldsByKey.factApproveDate.component = OrderDateReadonly;
    }

    if (entity.editorData.factApproveDate && fieldsByKey.estimatedApproveDate && !isAdmin) {
      fieldsByKey.estimatedApproveDate.type = 'custom';
      fieldsByKey.estimatedApproveDate.component = OrderDateReadonly;
    }

    return Object.values(fieldsByKey);
  }, [entity.fields, entity.isLoading, isAdmin]);

  const onChange = useCallback(
    (field: string, value: any) => {
      if (errors[field]) setErrors(omit([field])(errors));

      return setData({ ...data, [field]: value });
    },
    [data, setData, errors]
  );

  const onArchive = useCallback(
    () => {
      entity.archiveItem(parseInt(id));
    },
    [entity, id]
  )

  // Sets prod hours equal to sold ones if they're empty when creating order
  const syncFieldsOnBlur = useCallback(() => {
    if (!isCreating) return;

    const newData = Object.entries(data).reduce((obj, [soldField, value]) => {
      const prodField = SOLD_HOURS_TO_PROD[soldField];
      if (!prodField || !!data[prodField]) {
        return obj;
      }

      return { ...obj, [prodField]: value };
    }, {} as Record<string, any>);

    if (!Object.keys(newData).length) return;

    setData({ ...data, ...newData });
  }, [data, setData, isCreating]);

  const tabsWithErrors = useMemo(() => {
    const errorFields = Object.keys(entity.editorFieldErrors);

    return availableTabs
      .filter((tab) =>
        tab.groups.some((group) => group.fields.some((field) => errorFields.includes(field)))
      )
      .map((tab, index) => availableTabs.indexOf(tab));
  }, [availableTabs, errors]);

  // Fetch item on mount
  useEffect(() => {
    if (!id) {
      entity.setEditorData({})
      return;
    }

    entity.getItem(id);
    return entity.getItemsCancel;
  }, [id]);

  // Set initial data
  useEffect(() => {
    setData(entity.editorData);
  }, [entity.editorData]);

  // Sync errors after submit
  useEffect(() => {
    setErrors(entity.editorFieldErrors);
  }, [entity.editorFieldErrors]);

  // Auto-focus on first tab with error
  useEffect(() => {
    if (!tabsWithErrors.length || tabsWithErrors.includes(currentTab)) return;

    setCurrentTab(tabsWithErrors[0]);
  }, [tabsWithErrors]);

  return (
    <form onSubmit={onSave} onBlurCapture={syncFieldsOnBlur}>
      <Paper square>
        <Tabs
          value={currentTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={onTabChange}
        >
          {availableTabs.map((tab, index) => (
            <Tab
              label={tab.title}
              key={tab.name}
              className={tabsWithErrors.includes(index) ? classes.tabWithError : classes.tab}
            />
          ))}
        </Tabs>

        {!entity.isLoading && (
          <OrderEditorTab
            fields={fields}
            data={data}
            groups={Object.values(availableTabs)[currentTab].groups}
            isEditing={isEditing}
            isCreating={isCreating}
            onChange={onChange}
            errors={errors}
          />
        )}

        {isEditing && (
          <div className={classes.buttons}>
            <Grid container spacing={1}>
              <Grid item style={{ flex: 1 }} />

              {isAdmin && !isCreating && (
                <Grid item>
                  <Button type="button" color="secondary" variant="outlined" onClick={onArchive}>
                    Удалить
                  </Button>
                </Grid>
              )}

              <Grid item>
                <Button type="button" color="default" variant="outlined" onClick={onCancel}>
                  Отмена
                </Button>
              </Grid>

              <Grid item>
                <Button type="submit" variant="contained" color="primary">
                  Сохранить
                </Button>
              </Grid>
            </Grid>
          </div>
        )}
      </Paper>
    </form>
  );
});

export { OrderEditor };
