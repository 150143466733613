import { Theme } from '@material-ui/core';

export default (theme: Theme) => ({
  wrap: {
    padding: 16,
  },
  table: {
    border: '1px solid #e1e8ee',
    borderRadius: '3px 3px 3px 3px',

    '& td, & th': {
      padding: theme.spacing(2),
    },

    '& th': {
      backgroundColor: '#e1e8ee',
      fontWeight: 'bold' as 'bold',
    }
  }
});
