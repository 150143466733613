import {
  fetchOrderItemsFn,
  getOrderItemFn,
  updateOrderItemFn,
  createOrderItemFn,
  fetchOrderReference,
  fetchJiraCategories, fetchOrderSlots,
} from "./api";
import { OrderEntity } from "./OrderEntity";
import { ORDER_FIELDS } from "./fields";
import { DEFAULT_ORDERS_COUNT } from "./constants";

export default (host: string) =>
  new OrderEntity({
    title: "Заказы",
    editable: true,
    viewable: true,
    creatable: true,
    exportable: false,
    items: DEFAULT_ORDERS_COUNT,
    api: {
      list: { url: `${host}/orders`, method: "get" },
      get: { url: `${host}/orders`, method: "get" },
      update: { url: `${host}/orders`, method: "put" },
      create: { url: `${host}/orders`, method: "post" },
      delete: { url: `${host}/orders`, method: "delete" },
      activity: { url: `${host}/activity`, method: 'get' }
    },
    menu: {
      label: "Заказы",
      url: "/orders",
      enabled: true,
    },
    references: {
      salesUser: {
        getMany: fetchOrderReference(host, "sales"),
      },
      teamLeadUser: {
        getMany: fetchOrderReference(host, "teamleads"),
      },
      responsibleUser: {
        getMany: fetchOrderReference(host, "project-managers"),
      },
      jiraCategory: {
        getMany: fetchJiraCategories(host, "jira-categories"),
      },
      slotId: {
        getMany: fetchOrderSlots(host),
      },
    },
    fields: ORDER_FIELDS,
    fetchItemsFn: fetchOrderItemsFn,
    getItemsFn: getOrderItemFn,
    updateItemsFn: updateOrderItemFn,
    createItemsFn: createOrderItemFn,
  });
