import React, { FC, useMemo } from "react";
import { IEntityFieldProps, EntityFieldString } from "icerockdev-admin-toolkit";
import styles from "./styles";
import { WithStyles, withStyles } from "@material-ui/core";

type IProps = IEntityFieldProps & WithStyles<typeof styles> & {};

const ISSUE_ICONS = {
  Organizational:
    "https://jira.icerockdev.com/secure/viewavatar?size=xsmall&avatarId=10309&avatarType=issuetype",
  Bug:
    "https://jira.icerockdev.com/secure/viewavatar?size=xsmall&avatarId=10303&avatarType=issuetype",
  Task:
    "https://jira.icerockdev.com/secure/viewavatar?size=xsmall&avatarId=10318&avatarType=issuetype",
  Testing:
    "https://jira.icerockdev.com/secure/viewavatar?size=xsmall&avatarId=10308&avatarType=issuetype",
  Epic: "https://jira.icerockdev.com/images/icons/issuetypes/epic.svg",
  Story: "https://jira.icerockdev.com/images/icons/issuetypes/story.svg",
  Analytics:
    "https://jira.icerockdev.com/secure/viewavatar?size=xsmall&avatarId=10322&avatarType=issuetype",
  Desing:
    "https://jira.icerockdev.com/secure/viewavatar?size=xsmall&avatarId=10312&avatarType=issuetype",
};

const WorklogIssueUnconnected: FC<IProps> = ({
  value,
  classes,
  data,
  isEditing,
  isFiltering,
  fields,
  name,
  handler,
}) => {
  const field = useMemo(() => fields.find((field) => field.name === name), [
    fields,
    name,
  ]);

  const icon = useMemo(
    () =>
      (data?.issueType && ISSUE_ICONS[data.issueType]) ||
      "https://jira.icerockdev.com/secure/viewavatar?size=xsmall&avatarId=10301&avatarType=issuetype",
    [data]
  );

  if (!field || !data) return null;

  if (isFiltering)
    return (
        <EntityFieldString
          value={
            Object.prototype.hasOwnProperty.call(data, field.name)
              ? data[field.name]
              : null
          }
          label="Задача"
          name={name}
          isEditing={isEditing}
          handler={handler}
          fields={fields}
        />
    );

  if (!value) return null;

  return (
    <div>
      <a
        href={`https://jira.icerockdev.com/browse/${value}`}
        target="_blank"
        className={classes.link}
      >
        <img src={icon} />
        {value}
      </a>
    </div>
  );
};

const WorklogIssue = withStyles(styles)(WorklogIssueUnconnected) as FC<IProps>;

export { WorklogIssue };
