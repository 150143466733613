export const HEALTH_STATUSES = [
  { id: "FAIL", name: "Fail", bg: "#F8D5D5", color: "#6F1D1D" },
  { id: "OPT_BAD", name: "Opt Bad", bg: "#F8E6D3", color: "#944D1E" },
  { id: "OPT_GOOD", name: "Opt Good", bg: "#D7EFDB", color: "#47683C" },
  { id: "REAL", name: "Real", bg: "#D9ECF4", color: "#224A5B" },
  { id: "DONE", name: "Done", bg: "#C7E5AE", color: "#2D4C12" },
];

export const PRODUCTION_STATUS = {
  NOT_SOLD: "Не продано",
  TO_PLANNING: "В планирование",
  PLANNING: "Планирование",
  HOLD: "Пауза",
  WAIT_START: "Ждем освобождения людей",
  IN_PROGRESS: "В разработке",
  ACCEPTANCE: "Утверждение",
  DONE: "Утверждено",
};

export const SALES_STATUS = {
  WAIT_ESTIMATION: "Ждет оценки",
  WAIT_SALE: "Ждет продажи",
  WAIT_SIGN_SA: "Ожидает подписания оригинала ДС",
  SOLD: "Продано",
  WAIT_SIGN_ACT: "Ждет подписания акта",
  ACT_SIGNED: "Акт подписан",
  CANCELED: "Отменено",
  NEVER_SOLD: "Не будет продано",
};

export const DEFAULT_PRIORITY_COLOR = "white"
export const DEFAULT_PRIORITY_VALUE = "NOT_SET"

export const PRIORITY_OPTIONS = [
  {
    value: DEFAULT_PRIORITY_VALUE, color: "white", text: "Не указан"
  },
  {
    value: "LOW", color: "green", text: "Низкий"
  },
  {
    value: "MEDIUM", color: "yellow", text: "Средний"
  },
  {
    value: "HIGH", color: "red", text: "Высокий"
  }
]

export const DEFAULT_ORDERS_COUNT = 10;
export const ORDER_SEARCH_DEBOUNCE = 500;
