import {Config} from "icerockdev-admin-toolkit";
import auth from "./auth";
import orders from "./pages/orders";
import theme from "./theme";
import logo from "./assets/icerock.png";
import orderNumbers from "./pages/orderNumbers";
import worklog from "./pages/worklog";
import reports from "~/config/pages/reports";
import moneyFlow from "~/config/pages/moneyFlow";

export const BASE_URL =
  process.env.REACT_APP_BASE_URL || "http://localhost:8080/api";

export default new Config({
  auth: auth(BASE_URL),
  pages: [
    orders(BASE_URL),
    orderNumbers(BASE_URL),
    worklog(BASE_URL),
    reports(BASE_URL),
    moneyFlow(BASE_URL)
  ],
  theme,
  logo,
});
