import jwtDecode, {JwtPayload} from "jwt-decode";
import {IAuthProviderProps} from "icerockdev-admin-toolkit";

interface UserData {
  jiraUserKey: string;
  scopes: string[];
}

interface UserJwtPayload extends JwtPayload {
  user: string;
}

function readUserData(user: IAuthProviderProps['user']): UserData | null {
  let token: string | null = user?.token ?? null;
  if (token == null) return null;

  return readUserDataFromToken(token);
}

function readUserDataFromToken(token: string): UserData {
  let decoded: UserJwtPayload = jwtDecode<UserJwtPayload>(token);
  return JSON.parse(decoded.user);
}

function haveScope(user: IAuthProviderProps['user'], scope: string): boolean {
  let userData: UserData | null = readUserData(user);
  let index: number | undefined = userData?.scopes?.findIndex(value => value == scope)
  return index != undefined
}

function isOrdersAdmin(user: IAuthProviderProps['user']): boolean {
  return haveScope(user, "orders-admin");
}

function isTrackingAdmin(user: IAuthProviderProps['user']): boolean {
  return haveScope(user, "tracking-admin");
}

export {isOrdersAdmin, isTrackingAdmin};
