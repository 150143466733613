import React, {useCallback, useEffect, useState} from "react";
import {Config} from "icerockdev-admin-toolkit";
import {observer} from "mobx-react";
import {CircularProgress} from "@material-ui/core";
import {MoneyFlowAmount, MoneyFlowDetails} from "~/config/pages/moneyFlow/api-types";
import {IReactComponent} from "mobx-react/dist/types/IReactComponent";
import {makeStyles} from "@material-ui/core/styles";
import styles from "~/config/pages/orders/OrderEditorTab/styles";
import {ClassNameMap} from "@material-ui/core/styles/withStyles";
import {SubmitableTextField} from "~/components/SubmitableTextField";
import {getDetails, setDetails} from "~/config/pages/moneyFlow/api";

interface MoneyFlowDetailsProps {
  apiBaseUrl: string,
  refKey: string,
  config: Config
}

type MoneyFlowDetailsDataState = Error | MoneyFlowDetails | null

const useStyles = makeStyles(styles)

export const MoneyFlowDetailsView: IReactComponent<MoneyFlowDetailsProps> = observer(
  (
    props: MoneyFlowDetailsProps
  ): JSX.Element => {
    const styles = useStyles();

    const [
      dataState,
      setDataState
    ] = useState<MoneyFlowDetailsDataState>(() => null);

    useEffect(() => {
      setDataState(null);
      getDetails(props.apiBaseUrl, props.config, props.refKey)
        .then(setDataState)
        .catch(setDataState);
    }, [setDataState]);

    const changeComment = useCallback((newValue) => {
      setDetails(props.apiBaseUrl, props.config, props.refKey, newValue)
        .then(() => getDetails(props.apiBaseUrl, props.config, props.refKey))
        .then(setDataState)
        .catch(setDataState);
    }, [setDataState]);

    if (dataState instanceof Error) {
      return errorState(dataState);
    } else if (dataState == null) {
      return loadingState();
    } else {
      return successfulState(
        dataState as MoneyFlowDetails,
        styles,
        changeComment
      );
    }
  }
);

function errorState(error: Error): JSX.Element {
  let errorString = JSON.stringify(error["response"]?.data, null, 2);
  return (
    <div style={{padding: 16}}>
      <p>{error.message}</p>
      <p style={{whiteSpace: "pre-wrap"}}>{errorString}</p>
    </div>
  );
}

function loadingState(): JSX.Element {
  return (<div style={
    {
      width: "100vw",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }
  }>
    <CircularProgress/>
  </div>);
}

type EditableValue = {
  value: string;
  onSubmit: (string) => void;
}

type Field = {
  title: string;
  value: string | EditableValue | null;
}

type FieldsGroup = {
  title: string;
  fields: Field[]
}

function successfulState(
  data: MoneyFlowDetails,
  classes: ClassNameMap<string>,
  changeComment: (string) => void
): JSX.Element {
  let pageData: FieldsGroup[] = [
    {
      title: "Общее",
      fields: [
        {title: "Контрагент", value: data.contractor},
        {title: "Организация", value: data.organization},
        {title: "Статус", value: data.status},
        {title: "Комментарий бухгалтерии", value: data.financeComment},
        {
          title: "Комментарий сейлза", value: {
            value: data.salesComment ?? '',
            onSubmit: changeComment
          }
        },
      ]
    },
    {
      title: "План",
      fields: [
        {title: "Сумма в валюте", value: getCurrencyAmount(data.plannedAmount)},
        {title: "Сумма в рублях", value: getRubAmount(data.plannedAmount)},
        {title: "Изначальная дата", value: data.plannedDate},
        {title: "Ожидаемая дата", value: data.estimatedDate},
      ]
    },
    {
      title: "Факт",
      fields: [
        {title: "Сумма в валюте", value: getCurrencyAmount(data.factAmount)},
        {title: "Сумма в рублях", value: getRubAmount(data.factAmount)},
        {title: "Дата", value: data.factDate},
      ]
    },
    {
      title: "Заказ",
      fields: [
        {title: "Номер", value: data.order?.number ?? null},
        {title: "Описание", value: data.order?.description ?? null},
        {title: "Проект", value: data.order?.jiraCategory ?? null},
        {title: "Дата по ДС", value: data.order?.supplementaryAgreementDate ?? null},
        {title: "Статус производства", value: data.order?.productionStatus ?? null},
        {title: "Статус продаж", value: data.order?.saleStatus ?? null},
        {title: "Здоровье", value: data.order?.healthStatus ?? null},
        {title: "Приоритет", value: data.order?.priority ?? null},
      ]
    }
  ]

  let groups: JSX.Element[] = pageData.map((group: FieldsGroup): JSX.Element => {
    let fields: JSX.Element[] = group.fields.map((field: Field): JSX.Element => {
      if (field.value != null && typeof field.value === 'object') {
        let value: EditableValue = field.value;
        return (
          <SubmitableTextField
            className={classes.field}
            key={field.title}
            title={field.title}
            initialValue={value.value}
            onSubmit={value.onSubmit}
          />
        )
      } else {
        let value: string = field.value ?? '&nbsp;';
        return (
          <div className={classes.field} key={field.title}>
            <div className="label">{field.title}</div>

            <div dangerouslySetInnerHTML={{__html: value}}/>
          </div>
        )
      }

    });

    return (
      <div className={classes.group} key={group.title}>
        <div className={classes.groupTitle}>{group.title}</div>

        <div className={classes.groupFields}>
          {fields}
        </div>
      </div>
    )
  });

  return (
    <div className={classes.grid} style={{padding: 16}}>
      {groups}
    </div>
  );
}

let format = Intl.NumberFormat(undefined, {
  maximumFractionDigits: 2
});

function getCurrencyAmount(amount: MoneyFlowAmount | null): string | null {
  if (amount == null) return null;

  return format.format(amount.amountInCurrency) + amount.currency;
}

function getRubAmount(amount: MoneyFlowAmount | null): string | null {
  if (amount == null) return null;

  return format.format(amount.amountInRub) + "₽";
}
