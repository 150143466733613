import { Theme } from "@material-ui/core";

export default (theme: Theme) => ({
  wrap: {
    flex: 1,
    display: "flex" as "flex",

    "& .MuiInputBase-adornedEnd": {
      border: "1px solid #eeeeee",
      height: 50,
      boxSizing: "border-box",
      backgroundColor: "white",

      "& svg": {
        opacity: 0.4,
      },
    },

    "@media(max-width: 640px)": {
      width: "100%",
      paddingRight: 10,
    },
  },
  title: {
    flex: 1,
    fontWeight: "bold" as "bold",
    fontSize: "34px",
    lineHeight: "40px",
    paddingRight: 24,
  },
});
