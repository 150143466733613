import { Theme } from "@material-ui/core";

export default (theme: Theme) => ({
  modal: {
    position: "fixed" as "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center" as "center",
    justifyContent: "center" as "center",
    zIndex: 1101,
    overflow: "auto",
    padding: "10px 80px",
    boxSizing: "border-box" as "border-box",

    "@media(max-width: 768px)": {
      padding: 10,
    },
  },
  content: {
    background: "white",
    width: "100%",
    borderRadius: 4,
    position: "relative" as "relative",
    margin: "auto",

    "& h2": {
      margin: 0,
      marginBottom: 32,
      fontSize: "34px",
      lineHeight: "40px",
    },
  },
  wrap: {
    display: "flex",
    flexDirection: "row" as "row",
    alignItems: "stretch",
    width: "100%",

    "@media(max-width: 768px)": {
      flexDirection: "column" as "column",
    },
  },
  comments: {
    flex: 1,
    borderRadius: 3,
    background: "#f9f9f9",
  },
  editor: {
    flex: 1,
    padding: 20,
    boxSizing: "border-box" as "border-box",
    position: 'relative' as 'relative',
  },
  fields: {
    display: "grid",
    gridTemplateColumns: `repeat(auto-fill, minmax(300px, 1fr))`,
    gridTemplateRows: "auto",
    gridRowGap: 16,
    gridColumnGap: 16,

    "& > div": {
      marginBottom: 10,
      width: "100%",
    },

    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  loader: {
    height: 300,
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  save_button: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",

    "& > button": {
      boxShadow: "none",
      width: "100%",
      height: 56,
    },
  },
  error: {
    color: "red",
    wordBreak: "break-word" as "break-word",
    flex: 1,
    fontSize: "0.8em",
  },
  success: {
    color: "darkgreen",
  },
  status: {
    position: 'absolute' as 'absolute',
    bottom: 5,
    right: 25,
  },
  locked: {
    opacity: 0.8,
    pointerEvents: "none" as "none",
    touchAction: "none" as "none",
  },
  close: {
    cursor: "pointer",
    position: "absolute" as "absolute",
    backgroundColor: "white",
    top: 0,
    right: -50,
    width: 40,
    height: 40,
    borderRadius: 4,
    display: "flex",
    alignItems: "center" as "center",
    justifyContent: "center" as "center",

    "@media(max-width: 768px)": {
      position: 'fixed',
      top: 0,
      right: 0,
      background: 'white',
      zIndex: 2,
      width: 32,
      height: 32,
    },
  },
});
