import React, { FC, useState } from 'react';
import {OrdersEntity, SlotDevExpenses} from '../../../api-types';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles, Divider,
} from '@material-ui/core';
import { groupProjectOrdersData, groupSlotOrdersData } from '~/utils/helpers';
import ProfitabilitySlotGroupRows from './ProfitabilitySlotGroupRows';
import ProfitabilityProjectGroupRows from './ProfitabilityProjectGroupRows';

const useStyles = makeStyles({
  divider: {
    margin: '12px 0'
  },
  table: {
    minWidth: 1200,
  },
  tableRow: {
    background: 'rgb(187, 187, 187)',
    '& .MuiTableCell-root': {
      padding: '16px 8px',
      color: '#000000',
    },
  },
});

type Props = {
  orders: OrdersEntity[];
  slotDevExpenses: SlotDevExpenses[];
};

const Profitability: FC<Props> = ({ orders, slotDevExpenses }) => {
  const [isSlotType, setIsSlotType] = useState(true);
  const classes = useStyles();

  return (
    <>
      <Button variant="contained" color="default" onClick={() => setIsSlotType((prev) => !prev)}>
        {isSlotType ? 'By Projects' : 'By Slots'}
      </Button>
      <Divider className={classes.divider} />
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow className={classes.tableRow}>
              <TableCell>Заказ покупателя.Проект</TableCell>
              <TableCell align="right">Выручка, руб.</TableCell>
              <TableCell align="right">Себестоимость, руб.</TableCell>
              <TableCell align="right">Прибыль по проектам, руб.</TableCell>
              <TableCell align="right">Проектная рентабельность, %</TableCell>
              <TableCell align="right">Продано часов всего</TableCell>
              <TableCell align="right">Потрачено часов всего</TableCell>
              <TableCell align="right">Вылет/экономия</TableCell>
              <TableCell align="right">Development expenses, руб.</TableCell>
              <TableCell align="right">Валовая производственная маржа (ВПМ), руб.</TableCell>
              <TableCell align="right">Рентаб-ть ВПМ, %</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isSlotType
              ? groupSlotOrdersData(orders, slotDevExpenses).map((slotItem) => (
                  <ProfitabilitySlotGroupRows key={slotItem.slotName} slotItem={slotItem} />
                ))
              : groupProjectOrdersData(orders, slotDevExpenses).map((projItem) => (
                  <ProfitabilityProjectGroupRows key={projItem.project} projectOrders={projItem} />
                ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default Profitability;
