import React, { FC, useEffect, useRef, useState } from "react";
import { IOrderActivity } from "~/config/pages/orders/types";
import { flow } from "mobx";
import { OrderViewHistoryRow } from "~/config/pages/orders/OrderViewHistoryRow";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./styles";
import { observer } from "mobx-react";
import { OrderEntity } from "~/config/pages/orders/OrderEntity";

interface IProps {
  id: string;
  fetchHistory: (orderId: string) => Promise<IOrderActivity[]>;
  data: Record<string, string>;
  entity: OrderEntity;
}

const useStyles = makeStyles(styles);
const OrderViewHistory: FC<IProps> = observer(
  ({ id, fetchHistory, data, entity }) => {
    const [activities, setActivities] = useState<IOrderActivity[]>([]);
    const classes = useStyles();

    useEffect(() => {
      // make cancellable promise
      const loader = flow(function* getHistory() {
        const result = yield fetchHistory(id);
        setActivities(result);
      })();

      return () => {
        if (loader && loader.cancel) {
          loader.cancel();
        }
      };
    }, [id, fetchHistory]);

    return (
      <div className={classes.wrap}>
        <table className={classes.table}>
          <thead>
          <tr>
            <th>Пользователь</th>
            <th>Изменено</th>
            <th>Время</th>
          </tr>
          </thead>
          <tbody>
            {activities.map((activity) => (
              <OrderViewHistoryRow
                activity={activity}
                key={activity.id.toString()}
                data={data}
                entity={entity}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
);

export { OrderViewHistory };
