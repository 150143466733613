import React, { FC, useEffect, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import { Paper, Tabs, Tab } from '@material-ui/core';
import { IEntityField } from 'icerockdev-admin-toolkit';
import { OrderViewStatistics } from '../OrderViewStatistics';
import { OrderViewReleases } from '../OrderViewReleases';
import { OrderViewComments } from '../OrderViewComments';
import { useHistory, Switch, Route, Redirect } from 'react-router-dom';
import { OrderEditorTab } from '~/config/pages/orders/OrderEditorTab';
import { ORDER_EDITOR_TABS } from '~/config/pages/orders/OrderEditor/constants';
import { IOrderActivity } from "~/config/pages/orders/types";
import { OrderEntity } from "~/config/pages/orders/OrderEntity";
import { OrderViewHistory } from '~/config/pages/orders/OrderViewHistory';

const TABS = [
  "summary",
  "dates",
  "statistics",
  "releases",
  "comments",
  "history",
];

const TAB_TITLES = [
  ORDER_EDITOR_TABS[0].title,
  ORDER_EDITOR_TABS[1].title,
  'Статистика',
  'Релизы',
  'Комментарии',
  'История'
];

interface IProps {
  id: string;
  data: Record<string, string>;
  entity: OrderEntity;
  fields: IEntityField[];
  getItem: (id: string) => void;
  cancelGetItem: () => void;
  postComment: (orderId: number, text: string) => Promise<Record<string, string>>;
  fetchComments: (props: {
    id: number;
    skip: number;
  }) => Promise<Record<string, string>[]>;
  fetchHistory: (orderId: string) => Promise<IOrderActivity[]>;
}

const OrderView: FC<IProps> = observer(
  ({
    id,
    entity,
    data,
    fields,
    getItem,
    cancelGetItem,
    postComment,
    fetchComments,
    fetchHistory,
  }) => {
    const history = useHistory();

    const currentTab = useMemo(() => {
      const match = history.location.pathname.match(/\/([^\/]+)$/);
      const current = (match && match[1] && TABS.indexOf(match[1])) || 0;
      return current >= 0 ? current : 0;
    }, [history]);

    const onTabChange = useCallback(
      (_, index: number) => history.push(`/orders/${id}/${TABS[index].toLowerCase()}`),
      [history]
    );

    useEffect(() => {
      getItem(id);
      return cancelGetItem;
    }, [id]);

    return (
      <Paper square>
        <Tabs
          value={currentTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={onTabChange}
        >
          {TABS.map((tab, index) => (
            <Tab label={TAB_TITLES[index]} key={tab} />
          ))}
        </Tabs>

        <Switch>
          <Route
            path={`/orders/${id}/${TABS[0]}`}
            render={() => (
              <OrderEditorTab fields={fields} data={data} groups={ORDER_EDITOR_TABS[0].groups} />
            )}
          />

          <Route
            path={`/orders/${id}/${TABS[1]}`}
            render={() => (
              <OrderEditorTab fields={fields} data={data} groups={ORDER_EDITOR_TABS[1].groups} />
            )}
          />

          <Route
            path={`/orders/${id}/${TABS[2]}`}
            render={() => <OrderViewStatistics data={data} fields={fields} />}
          />

          <Route
            path={`/orders/${id}/${TABS[3]}`}
            render={() => <OrderViewReleases data={data} fields={fields} />}
          />

          <Route
            path={`/orders/${id}/${TABS[4]}`}
            render={() => (
              <OrderViewComments
                data={data}
                postComment={postComment}
                fetchComments={fetchComments}
              />
            )}
          />

          <Route
            path={`/orders/${id}/${TABS[5]}`}
            render={() => (
              <OrderViewHistory
                id={id}
                fetchHistory={fetchHistory}
                data={data}
                entity={entity}
              />
            )}
          />

          <Redirect to={`/orders/${id}/${TABS[0]}`} />
        </Switch>
      </Paper>
    );
  }
);

export { OrderView };
