import Axios from "axios";
import {
  Entity,
  IEntityCreateFunctionProps,
  IEntityCreateFunctionResult,
  IEntityFetchFunction,
  IEntityGetFunction,
  IEntityUpdateFunctionProps,
  IEntityUpdateFunctionResult,
  UNAUTHORIZED,
} from "icerockdev-admin-toolkit";
import qs from "qs";
import omit from "ramda/es/omit";
import {ORDER_FIELDS} from "./fields";
import HttpStatusCode from 'http-status-typed';
import {parseResponseErrorMessage} from "~/utils/parse";
import {IOrderActivity} from "~/config/pages/orders/types";

export const fetchOrderReference = (url: string, type: string) => async (
  entity: Entity
) => {
  const withToken = entity.parent?.auth?.withToken;

  if (!withToken) return {};

  const personResponse = await withToken(
    ({ token }) =>
      Axios.get(`${url}/${type}`, {
        params: {
          _start: 0,
          _end: 9999,
          _sort: "id",
          _order: "DESC",
        },
        headers: { authorization: token },
      }).catch((e) =>
        e.response?.status === HttpStatusCode.UNAUTHORIZED
          ? { error: UNAUTHORIZED }
          : e.response
      ),
    {}
  );

  if (
    !personResponse ||
    !personResponse.data ||
    !personResponse.data.length ||
    personResponse.error
  )
    return {};

  return personResponse.data.reduce(
    (obj: Record<string, { fullName: string; isActive: boolean }>, person: { fullName: string; id: string; isActive: boolean }) => ({
      ...obj,
      [person.id]: {fullName: person.fullName, isActive: person.isActive},
    }),
    {}
  );
};

export const fetchOrderSlots = (url: string) => async (
  entity: Entity
) => {
  const withToken = entity.parent?.auth?.withToken;

  if (!withToken) return {};

  const response = await withToken(
    ({token}) =>
      Axios.get(`${url}/slots`, {
        headers: {authorization: token},
      }).catch((e) =>
        e.response?.status === HttpStatusCode.UNAUTHORIZED
          ? {error: UNAUTHORIZED}
          : e.response
      ),
    {}
  );

  if (!response?.data || !response.data.length) {
    return [];
  }

  return response.data.reduce(
    (obj: Record<string, { name: string; manager: string }>, slot: { id: number; name: string; managerUser: string; }) => ({
      ...obj,
      [slot.id]: {name: slot.name, manager: slot.managerUser},
    }),
    {}
  );
};

export const fetchJiraCategories = (url: string, type: string) => async (
  entity: Entity
) => {
  const withToken = entity.parent?.auth?.withToken;

  if (!withToken) return {};

  const response = await withToken(
    ({ token }) =>
      Axios.get(`${url}/${type}`, {
        params: {
          _start: 0,
          _end: 9999,
        },
        headers: { authorization: token },
      }).catch((e) =>
        e.response?.status === HttpStatusCode.UNAUTHORIZED
          ? { error: UNAUTHORIZED }
          : e.response
      ),
    {}
  );

  if (!response || !response.data || !response.data.length) return {};

  return response.data
    .sort((a, b) => a.name.localeCompare(b.name))
    .reduce(
      (obj: Record<string, string>, category: { name: string }) => ({
        ...obj,
        [category.name]: category.name,
      }),
      {}
    );
};

export const fetchOrderComments = async ({
  url,
  token,
  orderId,
  skip = 0,
}: {
  url: string;
  token: string;
  orderId: number;
  skip: number;
}): Promise<Record<string, string>[]> => {
  const response = await Axios.get(
    `${url.replace(
      "/orders",
      "/order-comments"
    )}?orderId=${orderId}&_order=DESC&_start=${skip}&_end=${skip + 20}&_sort=dateTime`,
    {
      headers: { authorization: token },
    }
  ).catch((e) => e.response);

  if (!response?.data || !response.data.length) return [];

  return response.data;
};

const getOrderReleases = async ({
  url,
  token,
  number,
}: {
  url: string;
  token?: string;
  number: string;
}): Promise<Record<string, any>[]> => {
  const response = await Axios.get(url.replace("orders", "releases"), {
    params: {
      _start: 0,
      _end: 25,
      _order: "DESC",
      orderNumber: number,
    },
    headers: {
      authorization: token,
    },
  }).catch((e) => e.response);

  if (!response?.data || !response.data.length) {
    return [];
  }

  return response.data;
};

export const fetchOrderItemsFn: IEntityFetchFunction = async ({
  url,
  token,
  page = 0,
  count,
  sortDir,
  sortBy: _sort,
  filter,
}) => {
  const _start = page && count ? page * count : 0;
  const _end = count ? (page + 1) * count : 25;
  const _order = sortDir.toUpperCase();
  const filters: Record<string, string> =
    filter?.reduce((obj, item) => ({ ...obj, [item.name]: item.value }), {}) ||
    {};

  // fetching orders
  const response = await Axios.get(url, {
    params: {
      _start,
      _end,
      ...(_sort ? { _sort, _order } : {}),
      ...filters,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
    headers: { authorization: token },
  }).catch((e) => e.response);

  if (response && response.status === HttpStatusCode.UNAUTHORIZED) {
    return {
      data: {
        list: [],
        totalCount: 0,
      },
      error: UNAUTHORIZED,
    };
  }

  if (response?.status !== HttpStatusCode.OK || !response?.data)
    return {
      data: { list: [] },
      error: parseResponseErrorMessage(response) ||  `Не удалось получить заказы`,
    };

  const list = response.data;

  return {
    data: {
      list,
      totalCount: parseInt(response.headers["x-total-count"] || 0, 10),
    },
    filterData: (list && list[0]) || {}, // we use it for salesUserList, teamLeadUserList and responsibleUserList
    error: "",
  };
};

export const getOrderItemFn: IEntityGetFunction = async ({
  url,
  id,
  token,
}) => {
  const result = await Axios.get(`${url}/${id}`, {
    headers: {
      authorization: token,
    },
  }).catch((e) => e.response);

  if (result && result.status === HttpStatusCode.UNAUTHORIZED) {
    return {
      data: {
        list: [],
        totalCount: 0,
      },
      error: UNAUTHORIZED,
    };
  }

  if (result?.status !== HttpStatusCode.OK || !result?.data)
    return {
      data: {},
      error: parseResponseErrorMessage(result) || `Не удалось загрузить заказ`,
    };

  let data = result.data;

  data.releases = await getOrderReleases({
    url,
    token,
    number: data.number,
  });

  return {
    data,
    error: "",
  };
};

export const postOrderComment = async ({
  token,
  text,
  url,
  orderId,
}: {
  token: string;
  text: string;
  url: string;
  orderId: string;
}): Promise<{
  id: number;
  author: string;
  text: string;
  dateTime: string;
}> => {
  const response = await Axios.post(
    url,
    { orderId, text },
    { headers: { authorization: token } }
  ).catch((e) => e.response);

  return response.data || {};
};

export const updatePriority = async ({
  token,
  priority,
  url,
  orderId,
}: {
  token: string;
  priority: number;
  url: string;
  orderId: string;
}) => {
  const response = await Axios.post(
    url,
    { orderId, priority },
    { headers: { authorization: token } }
  ).catch((e) => e.response);

  return response.data || {};
};

const cleanNullFields = (parsed: Record<string, any>) => {
  const nullFields = ORDER_FIELDS.filter(
    (field) =>
      field.type === "select" ||
      field.type === "referenceSelect" ||
      ["productionStatus", "saleStatus", "healthStatus"].includes(field.name)
  ).map((field) => field.name);

  return Object.keys(parsed).reduce(
    (obj, field) => ({
      ...obj,
      [field]:
        nullFields.includes(field) && parsed[field] === ""
          ? null
          : parsed[field],
    }),
    {}
  );
};
export const updateOrderItemFn = async ({
  url,
  token,
  data,
}: IEntityUpdateFunctionProps): Promise<IEntityUpdateFunctionResult> => {
  const parsed = omit([
    "salesUserList",
    "responsibleUserList",
    "teamLeadUserList",
    "releases",
    "comments",
  ])(cleanNullFields(data)) as Record<string, any>;


  const hasFactApproveDate = !!parsed.factApproveDate
  const hasDoneStatus = parsed.productionStatus === "DONE"

  if ((hasFactApproveDate && !hasDoneStatus) || (hasDoneStatus && !hasFactApproveDate)){
    return {
      data: {},
      error: `Заказ с фактической датой приёмки должен иметь производственный статус "Утверждено" и наоборот`
    }
  }

  const result = await Axios.put(`${url}/${data.id}`, parsed, {
    headers: { authorization: token },
  }).catch((e) => e.response);

  if (result?.status === HttpStatusCode.UNAUTHORIZED) return { data: {}, error: UNAUTHORIZED };
  if (result?.status !== HttpStatusCode.OK || !result?.data) {
    const error = parseResponseErrorMessage(result) || `Не удалось обновить заказ`

    return { data: {}, error }
  }

  return {
    data: result.data,
  };
};

export const createOrderItemFn = async ({
  url,
  token,
  data,
}: IEntityCreateFunctionProps): Promise<IEntityCreateFunctionResult> => {
  const parsed = omit([
    "salesUserList",
    "responsibleUserList",
    "teamLeadUserList",
    "releases",
    "comments",
  ])(cleanNullFields(data));

  const result = await Axios.post(url, parsed, {
    headers: { authorization: token },
  }).catch((e) => e.response);

  if (result?.status === HttpStatusCode.UNAUTHORIZED) return { data: {}, error: UNAUTHORIZED };
  if (result?.status !== HttpStatusCode.OK || !result?.data) {
    const error = parseResponseErrorMessage(result) || `Не удалось создать заказ`

    return { data: {}, error };
  }

  return {
    data: result.data,
  };
};

export const fetchOrderHistoryFn = async (props: {
  url: string;
  token: string;
  id: number;
}): Promise<IOrderActivity[]> => {
  const result = await Axios.get(props.url, {
    headers: { authorization: props.token },
    params: {
      referenceSource: "order",
      referenceId: props.id,
    },
  });

  return Array.isArray(result.data) ? result.data : [];
};

export const archiveOrderItemFn = async (props: {
  url,
  id,
  token,
}) => {
  const result = await Axios.delete(`${props.url}/${props.id}`, {
    headers: {
      authorization: props.token,
    },
  }).catch((e) => e.response);

  if (result && result.status === HttpStatusCode.UNAUTHORIZED) {
    return {
      error: UNAUTHORIZED,
    };
  }

  if (result?.status !== HttpStatusCode.OK) {
    return {
      error: parseResponseErrorMessage(result) || `Не удалось удалить заказ`,
    };
  }

  return {};
};
