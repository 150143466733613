import {EmployeesEntity} from "~/config/pages/reports/api-types";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import React from "react";

export function NotLinkedTracking(props: { data: EmployeesEntity[] }) {
  let items = props.data;

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Username</TableCell>
            <TableCell align="right">Номер задачи</TableCell>
            <TableCell align="right">Описание задачи</TableCell>
            <TableCell align="right">Дата</TableCell>
            <TableCell align="right">Часов</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.flatMap((employee) => {
            return employee.noOrderTracking.map(track =>
              (
                <TableRow key={employee.username + track.id}>
                  <TableCell component="th" scope="row">{employee.username}</TableCell>
                  <TableCell align="right">{track.issueKey}</TableCell>
                  <TableCell align="right">{track.issueSummary}</TableCell>
                  <TableCell align="right">{track.date}</TableCell>
                  <TableCell align="right">{track.hours.toFixed(2)}</TableCell>
                </TableRow>
              )
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
