import { useCallback, useEffect } from "react";

export const useCloseOnEscape = (onRequestClose: () => void, ignore_inputs = true) => {
  const onEscape = useCallback(event => {
    if (event.keyCode !== 27) return;
    if (ignore_inputs && (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA')) return;

    onRequestClose();
  }, [onRequestClose]);

  useEffect(() => {
    window.addEventListener('keyup', onEscape);

    return () => { window.removeEventListener('keyup', onEscape); };
  }, [onEscape]);
};

