import { Theme } from "@material-ui/core";

export default (theme: Theme) => ({
  wrap: {
    position: "relative" as "relative",
    height: 32,
    display: 'flex' as 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  indicator: {
    position: "absolute" as "absolute",
    background: "#E2141B",
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 0,
    height: '100%',
    borderRadius: 3,
  },
  text: {
    position: "relative" as "relative",
    zIndex: 1,
    textAlign: 'center' as 'center',
  }
});
