import {EmployeesEntity} from "~/config/pages/reports/api-types";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import React from "react";

export function EmployeeList(props: { data: EmployeesEntity[] }) {
  let items = props.data;

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Username</TableCell>
            <TableCell align="right">Полная ёмкость</TableCell>
            <TableCell align="right">Выпадающая ёмкость</TableCell>
            <TableCell align="right">Доступная ёмкость</TableCell>
            <TableCell align="right">Коммерческая ёмкость</TableCell>
            <TableCell align="right">Затреканные часы</TableCell>
            <TableCell align="right">Коммерческие часы</TableCell>
            <TableCell align="right">Внутрянка, часы</TableCell>
            <TableCell align="right">Больничный, часы</TableCell>
            <TableCell align="right">Sick day, часы</TableCell>
            <TableCell align="right">Часов отпуска</TableCell>
            <TableCell align="right">Неоплачиваемое, часы</TableCell>
            <TableCell align="right">Недогруз, часы</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((employee) => (
            <TableRow key={employee.username}>
              <TableCell component="th" scope="row">{employee.username}</TableCell>
              <TableCell align="right">{formatHour(employee.fullHours)}</TableCell>
              <TableCell align="right">{formatHour(employee.droppedHours, employee.fullHours)}</TableCell>
              <TableCell align="right">{formatHour(employee.workHours, employee.fullHours)}</TableCell>
              <TableCell align="right">{formatHour(employee.orderTrackingHours, employee.fullHours)}</TableCell>
              <TableCell align="right">{formatHour(employee.trackingHours, employee.workHours)}</TableCell>
              <TableCell align="right">{formatHour(employee.orderTrackingHours, employee.trackingHours)}</TableCell>
              <TableCell align="right">{formatHour(employee.internalTrackingHours, employee.trackingHours)}</TableCell>
              <TableCell align="right">{formatHour(employee.sickLeaveHours)}</TableCell>
              <TableCell align="right">{formatHour(employee.sickDaysHours)}</TableCell>
              <TableCell align="right">{formatHour(employee.vacationHours)}</TableCell>
              <TableCell align="right">{formatHour(employee.notWorkHours)}</TableCell>
              <TableCell align="right">{formatHour(employee.benchHours)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function formatHour(value: number, maxValue: number | null = null): string {
  if (maxValue == null) return value.toFixed(2)
  return value.toFixed(2) + " / " + maxValue.toFixed(2) +
    " (" + (value / maxValue * 100.0).toFixed(0) + "%)";
}
