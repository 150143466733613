import React, { useMemo, FC } from "react";
import { withStyles, WithStyles } from "@material-ui/core";
import styles from "./styles";
import { observer } from "mobx-react";
import {getHoursNumber} from "~/utils/helpers";

type IProps = {
  overtime: number;
  data: Record<any, any>;
};

const OrderExcessField = withStyles(styles)(
  observer(({ overtime, data, classes }: IProps & WithStyles<typeof styles>) => {

    overtime = overtime ?? 0;

    const percent = useMemo(
      () =>
          overtime == 0
          ? 0
          : ((data.estimateHours || data.estimateHours == 0) && overtime < data.estimateHours ? overtime / data.estimateHours : 1),
      [data]
    );

    return (
      <div className={classes.wrap}>
        {overtime > 0 && (
          <div
            className={classes.indicator}
            style={{
              opacity: percent,
            }}
          />
        )}
        <div
          className={classes.text}
          style={{ color: percent < 0.5 ? "#333333" : "white" }}
        >
          {overtime.toFixed(2)}
        </div>
      </div>
    );
  })
) as FC<IProps>;

const OrderExcessProdField = withStyles(styles)(({data}: IProps) => {
    let excessProdHours = data?.excessProdHours
    return (
        <OrderExcessField
            overtime={!!excessProdHours && getHoursNumber(data?.excessProdHours) || 0.00}
            data={{
                estimateHours: data?.prodHours
            }}
        />
    );
}) as FC<IProps>

const OrderExcessSoldField = withStyles(styles)(({data}: IProps) => {
    let excessSoldHours = data?.excessSoldHours;
    return (
        <OrderExcessField
            overtime={!!excessSoldHours && getHoursNumber(excessSoldHours) || 0.00}
            data={{
                estimateHours: data?.soldHours
            }}
        />
    );
}) as FC<IProps>

export { OrderExcessField, OrderExcessProdField, OrderExcessSoldField };
