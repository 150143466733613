import { ReportAnalyze } from '~/config/pages/reports/api-types';
import React from 'react';
import { HighlightsTab } from '~/config/pages/reports/ReportsPage/highlightsTab';
import { EmployeeList } from '~/config/pages/reports/ReportsPage/employeeList';
import { OrdersList } from '~/config/pages/reports/ReportsPage/ordersList';
import { IllegalTracking } from '~/config/pages/reports/ReportsPage/illegalTracking';
import { NotLinkedTracking } from '~/config/pages/reports/ReportsPage/notLinkedTracking';
import { Tab, Tabs } from '@material-ui/core';
import OrdersStat from './components/ordersStat';
import Profitability from "~/config/pages/reports/ReportsPage/components/profitability/Profitability";

export function ReportTabs(props: { data: ReportAnalyze }) {
  const [value, setValue] = React.useState(0);

  let body: JSX.Element;
  if (value === 0) {
    body = <HighlightsTab data={props.data} />;
  } else if (value === 1) {
    body = <EmployeeList data={props.data.employees} />;
  } else if (value === 2) {
    body = <OrdersList data={props.data.orders} />;
  } else if (value === 3) {
    body = <OrdersStat data={props.data.orders} />;
  } else if (value === 4) {
    body = <IllegalTracking data={props.data.employees} />;
  } else if (value === 5) {
    body = <NotLinkedTracking data={props.data.employees} />;
  } else if (value === 6) {
    body = <Profitability orders={props.data.orders} slotDevExpenses={props.data.slotDevExpenses} />;
  } else {
    body = <div></div>;
  }

  let errors = props.data.errors;
  let errorsBlock: JSX.Element | undefined;
  if (errors.length > 0) {
    errorsBlock = (
      <div style={{ background: 'red', padding: 8 }}>
        <p>
          При построении отчета произошли ошибки. Не все данные являются корректными, будьте
          осторожны.
        </p>
        <p>
          Примите меры для исправления ошибок (сообщите бухгалтерии о некорректных данных или
          исправьте данные в Jira, Watchdog)
        </p>
        <ul>
          {errors.map((error) => (
            <li>{error}</li>
          ))}
        </ul>
      </div>
    );
  } else {
    errorsBlock = undefined;
  }

  return (
    <div>
      {errorsBlock}
      <Tabs value={value} onChange={(e, val) => setValue(val)}>
        <Tab label="Summary" selected={value === 0} />
        <Tab label="Employees" selected={value === 1} />
        <Tab label="Orders" selected={value === 2} />
        <Tab label="Orders Stats" selected={value === 3} />
        <Tab label="Illegal tracking" selected={value === 4} />
        <Tab label="Not linked tracking" selected={value === 5} />
        <Tab label="Profitability of Orders" selected={value === 5} />
      </Tabs>
      <div style={{ paddingTop: 16 }}>{body}</div>
    </div>
  );
}
