import React, {
  FC,
  MouseEventHandler,
  useCallback,
  useMemo,
  useState,
  useRef,
  useEffect,
} from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  ListItemText,
  Input,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import styles from "./styles";

type IProps = {
  label: string;
  value: any;
  isEditing?: boolean;
  isFiltering?: boolean;
  handler?: (val: any) => void;
  error?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  options?: Record<any, any>;
} & Record<string, any>;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ProductionStatusField = withStyles(styles)(
  ({
    label,
    value,
    handler,
    error,
    isEditing,
    isFiltering,
    onClick,
    options,
    classes,
  }: IProps) => {
    const items = useMemo<string[]>(
      () => (value && value.split(",").filter((item) => item)) || [],
      [value]
    );

    const onChangeMultiple = useCallback(
      (event) => {
        if (!handler) return;

        handler(event.target.value.join(","));
      },
      [value, handler, items]
    );

    const onChange = useCallback(
      (event) => {
        if (!handler) return;

        handler(event.target.value);
      },
      [value, handler]
    );

    const ref = useRef<HTMLLabelElement>(null);
    const [labelWidth, setLabelWidth] = useState(0);

    useEffect(() => {
      setLabelWidth((ref.current && ref.current.clientWidth) || 0);
    }, [ref.current]);

    if (isFiltering) {
      return (
        <FormControl variant="outlined" className={classes.wrap}>
          <InputLabel htmlFor={label} ref={ref}>
            {label}
          </InputLabel>

          <Select
            variant="outlined"
            id={label}
            name={label}
            label={label}
            value={items}
            onChange={onChangeMultiple}
            input={<Input />}
            renderValue={(selected) =>
              (!!selected &&
                !!options &&
                (selected as string[])
                  .map((item) => options[item])
                  .join(", ")) ||
              ""
            }
            error={!!error}
            multiple
            inputProps={{ className: "select" }}
            MenuProps={MenuProps}
            labelWidth={labelWidth}
            style={{ minWidth: labelWidth + 40 }}
          >
            {options &&
              Object.keys(options).map((item) => (
                <MenuItem key={item} value={item}>
                  <Checkbox checked={items.indexOf(item) > -1} />
                  <ListItemText primary={options[item]} />
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      );
    }

    return isEditing ? (
      <FormControl variant="outlined">
        <InputLabel htmlFor={label} ref={ref}>
          {label}
        </InputLabel>

        <Select
          variant="outlined"
          id={label}
          name={label}
          label={label}
          value={!value ? "" : value}
          onChange={onChange}
          error={!!error}
          inputProps={{ className: "select" }}
          labelWidth={labelWidth}
          style={{ minWidth: labelWidth + 40 }}
        >
          <MenuItem value="">...</MenuItem>

          {options &&
            Object.keys(options).map((item) => (
              <MenuItem key={item} value={item}>
                {options[item]}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    ) : (
      <div onClick={onClick}>
        {(options && options[value]) || <div>&nbsp;</div>}
      </div>
    );
  }
) as FC<IProps>;

export { ProductionStatusField };
