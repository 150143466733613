import { Theme } from "@material-ui/core";

export default (theme: Theme) => ({
  wrap: {
    height: "100%",
    minHeight: 400,
    display: 'grid',
    gridTemplateRows: '1fr auto',
  },
  form: {
    margin: 20,
    padding: 20,
    background: 'white',
    borderRadius: '2px',
  },
  buttons: {
    display: "flex",
    marginTop: 10,
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  list_wrap: {
    flex: 1,
    position: 'relative' as 'relative',
    borderBottom: '1px solid #E5E5E5',
  },
  list: {
    padding: '20px 20px 10px 20px',
    display: "flex" as "flex",
    flexDirection: "column" as "column",
    maxHeight: '100%',
    overflow: "auto",
    marginBottom: 10,
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    position: 'absolute' as 'absolute',
  },
  comment: {
    position: "relative" as "relative",
    padding: 16,
    borderRadius: 2,
    background: "#eeeeee",
    marginBottom: 10,
    wordBreak: "break-word" as "break-word",
    fontSize: '12px',
    lineHeight: '16px',

    "& > b": {
      whiteSpace: "nowrap",
    },
  },
  comment_meta: {
    display: 'flex' as 'flex',
    flexDirection: 'row' as 'row',
    marginBottom: 10,

    '& b': {
      flex: 1,
    }
  },
  comment_date: {
    color: 'rgba(32, 32, 32, 0.5)',
  },
});
