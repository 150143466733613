import { OrderExcessProdField, OrderExcessSoldField } from "./OrderExscessField";
import { OrderSpentHoursInternal } from "./OrderSpentHoursInternal";
import { OrderSpentHours } from "./OrderSpentHours";
import { OrderSpentHoursExternal } from "./OrderSpentHoursExternal";
import {HEALTH_STATUSES, SALES_STATUS, PRODUCTION_STATUS, PRIORITY_OPTIONS} from "./constants";
import { OrderHealthStatus } from "./OrderHealthStatus";
import { ProductionStatusField } from "./ProductionStatusField";
import { IEntityField } from "icerockdev-admin-toolkit";
import { OrderSoldHours } from "./OrderSoldHours";
import { JiraAutocomplete } from "./JiraAutocompleteField";
import { OrderProdHours } from "~/config/pages/orders/OrderProdHours";
import { isPositiveOrEmpty } from '~/utils/validators';
import { OrderPriority} from "~/config/pages/orders/OrderPriorityField";
import {OrderReferenceSelect} from "~/config/pages/orders/OrderReferenceSelect";
import {OrderSlotSelect} from "~/config/pages/orders/OrderSlotSelect";
import {EntityFieldDate} from "~/components/EntityFieldDate";
import {OrderDate} from "~/config/pages/orders/OrderDate";

export const ORDER_FIELDS: IEntityField[] = [
  {
    name: "id",
    type: "number",
    label: "id",
    hideInCreate: true,
    hideInEdit: true,
    hideInList: true,
    filterable: false,
  },
  {
    label: "Приоритет",
    name: "priority",
    type: "custom",
    component: OrderPriority,
    options: PRIORITY_OPTIONS,
    hideInList: false,
    hideInCreate: true,
    hideInExport: true,
    hideInEdit: true,
  },
  {
    name: "slotId",
    label: "Слот",
    type: "referenceSelect",
    component: OrderSlotSelect,
    filterable: true,
  },
  {
    name: "number",
    type: "string",
    label: "Номер заказа",
    sortable: true,
    filterable: false,
    title: true,
    validator: (val: string) => {
      return val && val.match(/^FP-[0-9]{1,9}(-[0-9]{1,9})?|TM-[0-9]{1,9}(-[0-9]{1,9})?|NS-[0-9]{1,9}(-[0-9]{1,9})?$/)
        ? ""
        : "Номер заказа должен соответствовать шаблонам FP-(номер) или TM-(номер) или NS-(нормер)";
    },
  },
  {
    name: "legalEntity",
    label: "Юридическое лицо",
    type: "string",
    hideInExport: true,
    hideInEdit: false,
    hideInList: true,
  },
  {
    name: "jiraCategory",
    type: "referenceSelect",
    label: "Jira category",
    sortable: true,
    filterable: true,
    hideInCreate: true,
    component: JiraAutocomplete,
  },
  {
    name: "excludeJiraCategory",
    type: "referenceSelect",
    label: "Не в Jira category",
    sortable: true,
    filterable: true,
    hideInCreate: true,
    hideInList: true,
    hideInEdit: true,
    hideInExport: true,
    component: JiraAutocomplete,
  },
  {
    name: "description",
    type: "string",
    label: "Описание",
    sortable: true,
    filterable: false,
    required: true,
  },
  {
    name: "productionStatus",
    label: "Production Status",
    type: "custom",
    options: PRODUCTION_STATUS,
    sortable: true,
    required: true,
    filterable: true,
    hideInCreate: true,
    component: ProductionStatusField,
  },
  {
    name: "saleStatus",
    label: "Sale Status",
    type: "custom",
    options: SALES_STATUS,
    sortable: true,
    required: true,
    filterable: true,
    hideInCreate: true,
    component: ProductionStatusField,
  },
  {
    name: "healthStatus",
    label: "Health Status",
    type: "custom",
    hideInEdit: false,
    hideInList: false,
    hideInCreate: true,
    sortable: true,
    filterable: true,
    component: OrderHealthStatus as any,
    options: HEALTH_STATUSES.reduce(
      (obj, status) => ({ ...obj, [status.id]: status.name }),
      {}
    ),
  },
  {
    name: "responsibleUser",
    label: "Responsible User",
    type: "referenceSelect",
    component: OrderReferenceSelect,
    sortable: true,
    filterable: true,
    hideInCreate: true,
  },
  {
    name: "teamLeadUser",
    label: "Teamlead User",
    type: "referenceSelect",
    component: OrderReferenceSelect,
    sortable: true,
    filterable: true,
    options: {},
    hideInCreate: true,
    hideInList: true,
  },
  {
    name: "salesUser",
    label: "Sales User",
    type: "referenceSelect",
    component: OrderReferenceSelect,
    sortable: true,
    filterable: true,
    options: {},
    hideInCreate: false,
    hideInList: true,
    required: true,
  },
  {
    name: "soldHours",
    label: "Sold Hours",
    type: "custom",
    sortable: true,
    filterable: false,
    hideInEdit: true,
    hideInCreate: true,
    component: OrderSoldHours
  },
  {
    name: "prodHours",
    label: "Prod Hours",
    type: "custom",
    sortable: true,
    filterable: false,
    hideInEdit: true,
    hideInCreate: true,
    component: OrderProdHours,
  },
  {
    name: "spentHours",
    label: "Spent Hours",
    type: "custom",
    sortable: true,
    hideInEdit: true,
    hideInCreate: true,
    filterable: false,
    component: OrderSpentHours,
  },
  {
    name: "spentHoursInternal",
    label: "Spent Hours (internal)",
    type: "custom",
    sortable: true,
    hideInEdit: true,
    hideInCreate: true,
    filterable: false,
    component: OrderSpentHoursInternal,
  },
  {
    name: "spentHoursExternal",
    label: "Spent Hours (external)",
    type: "custom",
    sortable: true,
    hideInEdit: true,
    hideInCreate: true,
    filterable: false,
    component: OrderSpentHoursExternal,
  },
  {
    label: "Дата из ДС",
    name: "supplementaryAgreementDate",
    type: "date",
    component: EntityFieldDate,
    sortable: true,
    hideInList: false,
    hideInCreate: true,
    hideInExport: true,
    hideInEdit: false,
  },
  {
    label: "Плановая дата приёмки",
    name: "plannedApproveDate",
    type: "date",
    component: OrderDate,
    sortable: true,
    hideInList: true,
    hideInCreate: true,
  },
  {
    label: "Прогнозная дата приёмки",
    name: "estimatedApproveDate",
    type: "date",
    component: OrderDate,
    sortable: true,
    hideInList: false,
    hideInCreate: true,
  },
  {
    label: "Фактическая дата приёмки",
    name: "factApproveDate",
    type: "date",
    component: OrderDate,
    sortable: true,
    filterable: false,
    hideInList: false,
    hideInCreate: true,
  },
  {
    name: "soldHoursIos",
    label: "Sold Hours IOS",
    type: "number",
    sortable: true,
    filterable: false,
    hideInList: true,
    hideInExport: true,
    options: {
      accuracy: 1,
    },
    validator: isPositiveOrEmpty,
  },
  {
    name: "soldHoursAndroid",
    label: "Sold Hours Android",
    type: "number",
    sortable: true,
    filterable: false,
    hideInExport: true,
    hideInList: true,
    options: {
      accuracy: 1,
    },
    validator: isPositiveOrEmpty,
  },
  {
    name: "soldHoursWeb",
    label: "Sold Hours Web",
    type: "number",
    sortable: true,
    filterable: false,
    hideInExport: true,
    hideInList: true,
    options: {
      accuracy: 1,
    },
    validator: isPositiveOrEmpty,
  },
  {
    name: "soldHoursDesign",
    label: "Sold Hours Design",
    type: "number",
    sortable: true,
    filterable: false,
    hideInExport: true,
    hideInList: true,
    options: {
      accuracy: 1,
    },
    validator: isPositiveOrEmpty,
  },
  {
    name: "soldHoursAnalytics",
    label: "Sold Hours Analytics",
    type: "number",
    sortable: true,
    filterable: false,
    hideInExport: true,
    hideInList: true,
    options: {
      accuracy: 1,
    },
    validator: isPositiveOrEmpty,
  },
  {
    name: "soldHoursPM",
    label: "Sold Hours PM",
    type: "number",
    sortable: true,
    filterable: false,
    hideInExport: true,
    hideInList: true,
    options: {
      accuracy: 1,
    },
    validator: isPositiveOrEmpty,
  },
  {
    name: "soldHoursQA",
    label: "Sold Hours QA",
    type: "number",
    sortable: true,
    filterable: false,
    hideInExport: true,
    hideInList: true,
    options: {
      accuracy: 1,
    },
    validator: isPositiveOrEmpty,
  },
  {
    name: "soldHoursSupport",
    label: "Sold Hours Support",
    type: "number",
    sortable: true,
    filterable: false,
    hideInExport: true,
    hideInList: true,
    options: {
      accuracy: 1,
    },
    validator: isPositiveOrEmpty,
  },
  {
    name: "soldHoursMPP",
    label: "Sold Hours MPP",
    type: "number",
    sortable: true,
    filterable: false,
    hideInExport: true,
    hideInList: true,
    options: {
      accuracy: 1,
    },
    validator: isPositiveOrEmpty,
  },
  {
    name: "soldHoursDevOps",
    label: "Sold Hours DevOps",
    type: "number",
    sortable: true,
    filterable: false,
    hideInExport: true,
    hideInList: true,
    options: {
      accuracy: 1,
    },
    validator: isPositiveOrEmpty,
  },
  {
    name: "prodHoursIos",
    label: "Prod Hours IOS",
    type: "number",
    sortable: true,
    filterable: false,
    hideInList: true,
    hideInExport: true,
    options: {
      accuracy: 1,
    },
    validator: isPositiveOrEmpty,
  },
  {
    name: "prodHoursAndroid",
    label: "Prod Hours Android",
    type: "number",
    sortable: true,
    filterable: false,
    hideInExport: true,
    hideInList: true,
    options: {
      accuracy: 1,
    },
    validator: isPositiveOrEmpty,
  },
  {
    name: "prodHoursWeb",
    label: "Prod Hours Web",
    type: "number",
    sortable: true,
    filterable: false,
    hideInExport: true,
    hideInList: true,
    options: {
      accuracy: 1,
    },
    validator: isPositiveOrEmpty,
  },
  {
    name: "prodHoursDesign",
    label: "Prod Hours Design",
    type: "number",
    sortable: true,
    filterable: false,
    hideInExport: true,
    hideInList: true,
    options: {
      accuracy: 1,
    },
    validator: isPositiveOrEmpty,
  },
  {
    name: "prodHoursAnalytics",
    label: "Prod Hours Analytics",
    type: "number",
    sortable: true,
    filterable: false,
    hideInExport: true,
    hideInList: true,
    options: {
      accuracy: 1,
    },
    validator: isPositiveOrEmpty,
  },
  {
    name: "prodHoursPM",
    label: "Prod Hours PM",
    type: "number",
    sortable: true,
    filterable: false,
    hideInExport: true,
    hideInList: true,
    options: {
      accuracy: 1,
    },
    validator: isPositiveOrEmpty,
  },
  {
    name: "prodHoursQA",
    label: "Prod Hours QA",
    type: "number",
    sortable: true,
    filterable: false,
    hideInExport: true,
    hideInList: true,
    options: {
      accuracy: 1,
    },
    validator: isPositiveOrEmpty,
  },
  {
    name: "prodHoursSupport",
    label: "Prod Hours Support",
    type: "number",
    sortable: true,
    filterable: false,
    hideInExport: true,
    hideInList: true,
    options: {
      accuracy: 1,
    },
    validator: isPositiveOrEmpty,
  },
  {
    name: "prodHoursMPP",
    label: "Prod Hours MPP",
    type: "number",
    sortable: true,
    filterable: false,
    hideInExport: true,
    hideInList: true,
    options: {
      accuracy: 1,
    },
    validator: isPositiveOrEmpty,
  },
  {
    name: "prodHoursDevOps",
    label: "Prod Hours DevOps",
    type: "number",
    sortable: true,
    filterable: false,
    hideInExport: true,
    hideInList: true,
    options: {
      accuracy: 1,
    },
    validator: isPositiveOrEmpty,
  },
  {
    label: "Желаемая дата начала от сейлза",
    name: "startDateFromSales",
    type: "date",
    component: EntityFieldDate,
    hideInExport: true,
    hideInList: true,
    hideInCreate: true,
    hideInEdit: true
  },
  {
    label: "Желаемая дата завершения от сейлза",
    name: "endDateFromSales",
    type: "date",
    component: EntityFieldDate,
    hideInExport: true,
    hideInList: true,
    hideInCreate: true,
    hideInEdit: true,
  },
  {
    label: "Плановая дата начала разработки",
    name: "plannedStartDevelopmentDate",
    type: "date",
    component: EntityFieldDate,
    hideInList: true,
    hideInExport: true,
    hideInEdit: true,
    hideInCreate: true,
  },
  {
    label: "Плановая дата завершения разработки",
    name: "plannedEndDevelopmentDate",
    type: "date",
    component: EntityFieldDate,
    hideInList: true,
    hideInExport: true,
    hideInCreate: true,
    hideInEdit: true
  },
  {
    label: "Прогнозная дата завершения разработки",
    name: "estimatedEndDevelopmentDate",
    type: "date",
    component: EntityFieldDate,
    hideInList: true,
    hideInExport: true,
    hideInCreate: true,
    hideInEdit: true
  },
  {
    label: "Фактическая дата начала разработки",
    name: "factStartDate",
    type: "date",
    component: EntityFieldDate,
    hideInList: true,
    hideInExport: true,
    hideInCreate: true,
    hideInEdit: true,
  },
  {
    label: "Фактическая дата завершения разработки",
    name: "factEndDevelopmentDate",
    type: "date",
    component: EntityFieldDate,
    hideInList: true,
    hideInExport: true,
    hideInCreate: true,
    hideInEdit: true,
  },
  {
    name: "preprodEpicKey",
    label: "Preprod Epic Key",
    type: "string",
    hideInExport: true,
    hideInList: true,
    validator: (val: string) =>
      !val || (val.length >= 0 && val.length <= 12)
        ? ""
        : "Должно быть от 0 до 12 символов",
  },
  {
    name: "requirementsLink",
    label: "Requirements Link",
    type: "string",
    hideInExport: true,
    hideInList: true,
    validator: (val: string) =>
      !val || (val.length >= 0 && val.length <= 512)
        ? ""
        : "Должно быть от 0 до 512 символов",
  },
  {
    name: "communicationsLink",
    label: "Communications Link",
    type: "string",
    hideInExport: true,
    hideInList: true,
    validator: (val: string) =>
      !val || (val.length >= 0 && val.length <= 512)
        ? ""
        : "Должно быть от 0 до 512 символов",
  },
  {
    name: "trelloLink",
    label: "Trello Link",
    type: "string",
    hideInExport: true,
    hideInList: true,
    validator: (val: string) =>
      !val || (val.length >= 0 && val.length <= 512)
        ? ""
        : "Должно быть от 0 до 512 символов",
  },
  {
    name: "productionEstimateLink",
    label: "Ссылка на Производственную оценку",
    type: "string",
    hideInExport: true,
    hideInList: true,
    validator: (val: string) =>
      !val || (val.length >= 0 && val.length <= 512)
        ? ""
        : "Должно быть от 0 до 512 символов",
  },
  {
    name: "pricePerHourRub",
    label: "Стоимость часа, руб.",
    type: "number",
    hideInExport: true,
    hideInList: true,
    hideInEdit: true,
  },
  {
    name: "commissionPerHourRub",
    label: "Комиссия за час, руб.",
    type: "number",
    hideInExport: true,
    hideInList: true,
    hideInEdit: true,
  },
  // date filters
  {
    label: "Плановая дата приёмки",
    name: "plannedApproveDateRange",
    type: "daterange",
    filterable: true,
    hideInList: true,
    hideInCreate: true,
    hideInEdit: true,
    hideInExport: true,
  },
  {
    label: "Прогнозная дата приёмки",
    name: "estimatedApproveDateRange",
    type: "daterange",
    filterable: true,
    hideInList: true,
    hideInCreate: true,
    hideInEdit: true,
    hideInExport: true,
  },
  {
    label: "Фактическая дата приёмки",
    name: "factApproveDateRange",
    type: "daterange",
    filterable: true,
    hideInList: true,
    hideInCreate: true,
    hideInEdit: true,
    hideInExport: true,
  },
  {
    name: "confluenceKey",
    label: "Confluence key",
    type: "string",
    hideInEdit: false,
    hideInExport: true,
    hideInCreate: true,
    hideInList: true,
  },
  {
    label: "Дата заведения заказа",
    name: "createDate",
    type: "datetime",
    hideInList: true,
    hideInExport: true,
    hideInCreate: true,
    hideInEdit: true,
  },
  {
    label: "Обновлено",
    name: "updateDate",
    type: "datetime",
    sortable: true,
    hideInList: false,
    hideInExport: true,
    hideInCreate: true,
    hideInEdit: true,
  },
  {
    label: "Дата выписки акта",
    name: "factActDate",
    type: "date",
    component: EntityFieldDate,
    hideInList: true,
    hideInCreate: true,
    hideInExport: true,
    hideInEdit: true,
  },
  {
    label: "Вылет комм.",
    name: "excessSoldHours",
    type: "custom",
    component: OrderExcessSoldField,
    sortable: true,
    hideInEdit: true,
    hideInCreate: true,
  },
  {
    label: "Вылет прод.",
    name: "excessProdHours",
    type: "custom",
    component: OrderExcessProdField,
    sortable: true,
    hideInEdit: true,
    hideInCreate: true,
  },
];
