export type IEditorTabGroup = {
  title: string,
  fields: string[],
}

export type IEditorTab = {
  title: string,
  name: string,
  groups: IEditorTabGroup[],
}

export const SOLD_HOURS_TO_PROD = {
  soldHoursIos: "prodHoursIos",
  soldHoursAndroid: "prodHoursAndroid",
  soldHoursWeb: "prodHoursWeb",
  soldHoursDesign: "prodHoursDesign",
  soldHoursAnalytics: "prodHoursAnalytics",
  soldHoursPM: "prodHoursPM",
  soldHoursQA: "prodHoursQA",
  soldHoursSupport: "prodHoursSupport",
  soldHoursMPP: "prodHoursMPP",
  soldHoursDevOps: "prodHoursDevOps",
}

export const ORDER_EDITOR_TABS: IEditorTab[] = [
  {
    name: 'summary',
    title: 'Общее',
    groups: [
      {
        title: 'Общая информация',
        fields: [
          "number",
          "legalEntity",
          "jiraCategory",
          "description",
          "slotId",
        ]
      },
      {
        title: 'Статусы',
        fields: [
          "productionStatus",
          "saleStatus",
          "healthStatus",
        ]
      },
      {
        title: 'Ответственные',
        fields: [
          "responsibleUser",
          "teamLeadUser",
          "salesUser",
        ]
      },
      {
        title: 'Сcылки',
        fields: [
          "confluenceKey",
          "preprodEpicKey",
          "requirementsLink",
          "communicationsLink",
          "trelloLink",
          "productionEstimateLink"
        ]
      }
    ]
  },
  {
    title: 'Даты',
    name: 'dates',
    groups: [
      {
        title: 'Общее',
        fields: [
          "createDate",
          "updateDate"
        ],
      },
      {
        title: 'Приёмка',
        fields: [
          "plannedApproveDate",
          "estimatedApproveDate",
          "factApproveDate",
        ]
      },
      {
        title: 'Акт',
        fields: [
          "factActDate",
        ]
      },
      {
        title: 'Доп. соглашение',
        fields: [
          "supplementaryAgreementDate",
        ]
      }
    ]

  },
  {
    title: 'Часы',
    name: 'hours',
    groups: [
      {
        title: 'Проданные часы',
        fields: [
          "soldHoursIos",
          "soldHoursAndroid",
          "soldHoursWeb",
          "soldHoursDesign",
          "soldHoursAnalytics",
          "soldHoursPM",
          "soldHoursQA",
          "soldHoursSupport",
          "soldHoursMPP",
          "soldHoursDevOps",
        ]
      },
      {
        title: 'Производственная оценка',
        fields: [
          "prodHoursIos",
          "prodHoursAndroid",
          "prodHoursWeb",
          "prodHoursDesign",
          "prodHoursAnalytics",
          "prodHoursPM",
          "prodHoursQA",
          "prodHoursSupport",
          "prodHoursMPP",
          "prodHoursDevOps",
        ]
      }
    ]
  }
]
