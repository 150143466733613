import HttpStatusCode from "http-status-typed";

import {
  IEntityFetchFunction,
  UNAUTHORIZED,
  Entity,
} from "icerockdev-admin-toolkit";
import Axios from "axios";
import qs from "qs";
import { OrderAutocomplete } from "./WorklogEntity";
import { fetchJiraCategories } from "~/config/pages/orders/api";
import omit from "ramda/es/omit";
import { formatFilterDate } from "~/utils/date";

const DEFAULT_WORKLOG_COUNT = 25;
const WORKLOG_LEADS_LIMIT = 9999;

export const fetchWorklogItemsFn: IEntityFetchFunction = async ({
  url,
  token,
  page = 0,
  count,
  sortDir,
  sortBy: _sort,
  filter,
}) => {
  const _start = page && count ? page * count : 0;
  const _end = count ? (page + 1) * count : DEFAULT_WORKLOG_COUNT;
  const _order = sortDir.toUpperCase();
  const filters: Record<string, string> =
    filter?.reduce((obj, item) => ({ ...obj, [item.name]: item.value }), {}) ||
    {};

  // fetching orders
  const response = await Axios.get(url, {
    params: {
      _start,
      _end,
      ...(_sort ? { _sort, _order } : {}),
      ...filters,
      ...(filters.dateTimeFilter
        ? {
            dateTimeStart: filters.dateTimeFilter.split(",")[0],
            dateTimeEnd: filters.dateTimeFilter.split(",")[1],
            dateTimeFilter: undefined,
          }
        : {}),
      ...(Object.prototype.hasOwnProperty.call(filters, "order")
        ? { order: filters.order || "" }
        : {}),
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
    headers: { authorization: token },
  }).catch((e) => e.response);

  if (response && response.status === HttpStatusCode.UNAUTHORIZED) {
    return {
      data: {
        list: [],
        totalCount: 0,
      },
      error: UNAUTHORIZED,
    };
  }

  if (response?.status !== HttpStatusCode.OK || !response?.data)
    return {
      data: { list: [] },
      error: response.data.message || `Не удалось получить заказы`,
    };

  const list = response.data;

  return {
    data: {
      list,
      totalCount: parseInt(response.headers["x-total-count"] || 0, 10),
    },
    filterData: (list && list[0]) || {}, // we use it for salesUserList, teamLeadUserList and responsibleUserList
    error: "",
  };
};

export const fetchWorklogUserReference = (url: string, type: string) => async (
  entity: Entity
) => {
  const withToken = entity.parent?.auth?.withToken;

  if (!withToken) return {};

  const personResponse = await withToken(
    ({ token }) =>
      Axios.get(`${url}/${type}`, {
        params: {
          _start: 0,
          _end: WORKLOG_LEADS_LIMIT,
          _sort: "id",
          _order: "DESC",
        },
        headers: { authorization: token },
      }).catch((e) =>
        e.response?.status === HttpStatusCode.UNAUTHORIZED
          ? { error: UNAUTHORIZED }
          : e.response
      ),
    {}
  );

  if (
    !personResponse ||
    !personResponse.data ||
    !personResponse.data.length ||
    personResponse.error
  )
    return {};

  return personResponse.data.reduce(
    (
      obj: Record<string, string>,
      person: { fullName: string; id: string; userName: string }
    ) => ({
      ...obj,
      [person.userName]: person.fullName,
    }),
    {}
  );
};

export const getWorklogOrdersAutocomplete = (url: string) => ({
  token,
}): Promise<{ data?: OrderAutocomplete[]; error?: string }> =>
  Axios.get(url, {
    headers: { authorization: token },
  })
    .then((result) => ({ data: result.data }))
    .catch((e) => ({
      data: [],
      error:
        e.response?.status === HttpStatusCode.UNAUTHORIZED
          ? UNAUTHORIZED
          : "Не могу загрузить заказы",
    }));

export const setWorklogOrders = (url: string) => ({
  worklogs,
  orderId,
  isBench,
  token,
}: {
  worklogs: number[];
  orderId: number | null;
  isBench: boolean | null;
  token: string;
}): Promise<{ error: string }> =>
  Axios.post(
    url,
    {
      worklogs,
      orderId,
      isBench
    },
    { headers: { authorization: token } }
  )
    .then(() => ({ error: "" }))
    .catch((e) => ({
      error:
        e.response.status === HttpStatusCode.UNAUTHORIZED
          ? UNAUTHORIZED
          : e.response.data.message || "Не удалось обновить ворклоги",
    }));

export const fetchExternalJiraCategories = (url: string, type: string, exclude?: string[]) => async  (
  entity: Entity
) => {
  const result: Record<string, string> = await fetchJiraCategories(url, type)(entity)
  return exclude && exclude.length ? omit(exclude)(result) : result
}