import {
  fetchOrderItemsFn,
  getOrderItemFn,
  updateOrderItemFn,
  createOrderItemFn,
} from "../orders/api";
import { OrderNumberEntity } from "./OrderNumberEntity";

export default (host: string) =>
  new OrderNumberEntity({
    title: "Номера заказов",
    editable: false,
    viewable: false,
    creatable: false,
    exportable: false,
    items: 9999,
    api: {
      list: { url: `${host}/orders`, method: "get" },
      get: { url: `${host}/orders`, method: "get" },
      update: { url: `${host}/orders`, method: "put" },
      create: { url: `${host}/orders`, method: "post" },
    },
    menu: {
      label: "Номера заказов",
      url: "/order-number",
      enabled: true,
    },
    fields: [
      {
        name: "id",
        type: "number",
        label: "id",
        hideInCreate: true,
        hideInEdit: true,
        hideInList: true,
        filterable: false,
      },
      {
        name: "number",
        type: "string",
        label: "Номер заказа",
        sortable: true,
        filterable: false,
        title: true,
      },
    ],
    fetchItemsFn: fetchOrderItemsFn,
    getItemsFn: getOrderItemFn,
    updateItemsFn: updateOrderItemFn,
    createItemsFn: createOrderItemFn,
  });
