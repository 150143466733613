import React, { FC } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import GroupRows from './GroupRows';
import { processOrdersDataByHealthGroup } from '~/utils/helpers';
import { OrdersEntity } from '../../../api-types';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  table: {
    minWidth: 1200,
  },
  tableRow: {
    '& .MuiTableCell-root': {
      padding: '16px 8px',
    },
  },
});

type Props = {
  data: OrdersEntity[];
};

const OrdersStat: FC<Props> = ({ data }) => {
  const classes = useStyles();

  const { canBeCompletedList, canNotBeCompletedList } = processOrdersDataByHealthGroup(data);

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow className={classes.tableRow}>
            <TableCell></TableCell>
            <TableCell>Health status</TableCell>
            <TableCell align="right">soldHours</TableCell>
            <TableCell align="right">spentHours</TableCell>
            <TableCell align="right">Рейт</TableCell>
            <TableCell align="right">Себестоимость факт</TableCell>
            <TableCell align="right">Себестоимость прогноз</TableCell>
            <TableCell align="right">Выручка</TableCell>
            <TableCell align="right">% в выручке</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {canBeCompletedList.length > 0 ? (
            <GroupRows groupedData={canBeCompletedList} isCanBeCompleted />
          ) : (
            <Typography>No data for "Закроем"</Typography>
          )}
          {canNotBeCompletedList.length > 0 ? (
            <GroupRows groupedData={canNotBeCompletedList} />
          ) : (
            <Typography>No data for "НЕ закроем"</Typography>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OrdersStat;
