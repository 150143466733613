import { OrdersEntity } from '~/config/pages/reports/api-types';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { rubFormatter } from '~/config/pages/reports/ReportsPage/highlightsTab';
import React, { useState } from 'react';
import HeadCell from './components/headCell/HeadCell';
import {HEALTH_STATUSES} from "~/config/pages/orders/constants";
import {OrderHealthStatus} from "~/config/pages/orders/OrderHealthStatus";

export const healthStatusList = ['DONE', 'REAL', 'OPT_GOOD', 'OPT_BAD', 'FAIL'];

export function OrdersList(props: { data: OrdersEntity[] }) {
  const [orderNumberFilter, setOrderNumberFilter] = useState<string | null>(null);
  const [orderJiraFilter, setOrderJiraFilter] = useState<string | null>(null);
  const [healthStatusFilter, setHealthStatusFilter] = useState<string | null>(null);

  let dataInfo = props.data;

  if (orderNumberFilter) {
    dataInfo = dataInfo.filter((el) =>
      el.orderNumber.toLowerCase().includes(orderNumberFilter.toLowerCase())
    );
  }

  if (orderJiraFilter) {
    dataInfo = dataInfo.filter((el) =>
      orderJiraFilter === '-' ? !el.orderJiraCategory : el.orderJiraCategory === orderJiraFilter
    );
  }

  if (healthStatusFilter) {
    dataInfo = dataInfo.filter((el) =>
      healthStatusFilter === 'No status' ? !el.healthStatus : el.healthStatus === healthStatusFilter
    );
  }

  let items: OrdersEntity[] = dataInfo
    .sort((left, right) =>
      Date.parse(left.estimatedDate) < Date.parse(right.estimatedDate) ? -1 : 1
    )
    .sort((left, right) => (left.factDate == null && right.factDate != null ? -1 : 0))
    .sort((left, right) =>
      left.factDate == null && Date.parse(left.estimatedDate) < Date.parse(right.estimatedDate)
        ? 1
        : 0
    );

  const soldHours = items.reduce((acc, curr) => acc + curr.soldHours, 0);
  const trackedHours = items.reduce((acc, curr) => acc + curr.trackingHours, 0);
  const avrerageRate =
    items.reduce((acc, curr) => acc + curr.soldHours * curr.rateRub, 0) / soldHours;
  const totalRevenueRub = items.reduce((acc, curr) => acc + curr.revenueRub, 0);
  const totalCalculatedCostPriceRub = items.reduce(
    (acc, curr) => acc + curr.calculatedCostPriceRub,
    0
  );
  const totalFactCostPriceRub = items.reduce((acc, curr) => acc + curr.factCostPriceRub, 0);
  const averagePercentagePriceRub = items.reduce(
    (acc, curr) =>
      acc + ((curr.factCostPriceRub + curr.calculatedCostPriceRub) / curr.revenueRub) * 100,
    0
  );

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <HeadCell
              headTitle="Номер"
              listData={items.map((el) => el.orderNumber as string)}
              handleChange={setOrderNumberFilter}
            />
            <HeadCell
              headTitle="Jira Category"
              listData={items.map((el) => el.orderJiraCategory ?? '-')}
              handleChange={setOrderJiraFilter}
            />
            <HeadCell
              headTitle="HealthStatus"
              listData={HEALTH_STATUSES.map((el) => el.id)}
              handleChange={setHealthStatusFilter}
            />
            <TableCell align="right">Описание</TableCell>
            <TableCell align="right">Прогнозная дата приемки</TableCell>
            <TableCell align="right">Фактическая дата приемки</TableCell>
            <TableCell align="right">Продано часов</TableCell>
            <TableCell align="right">Затрекано часов</TableCell>
            <TableCell align="right">Рейт</TableCell>
            <TableCell align="right">Себестоимость факт</TableCell>
            <TableCell align="right">Себестоимость прогноз</TableCell>
            <TableCell align="right">Выручка</TableCell>
            <TableCell align="right">% себестоимости в выручке</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((order) => (
            <TableRow key={order.orderId}>
              <TableCell component="th" scope="row">
                <a
                  href={
                    window.location.protocol +
                    '//' +
                    window.location.host +
                    '/orders/' +
                    order.orderId +
                    '/summary'
                  }
                  target="_blank"
                >
                  {order.orderNumber}
                </a>
              </TableCell>
              <TableCell>{order.orderJiraCategory ?? '-'}</TableCell>
              <TableCell>
                <OrderHealthStatus value={order.healthStatus} options={HEALTH_STATUSES.reduce(
                    (obj, status) => ({ ...obj, [status.id]: status.name }),
                    {}
                )} />
              </TableCell>
              <TableCell align="right">{order.orderDescription}</TableCell>
              <TableCell align="right">{order.estimatedDate}</TableCell>
              <TableCell align="right">{order.factDate ?? '-'}</TableCell>
              <TableCell align="right">{order.soldHours.toFixed(2)}</TableCell>
              <TableCell align="right">{order.trackingHours.toFixed(2)}</TableCell>
              <TableCell align="right">{rubFormatter.format(order.rateRub)}</TableCell>
              <TableCell align="right">{rubFormatter.format(order.factCostPriceRub)}</TableCell>
              <TableCell align="right">
                {rubFormatter.format(order.calculatedCostPriceRub)}
              </TableCell>
              <TableCell align="right">{rubFormatter.format(order.revenueRub)}</TableCell>
              <TableCell align="right">
                {(
                  ((order.factCostPriceRub + order.calculatedCostPriceRub) / order.revenueRub) *
                  100
                ).toFixed(0)}
                %
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell component="th" scope="row">
              Суммы
            </TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right">{soldHours.toFixed(2)}</TableCell>
            <TableCell align="right">{trackedHours.toFixed(2)}</TableCell>
            <TableCell align="right">{rubFormatter.format(avrerageRate)}</TableCell>
            <TableCell align="right">{rubFormatter.format(totalFactCostPriceRub)}</TableCell>
            <TableCell align="right">{rubFormatter.format(totalCalculatedCostPriceRub)}</TableCell>
            <TableCell align="right">{rubFormatter.format(totalRevenueRub)}</TableCell>
            <TableCell align="right">
              {(averagePercentagePriceRub / items.length).toFixed(0)}%
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
